import { getInstance } from '../../../../common/api/spidertracks-sdk';
import { OrganisationGroupFormData, OrganisationGroupFormValues } from './OrganisationGroupForm';
import {
  GroupType,
  OrganisationGroupBase
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';

const createGroupAndAssignMembers = async (
  formFieldValues: OrganisationGroupFormValues,
  organisationId: string
) => {
  const newGroupParams: OrganisationGroupBase = {
    name: formFieldValues.groupName,
    textingAvailable: formFieldValues.groupVisibility
      ? formFieldValues.groupVisibility.includes('spidertxt')
      : false,
    notificationsAvailable: formFieldValues.groupVisibility
      ? formFieldValues.groupVisibility.includes('notifications')
      : false,
    active: formFieldValues.status === 'Active',
    type: GroupType.EXPLICIT,
    canDelete: true,
    canDeactivate: true,
    canEditMembership: true
  };
  await getInstance()
    .getUserService()
    .createOrganisationGroup(organisationId, newGroupParams);
  const newGroup = await getInstance()
    .getUserService()
    .getOrganisationGroups(organisationId);
  const newGroupId = newGroup.find(group => group.name === formFieldValues.groupName)?.id;
  if (!newGroupId) {
    console.error('Failed to retrieve groupID after creating group:', formFieldValues.groupName);
    return;
  }
  await getInstance()
    .getUserService()
    .assignMembersToOrganisationGroup(organisationId, newGroupId, formFieldValues.users);
};

const updateGroupAndMembers = async (
  original: OrganisationGroupFormData,
  formFieldValues: OrganisationGroupFormValues,
  organisationId: string
) => {
  let updateGroupParams: OrganisationGroupBase | null = null;
  let updateMembers = false;
  let updateAircraft = false;
  if (original.groupId === undefined) {
    console.error('Group ID is undefined, cannot update group:', original);
    return;
  }
  if (
    original.groupName !== formFieldValues.groupName.trim() ||
    original.spiderTxtAvailable !== formFieldValues.groupVisibility.includes('spidertxt') ||
    original.notificationsAvailable !== formFieldValues.groupVisibility.includes('notifications') ||
    original.status !== (formFieldValues.status === 'Active')
  ) {
    updateGroupParams = {
      id: original.groupId,
      name: formFieldValues.groupName.trim(),
      textingAvailable: formFieldValues.groupVisibility.includes('spidertxt'),
      notificationsAvailable: formFieldValues.groupVisibility.includes('notifications'),
      active: formFieldValues.status === 'Active',
      type: original.type,
      canDelete: original.canDelete,
      canDeactivate: original.canDeactivate,
      canEditMembership: original.canEditMembership
    };
  }
  if (
    original.groupMembers?.length !== formFieldValues.users?.length ||
    original.groupMembers.some(member => !formFieldValues.users.includes(member.id)) ||
    formFieldValues.users.some(
      userId => !original.groupMembers.some(member => member.id === userId)
    )
  ) {
    updateMembers = true;
  }
  if (
    original.groupSelectedAircraft?.length !== formFieldValues.aircraft?.length ||
    original.groupSelectedAircraft
      .map(aircraft => (typeof aircraft === 'string' ? aircraft : aircraft.id))
      .sort()
      .join() != formFieldValues.aircraft.sort().join()
  ) {
    updateAircraft = true;
  }

  if (updateGroupParams !== null) {
    await getInstance()
      .getUserService()
      .updateOrganisationGroup(organisationId, updateGroupParams);
  }
  if (updateMembers) {
    await getInstance()
      .getUserService()
      .assignMembersToOrganisationGroup(organisationId, original.groupId, formFieldValues.users);
  }
  if (updateAircraft) {
    await getInstance()
      .getUserService()
      .assignAircraftToOrganisationGroup(
        organisationId,
        original.groupId,
        formFieldValues.aircraft
      );
  }
};

const saveGroupChanges = async (
  original: OrganisationGroupFormData | null,
  formFieldValues: OrganisationGroupFormValues,
  organisationId: string
) => {
  if (!original?.groupId) {
    await createGroupAndAssignMembers(formFieldValues, organisationId);
  } else {
    await updateGroupAndMembers(original, formFieldValues, organisationId);
  }
};

export const saveFormFieldChanges = async (
  original: OrganisationGroupFormData | null,
  formFieldValues: OrganisationGroupFormValues,
  organisationId: string
) => {
  await Promise.all([saveGroupChanges(original, formFieldValues, organisationId)]);
};
