import { Radio } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataSource } from '../../../redux/slice/flightExplorer/flightExplorer';
import styled from 'styled-components';
import { getDataSource } from '../../../redux/selectors/flightExplorer';

interface OverlayProps {
  className: string;
}

const OverlayControls = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
`;

const SourceOverlay: React.FC<OverlayProps> = ({ className }) => {
  const dispatch = useDispatch();
  const sources = useSelector(getDataSource);
  const handleFeatureClick = (dataSource: string) => {
    dispatch(setDataSource({ dataSource }));
  };

  return (
    <div className={`${className}`}>
      <OverlayControls>
        <Radio
          data-testid="showEvents"
          checked={sources === ''}
          onChange={() => handleFeatureClick('')}
        >
          App default
        </Radio>
        <Radio
          data-testid="showEvents"
          checked={sources === 'spider'}
          onChange={() => handleFeatureClick('spider')}
        >
          Spider
        </Radio>
        <Radio
          data-testid="showEvents"
          checked={sources === 'platform'}
          onChange={() => handleFeatureClick('platform')}
        >
          Platform
        </Radio>
        <Radio
          data-testid="showEvents"
          checked={sources === 'spider,platform'}
          onChange={() => handleFeatureClick('spider,platform')}
        >
          Spider, platform
        </Radio>
        <Radio
          data-testid="showEvents"
          checked={sources === 'platform,spider'}
          onChange={() => handleFeatureClick('platform,spider')}
        >
          Platform, Spider
        </Radio>
      </OverlayControls>
    </div>
  );
};

export default SourceOverlay;
