import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import EventsConfigTable from './EventsConfigTable';
import InsightRulesHeader from './EventsConfigHeader';
import { useDispatch } from 'react-redux';
import { fetchAircraft } from '../../../redux/slice/aircraft';
import { sortEventTypes } from './utils';
import { eventRuleCreateSuccess, eventRuleCreateError, hasSpiderType } from '../../../helpers';
import { useFeatureFlag } from '../../../hooks';
import { fetchInsightRules } from '../../../redux/slice/insightRules';
import { EventType } from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import { SpiderType } from '../../../types/spiders';
import { populateUserData } from '../../../redux/reducers/navigationReducer/actions/thunk';
import EventsConfigDrawer from './EventsConfigDrawer';

export type AssignableAircraftData = PublicAircraftData & { unassigned?: boolean };

export const InsightRulesContainer = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const SpidertracksSDK = getInstance();
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const dispatch = useDispatch();
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [aircraft, setAircraft] = useState<AssignableAircraftData[]>([]);
  const insightsSafetyEnabled = useFeatureFlag('insights-safety', [organisationId]);
  const betaInsightEventRules = useFeatureFlag('beta-insight-event-rules', [organisationId]);
  const gForceEnabled = useFeatureFlag('enable-peak-g', [organisationId]);

  useEffect(() => {
    dispatch(fetchAircraft());
    dispatch(populateUserData());
    dispatch(fetchInsightRules(organisationId));
  }, [dispatch]);

  const fetchEverything = useCallback(async () => {
    setIsLoading(true);
    try {
      const eventService = SpidertracksSDK.getEventRulesService();
      const rulesService = SpidertracksSDK.getInsightRulesService();
      const [eventTypes, insightRules, spiders] = await Promise.all([
        eventService.getAllEventTypes(organisationId),
        rulesService.getAllInsightRules(organisationId),
        SpidertracksSDK.getSpiderSettingsService().getSpiders()
      ]);

      const aircraftWithRules = new Set<string>();
      for (const rule of insightRules) {
        if (!rule.allAircraft) {
          for (let aircraftId of rule.aircraftIds) {
            aircraftWithRules.add(aircraftId);
          }
        }
      }
      const aircraft = await SpidertracksSDK.getOrgAircraft(organisationId);
      const eligibleAircraft = aircraft.filter(
        craft => hasSpiderType([SpiderType.SX])(spiders)(craft) || aircraftWithRules.has(craft.id)
      );
      const assignableAircraft: AssignableAircraftData[] = eligibleAircraft.map(craft => {
        if (!hasSpiderType([SpiderType.SX])(spiders)(craft)) {
          return {
            ...craft,
            unassigned: true
          };
        }
        return {
          ...craft
        };
      });

      let sortedEventTypes = sortEventTypes(eventTypes);
      if (!gForceEnabled) {
        sortedEventTypes = sortedEventTypes.filter(
          eventType => eventType.eventType !== 'excessive_g_force'
        );
      }
      if (!betaInsightEventRules) {
        sortedEventTypes = sortedEventTypes.filter(
          eventType =>
            eventType.eventType !== 'excessive_rpm' &&
            eventType.eventType !== 'excessive_speed' &&
            eventType.eventType !== 'excessive_temperature'
        );
      }

      setEventTypes(sortedEventTypes);
      setAircraft(assignableAircraft);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [SpidertracksSDK, organisationId]);

  const createEventRule = async (eventRule: any) => {
    try {
      await SpidertracksSDK.getEventRulesService().createEventRule(organisationId, eventRule);
      eventRuleCreateSuccess();
    } catch (e) {
      eventRuleCreateError();
    } finally {
      fetchEverything();
    }
  };

  useEffect(() => {
    fetchEverything();
  }, [organisationId, fetchEverything]);

  const openDrawer = () => {
    setDrawerVisibility(true);
  };

  const closeDrawer = () => {
    setDrawerVisibility(false);
  };

  return (
    <React.Fragment>
      <div className="px-5 py-4" style={{ overflow: 'scroll', height: '100%' }}>
        <InsightRulesHeader disabledAddButton={isLoading} openDrawer={openDrawer} />
        <EventsConfigTable
          aircraft={aircraft}
          isLoading={isLoading}
          eventTypes={eventTypes}
          refresh={fetchEverything}
          insightsSafetyEnabled={insightsSafetyEnabled}
        />
        <EventsConfigDrawer
          aircraft={aircraft}
          visibility={drawerVisibility}
          onClose={closeDrawer}
          eventTypes={eventTypes}
          save={createEventRule}
          insightsSafetyEnabled={insightsSafetyEnabled}
          isEditForm={false}
          selectedEventRule={undefined}
        />
      </div>
    </React.Fragment>
  );
};

export default InsightRulesContainer;
