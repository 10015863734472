import React from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { UserSettingType } from './UsersContainer';

interface ComponentProps {
  modalType: UserSettingType;
  ids: string[];
  setIsSaving: (isSaving: boolean) => void;
  removeFunction: (ids: string[]) => void;
}

export const showRemoveModal = ({
  modalType,
  ids,
  setIsSaving,
  removeFunction
}: ComponentProps) => {
  const isPlural = ids.length > 1;

  const pluralise = () => (isPlural ? 's' : '');

  const keywordPluralise = (modalType: UserSettingType) => `${modalType}${pluralise()}`;

  const keywordPluraliseUpper = (modalType: UserSettingType) =>
    keywordPluralise(modalType)
      .charAt(0)
      .toUpperCase() + keywordPluralise(modalType).slice(1);

  const getTitle = (modalType: UserSettingType) => `Remove ${keywordPluraliseUpper(modalType)}`;

  const getContent = (modalType: UserSettingType) => {
    const keywordInContent = keywordPluralise(modalType);
    switch (modalType) {
      case 'member':
        return (
          <>
            <p>Are you sure you want to remove the selected {keywordInContent}?</p>
            <p>
              This will remove the {keywordInContent} from the organisation. You would need to send
              a fresh invite to add the {keywordInContent} back into the organisation.
            </p>
          </>
        );
      case 'group':
        return (
          <>
            <p>Are you sure you want to remove the selected {keywordInContent}?</p>
            <p>This change will affect all members in this {keywordInContent}.</p>
          </>
        );
      case 'guest':
        return (
          <>
            <p>Are you sure you want to remove the selected {keywordInContent}?</p>
            <p>
              This will remove the {keywordInContent} from the organisation. You will not be able to
              add the {keywordInContent} back into the organisation at the moment but you can always
              invite them as Members.
            </p>
          </>
        );
    }
  };

  return Modal.confirm({
    title: getTitle(modalType),
    icon: <ExclamationCircleFilled />,
    content: getContent(modalType),
    okText: getTitle(modalType),
    okButtonProps: { type: 'danger' },
    onOk() {
      setIsSaving(true);
      removeFunction(ids);
    }
  });
};
