import { useEffect, useState } from 'react';
/*
We Subscribe to performanceObserver for resource type. This means things like images, scripts, stylesheets, etc.
We can filter the performance entries by URL if we want to monitor a specific resource.
*/
export const useResourcePerformanceObserver = (url?: string) => {
  const [performanceEntries, setPerformanceEntries] = useState<PerformanceResourceTiming[]>([]);

  useEffect(() => {
    // we've subscribed to the resource entry type, so we can expect the entries to be of type PerformanceResourceTiming
    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming
    const observer = new PerformanceObserver(list => {
      if (url) {
        setPerformanceEntries(
          list.getEntries().filter(entry => entry.name.includes(url)) as PerformanceResourceTiming[]
        );
      } else {
        setPerformanceEntries(list.getEntries() as PerformanceResourceTiming[]);
      }
    });

    observer.observe({ entryTypes: ['resource'] });

    return () => {
      observer.disconnect();
    };
  }, [url]);

  return performanceEntries;
};
