import { SolidTooltip } from '@spidertracks/components';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import React from 'react';
import { AircraftDetails, AircraftType, isAircraftDetails } from '../../../types/aircraft';

export const checkboxOptionTransformer = (
  disabled = false,
  valueTransformer: (aircraft: AircraftDetails) => string = aircraft => aircraft.id
) => <T extends AircraftType>(aircraft: T): CheckboxOptionType => {
  if (isAircraftDetails(aircraft)) {
    return {
      disabled,
      label: disabled ? (
      <SolidTooltip
        title={
          aircraft.unassigned
            ? 'This aircraft currently has no device which supports events.'
            : 'This aircraft is already configured for a different event.'
        }
        trigger="click"
      >
        {aircraft.registration}
      </SolidTooltip>
    ) : (
      aircraft.registration
      ),
      value: valueTransformer(aircraft)
    }
  }
  return {
    disabled,
    label: aircraft.registration,
    value: aircraft.id
  }
};

