import { MessageDeliveryStatusFromAPI } from '../common/api/spidertracks-sdk/private/services/spidertxt/conversationService';
import { Message, MessageStatus, MessageType } from '../types/spidertxt';

export type MessageFromSocket = {
  id: string;
  recipientId: string;
  isRead: boolean;
  senderId: string;
  messageBody: string;
  createdDate: number;
  displayDate: number;
  status: string;
  type: string;
  date: string;
  time: string;
  conversationId: number;
  attributionId: string;
};

export const transformToDomainMessage = (message: MessageFromSocket): Message => {
  return {
    id: message.id,
    body: message.messageBody,
    contactId: message.senderId,
    timestamp: message.createdDate,
    attributionId: message.attributionId,
    // This can also be of System message type, but right now we have no way of identifying a system message
    type: MessageType.RECEIVED,
    status:
      message.status == MessageDeliveryStatusFromAPI.DELIVERED
        ? MessageStatus.DELIVERED
        : MessageStatus.SENT,
    conversationId: message.conversationId
  };
};
