import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import { getCheckedIcon } from '../icons';
import { Table, Tooltip } from 'antd';
import { getAircraftRegistrationLabel, SolidTooltip } from '@spidertracks/components';
import './styles.scss';
import {
  OrganisationAccessibleAircraft,
  OrganisationGroup,
  OrganisationGroupMember
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

interface ComponentProps {
  groups?: OrganisationGroup[];
  loading: boolean;
  isOrgSpidertxtEnabled: boolean;
  isAircraftVisibilityGroupsAdminEnabled: boolean;
  isNotificationsAdminEnabled: boolean;
  onRow: (groupId: string) => void;
}

interface TableRow {
  key: React.Key;
  groupName: string;
  notificationsAvailable: boolean;
  spiderTxtAvailable: boolean; // Group visibility for spidertxt, aka show group as a contact in spidertxt
  active: boolean;
  members: { type: string; members: OrganisationGroupMember[] };
  aircraftVisibility: (OrganisationAccessibleAircraft | string)[];
}

const getActiveColumnContent = (active: boolean) => {
  const activeText = active ? 'Active' : 'Inactive';
  const className = activeText.toLowerCase();
  return <div className={className}>{activeText}</div>;
};

const getSpidertxtColumnTitle = () => {
  return (
    <Tooltip
      title={
        <span>
          Enable Spidertxt under <b>Organisation &gt; Spidertxt</b>
        </span>
      }
      placement="bottom"
      overlayStyle={{ backgroundColor: '#fff' }}
    >
      SPIDERTXT
      <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
    </Tooltip>
  );
};

const getNotificationsColumnTitle = () => {
  return (
    <Tooltip
      title={
        <span>Enabled groups are able to be selected when configuring Spider X Notifications</span>
      }
      placement="bottom"
      overlayStyle={{ backgroundColor: '#fff' }}
    >
      NOTIFICATIONS
      <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
    </Tooltip>
  );
};

export const OrganisationGroupsContainer: React.FC<ComponentProps> = ({
  groups,
  loading,
  isOrgSpidertxtEnabled,
  isAircraftVisibilityGroupsAdminEnabled,
  isNotificationsAdminEnabled,
  onRow
}) => {
  const onRowHandler = (record: TableRow) => {
    return {
      onClick: () => onRow(record.key.toString())
    };
  };

  function aircraftVisCol(aircraft: (OrganisationAccessibleAircraft | string)[]) {
    const aircraftNames = aircraft.map(v => (typeof v === 'string' ? v : v.registration));
    const aircraftCount = getAircraftRegistrationLabel(aircraftNames, '(no-aircraft)');

    const className = aircraft.length > 0 ? '' : 'no-aircraft';
    let aircraftTooltip = aircraftNames.join(', ');
    if (aircraft.length > 12) {
      aircraftTooltip =
        aircraftTooltip
          .split(',')
          .slice(0, 12)
          .join(', ') + ', ...';
    }
    return (
      <SolidTooltip
        className={className}
        placement="bottom"
        title={aircraftTooltip}
        trigger="hover"
      >
        {aircraftCount}
      </SolidTooltip>
    );
  }

  const aircraftVisibilityColumn = {
    title: 'AIRCRAFT VISIBILITY',
    dataIndex: 'aircraftVisibility',
    key: 'aircraftVisibility',
    width: '20%',
    render: aircraftVisCol
  };

  const notificationsColumn = {
    title: () => getNotificationsColumnTitle(),
    dataIndex: 'notificationsAvailable',
    key: 'notificationsAvailable',
    render: (notificationAvailable: boolean, record: TableRow) => {
      return getCheckedIcon(notificationAvailable, record.active);
    },
    width: '10%'
  };

  function groupNameCol(groupName: string, record: TableRow) {
    const isDynamic = record.members.type !== 'EXPLICIT';
    return isDynamic ? (
      <span>
        {groupName} <i>(Dynamic)</i>
      </span>
    ) : (
      groupName
    );
  }

  function membersCol({
    members,
    type
  }: {
    members: OrganisationGroupMember[];
    type: 'EXPLICIT' | 'ADMINS' | 'USERS';
  }) {
    let membersCount = members.length > 0 ? members.length : '(no members)';
    let className = members.length > 0 ? '' : 'no-members';
    let membersTooltip: string;
    if (type === 'EXPLICIT') {
      membersTooltip = members
        .map(member => {
          return member.firstName + ' ' + member.lastName;
        })
        .join(', ');
      if (members.length > 12) {
        //FIXME: breaks if a member has a comma in their name.  Better to limit members above this if.
        membersTooltip =
          membersTooltip
            .split(',')
            .slice(0, 12)
            .join(', ') + ', ...';
      }
    } else {
      membersTooltip = `Dynamically assigned (${members.length} members)`;
      membersCount = `(${members.length})`;
    }

    return (
      <SolidTooltip className={className} placement="bottom" title={membersTooltip} trigger="hover">
        {membersCount}
      </SolidTooltip>
    );
  }

  function spiderTxtIconCol(spidertxtAvailable: boolean, record: TableRow) {
    const className = isOrgSpidertxtEnabled ? '' : 'spidertxt-disabled';
    return (
      <div className={className}>
        {getCheckedIcon(spidertxtAvailable, record.active && isOrgSpidertxtEnabled)}
      </div>
    );
  }

  const columns: ColumnProps<TableRow>[] = [
    {
      title: 'GROUP NAME',
      dataIndex: 'groupName',
      key: 'groupName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        if (a.active !== b.active) {
          return a.active ? -1 : 1;
        } else {
          return a.groupName
            .toLowerCase()
            .localeCompare(b.groupName.toLowerCase(), undefined, { sensitivity: 'base' });
        }
      },
      width: '22%',
      render: groupNameCol
    },
    {
      title: 'MEMBERS',
      dataIndex: 'members',
      key: 'members',
      render: membersCol,
      width: '10%'
    },
    {
      title: () => getSpidertxtColumnTitle(),
      dataIndex: 'spiderTxtAvailable',
      key: 'spiderTxtAvailable',
      render: spiderTxtIconCol,
      width: '10%'
    },
    {
      title: 'STATUS',
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean) => {
        return getActiveColumnContent(active);
      },
      width: '10%'
    }
  ];

  if (isNotificationsAdminEnabled) {
    columns.splice(2, 0, notificationsColumn);
  }
  if (isAircraftVisibilityGroupsAdminEnabled) {
    columns.splice(2, 0, aircraftVisibilityColumn);
  }

  const groupInfoToTableRow = (group: OrganisationGroup): TableRow => ({
    key: group.id,
    groupName: group.name,
    notificationsAvailable: group.notificationsAvailable,
    spiderTxtAvailable: group.textingAvailable,
    active: group.active,
    members: { type: group.type, members: group.members ?? [] },
    aircraftVisibility: group.accessibleAircraft ?? []
  });
  const tableRows = groups?.map(groupInfoToTableRow);

  return (
    <div style={{ margin: '2.5em 0' }}>
      <Table
        className="groups-container"
        data-testid={'groups-table'}
        pagination={{ pageSize: 10, hideOnSinglePage: true }}
        rowKey="key"
        bordered
        dataSource={tableRows}
        columns={columns}
        loading={loading}
        onRow={onRowHandler}
      />
    </div>
  );
};

OrganisationGroupsContainer.propTypes = {
  groups: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  isOrgSpidertxtEnabled: PropTypes.bool.isRequired,
  isAircraftVisibilityGroupsAdminEnabled: PropTypes.bool.isRequired,
  isNotificationsAdminEnabled: PropTypes.bool.isRequired,
  onRow: PropTypes.func.isRequired
};
