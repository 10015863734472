import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Tooltip, Button, Input, Checkbox, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CheckboxOptionType } from 'antd/lib/checkbox';

import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

import {
  GroupType,
  OrganisationAccessibleAircraft,
  OrganisationGroupMember,
  OrganisationMember
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import { AircraftType } from '../../../../types/aircraft';

import { User } from '../../../common/form/UserSelector';
import { AircraftSelector, ConfirmationButtons, UserSelector } from '../../../common/form';

import { showRemoveModal } from '../RemoveModal';

import { saveFormFieldChanges } from './saveOrganisationGroup';

export interface OrganisationGroupFormData {
  groupId: string | undefined;
  groupName: string | undefined;
  groupMembers: OrganisationGroupMember[];
  organisationAircraft: AircraftType[];
  groupSelectedAircraft: (OrganisationAccessibleAircraft | string)[];
  notificationsAvailable: boolean;
  spiderTxtAvailable: boolean;
  status: boolean;
  canDelete: boolean;
  canDeactivate: boolean;
  canEditMembership: boolean;
  type: GroupType;
}

export interface OrganisationGroupFormValues {
  groupName: string;
  users: string[];
  aircraft: string[];
  groupVisibility: string[]; // contains "spidertxt" and/or "notifications"
  status: string;
}

interface ComponentProps extends FormComponentProps {
  initialData: OrganisationGroupFormData | null;
  organisationMembers: OrganisationMember[];
  isOrgSpidertxtEnabled: boolean;
  isAircraftVisibilityGroupsAdminEnabled: boolean;
  isNotificationsAdminEnabled: boolean;
  removeOrganisationGroup: (ids: string[]) => void;
  onClose: () => void;
  onSave: (success: boolean, actionType: string) => void;
  form: WrappedFormUtils;
}

export const OrganisationGroupForm: React.FC<ComponentProps> = ({
  initialData,
  organisationMembers,
  isOrgSpidertxtEnabled,
  isAircraftVisibilityGroupsAdminEnabled,
  isNotificationsAdminEnabled,
  removeOrganisationGroup,
  onClose,
  onSave,
  form
}) => {
  const formRef = useRef(form);
  const [isSaving, setIsSaving] = useState(false);

  const orgUserList = organisationMembers?.map(member => ({
    id: member.id,
    name: `${member.firstName} ${member.lastName}`,
    isAdmin: member.role === 'ADMIN',
    selected: initialData?.groupMembers.some(groupMember => groupMember.id === member.id)
  }));
  orgUserList.sort((user1, user2) => {
    if (user1.selected === undefined && user2.selected === undefined) {
      return user1.name.localeCompare(user2.name);
    }
    const selectedCompare = Number(user2.selected) - Number(user1.selected);
    if (selectedCompare !== 0) {
      return selectedCompare;
    }
    return user1.name.localeCompare(user2.name);
  });

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const getGroupVisibility = (initialData: OrganisationGroupFormData) => {
      const groupVisibility: string[] = [];
      if (initialData.spiderTxtAvailable) {
        groupVisibility.push('spidertxt');
      }
      if (initialData.notificationsAvailable) {
        groupVisibility.push('notifications');
      }
      return groupVisibility;
    };
    const selectedAircraft = initialData.groupSelectedAircraft.map(aircraft => {
      return typeof aircraft === 'string' ? aircraft : aircraft.id;
    });

    formRef.current.setFields({
      groupName: { value: initialData.groupName },
      users: { value: initialData.groupMembers.map(member => member.id) },
      aircraft: {
        value: selectedAircraft
      },
      status: { value: initialData.status ? 'Active' : 'Inactive' },
      groupVisibility: { value: getGroupVisibility(initialData) }
    });
  }, [initialData]);

  const aircraftVisibilityLabel = (
    <span>
      Aircraft Visibility
      <Tooltip
        placement="right"
        title={
          <span>
            Visibility allows you to select which group within your Organisation can view tracks
            from your aircraft. It allows the members in that group to see all aircraft by selecting
            the all aircraft option in the drop down selection.
          </span>
        }
      >
        <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
      </Tooltip>
    </span>
  );

  const spidertxtLabel = (
    <span id="group-spidertxt-status" className={isOrgSpidertxtEnabled ? '' : 'spidertxt-disabled'}>
      Enable Spidertxt group{' '}
      {!isOrgSpidertxtEnabled && (
        <Tooltip
          placement="right"
          title={
            <span>
              Spidertxt has been disabled for this organisation. Enable Spidertxt for this
              organisation under Organisation &gt; Spidertxt
            </span>
          }
        >
          <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
        </Tooltip>
      )}
    </span>
  );

  const notificationsLabel = (
    <span id="group-spidertxt-status">
      Show in Notifications setup{' '}
      {isNotificationsAdminEnabled && (
        <Tooltip
          placement="right"
          title={
            <span>
              Enabled groups are able to be selected when configuring Spider X Notifications
            </span>
          }
        >
          <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
        </Tooltip>
      )}
    </span>
  );

  const saveOrganisationGroup = async (formFieldValues: OrganisationGroupFormValues) => {
    setIsSaving(true);
    let success = true;
    const actionType = initialData?.groupId ? 'update' : 'create';
    if (formFieldValues) {
      try {
        await saveFormFieldChanges(
          initialData,
          formFieldValues,
          organisationMembers[0].organisationId
        );
      } catch (e) {
        success = false;
      }
    }

    setIsSaving(false);
    onSave(success, actionType);
  };

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formRef.current.validateFields((err, values) => {
      if (err) {
        return;
      }
      saveOrganisationGroup(values);
    });
  };

  const aircraftOptionTransformer = <T extends AircraftType>(aircraft: T): CheckboxOptionType => ({
    disabled: false,
    label: aircraft.registration,
    value: aircraft.id
  });

  const userOptionTransformer = (user: User): CheckboxOptionType => ({
    disabled: false,
    label: user.name,
    value: user.id
  });

  return (
    <div>
      <Form onSubmit={formSubmit} layout="vertical">
        <Form.Item label="Group Name" style={{ marginRight: '10px' }}>
          {formRef.current.getFieldDecorator('groupName', {
            validateFirst: true,
            rules: [
              { required: true, message: 'Group name is required.' },
              {
                max: 50,
                message: 'Group name must be less than 50 characters.'
              }
            ]
          })(<Input placeholder={initialData?.groupName || 'Enter group name'} />)}
        </Form.Item>

        <UserSelector
          form={form}
          initialValue={[]}
          isEditForm={false}
          users={orgUserList}
          userOptionTransformer={userOptionTransformer}
          showAllChoice={false}
          showAdminChoice={false}
          required={false}
          disabled={!(initialData?.canEditMembership ?? true)}
        />

        {isAircraftVisibilityGroupsAdminEnabled && (
          <AircraftSelector
            aircraft={initialData ? initialData.organisationAircraft : []}
            aircraftOptionTransformer={aircraftOptionTransformer}
            form={formRef.current}
            initialValue={[]}
            isEditForm={false}
            options={
              initialData ? initialData.organisationAircraft.map(aircraftOptionTransformer) : []
            }
            showAllChoice={true}
            showFixedWingChoice={true}
            showRotaryChoice={true}
            label={aircraftVisibilityLabel}
            allowNoneSelection={true}
          />
        )}

        <Form.Item label="Group Visibility" style={{ marginRight: '10px' }}>
          <Row style={{ paddingBottom: '8px' }}>
            {formRef.current.getFieldDecorator('groupVisibility', {
              initialValue: ['spidertxt', 'notifications']
            })(
              <Checkbox.Group>
                <Row style={{ paddingBottom: '8px' }}>
                  <Checkbox value="spidertxt">{spidertxtLabel}</Checkbox>
                </Row>
                {isNotificationsAdminEnabled && (
                  <Row style={{ paddingBottom: '8px' }}>
                    <Checkbox value="notifications">{notificationsLabel}</Checkbox>
                  </Row>
                )}
              </Checkbox.Group>
            )}
          </Row>
        </Form.Item>

        <Form.Item label="Status" style={{ marginRight: '10px' }}>
          {formRef.current.getFieldDecorator('status', {
            initialValue: initialData?.status === false ? 'Inactive' : 'Active',
            rules: [{ required: true, message: 'Please select the status of the group.' }]
          })(
            <Select disabled={!(initialData?.canDeactivate ?? true)}>
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          )}
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          {initialData?.groupId && (initialData?.canDelete ?? true) && (
            <Button
              type="danger"
              size="large"
              style={{ width: '85px', margin: '16px 8px 0 0' }}
              onClick={() => {
                if (initialData?.groupId) {
                  showRemoveModal({
                    modalType: 'group',
                    ids: [initialData.groupId],
                    setIsSaving: setIsSaving,
                    removeFunction: removeOrganisationGroup
                  });
                }
              }}
            >
              Remove
            </Button>
          )}

          <ConfirmationButtons onClose={onClose} isSaving={isSaving} />
        </div>
      </Form>
    </div>
  );
};

OrganisationGroupForm.propTypes = {
  initialData: PropTypes.any,
  organisationMembers: PropTypes.array.isRequired,
  isOrgSpidertxtEnabled: PropTypes.bool.isRequired,
  removeOrganisationGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
  isAircraftVisibilityGroupsAdminEnabled: PropTypes.bool.isRequired,
  isNotificationsAdminEnabled: PropTypes.bool.isRequired
};
