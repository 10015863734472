import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getTimezone } from '../../../redux/selectors/userData';
import { useDispatch, useSelector } from 'react-redux';
import { setDisplayTimezone } from '../../../redux/slice/flightExplorer/flightExplorer';
import {
  getDisplayTimezone,
  getTrackIdsForNavigation
} from '../../../redux/selectors/flightExplorer';
import PanelVisibilityControlButtons from './PanelVisibilityControlButtons';
import { DownloadFlight, HistoryTableFilter } from './icons';
import { FilterConfig } from '../../../redux/types';
import { HistoryTableFilterTooltipContent } from './HistoryTableFilterTooltipContent';
import { AircraftPropertiesTooltipContent } from './AircraftPropertiesTooltipContent';
import { DownloadTracks } from '../../common/modal/DownloadTracks';
import { FlightStatus } from '../../../constants';
import { loadTracksForNavigation } from '../../../redux/slice/flightExplorer/thunk';
import { TrackNavigationPreviousNextButton } from './TrackNavigationPreviousNextButton';
import { buildGoBackToHistoryPageUrl } from './buildGoBackToHistoryPageUrl';

const FlightDetailPanelWrapper = styled.div`
  display: flex;
  height: 30px;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const FlightDetailSummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-left: 2rem;
  gap: 10px;
`;

const FlightDetailTitle = styled.div<{ active: boolean }>`
  font-size: 1.25rem;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: ${props => (props.active ? '#BAD928' : '#E8E8E8')};
`;

const FlightDetailContent = styled.div<{ paddingLeft?: number; paddingRight?: number }>`
  font-size: 0.8rem;
  padding-right: ${props => props.paddingRight || 0.5}rem;
  padding-left: ${props => props.paddingLeft || 0}rem;
`;

const DownloadFlightButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const DropdownLabel = styled(Button)`
  &&& {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 69px;
    padding-left: 5px;
    height: 25px;
  }
`;

const TooltipComponentWrapper = (props: { child: JSX.Element; tooltipText: JSX.Element }) => (
  <Tooltip placement="bottom" trigger="hover" title={props.tooltipText}>
    <div>{props.child}</div>
  </Tooltip>
);

export interface AircraftProperties {
  orgName: string;
  registration: string;
  active: boolean;
  type?: string;
  make?: string;
  model?: string;
  device?: string;
  serialNumber: string;
}

export interface FlightDetailPanelProps {
  trackId: string;
  trackStatus?: FlightStatus | null;
  bootCount: number;
  departedTime: string;
  totalDistance: string;
  totalTime: string;
  aircraftProperties: AircraftProperties;
  filterConfig: FilterConfig;
  indexTime: number;
}

export const FlightDetailPanel: React.FC<FlightDetailPanelProps> = (
  flightDetails: FlightDetailPanelProps
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const displayTimezone = useSelector(getDisplayTimezone);
  const userTimezone = useSelector(getTimezone);
  const trackIdsForNavigation = useSelector(getTrackIdsForNavigation);
  const nextTrackIdForNavigation =
    trackIdsForNavigation[trackIdsForNavigation.indexOf(flightDetails.trackId) - 1];
  const previousTrackIdForNavigation =
    trackIdsForNavigation[trackIdsForNavigation.indexOf(flightDetails.trackId) + 1];

  const [displayTimezoneAbbr, setDisplayTimezoneAbbr] = useState<string>();
  const [userTimezoneAbbrv, setUserTimezoneAbbrv] = useState<string>();
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const timezoneItems = [
    {
      label: userTimezoneAbbrv,
      key: userTimezone
    },
    {
      label: 'UTC',
      key: 'UTC'
    }
  ];
  useEffect(() => {
    if (flightDetails.trackId && flightDetails.indexTime) {
      dispatch(
        loadTracksForNavigation({
          currentTrackId: flightDetails.trackId,
          indexTime: flightDetails.indexTime,
          filterConfig
        })
      );
    }
  }, [flightDetails.trackId, flightDetails.indexTime, flightDetails.filterConfig, dispatch]);

  useEffect(() => {
    if (displayTimezone == '') {
      setDisplayTimezoneAbbr(moment.tz(userTimezone).zoneAbbr());
      setUserTimezoneAbbrv(moment.tz(userTimezone).zoneAbbr());
      dispatch(setDisplayTimezone({ displayTimezone: userTimezone }));
    } else {
      setDisplayTimezoneAbbr(moment.tz(displayTimezone).zoneAbbr());
    }
  }, [displayTimezone, userTimezone]);

  const onValueChange = (value: string) => {
    dispatch(setDisplayTimezone({ displayTimezone: value }));
  };
  const filterConfig = flightDetails.filterConfig;
  const filterConfigPresent =
    filterConfig.aircraft ||
    filterConfig.sos ||
    filterConfig.spiderTimeGreaterThanOneMinute ||
    filterConfig.aircraftMoved;

  return (
    <FlightDetailPanelWrapper>
      <DownloadTracks
        visible={downloadModalVisible}
        trackIds={[flightDetails.trackId]}
        downloadLimit={30}
        requestClose={() => setDownloadModalVisible(false)}
      />
      <FlightDetailSummaryWrapper>
        <i
          onClick={() => history.push(buildGoBackToHistoryPageUrl(filterConfig))}
          className="material-icons event-go-back-icon"
        >
          arrow_back_ios
        </i>

        {filterConfigPresent && (
          <TooltipComponentWrapper
            child={
              <div data-testid="history-table-filter" className="history-table-filter">
                <HistoryTableFilter />
              </div>
            }
            tooltipText={<HistoryTableFilterTooltipContent {...flightDetails.filterConfig} />}
          />
        )}
        <TrackNavigationPreviousNextButton
          trackId={previousTrackIdForNavigation}
          direction="previous"
        />
        <TrackNavigationPreviousNextButton trackId={nextTrackIdForNavigation} direction="next" />
        <FlightDetailTitle
          active={flightDetails.aircraftProperties.active}
          data-testid={'flight-detail-title'}
        >
          <TooltipComponentWrapper
            child={
              <span data-testid="aircraft-registration">
                {flightDetails.aircraftProperties.registration}
              </span>
            }
            tooltipText={<AircraftPropertiesTooltipContent {...flightDetails.aircraftProperties} />}
          />
        </FlightDetailTitle>

        <FlightDetailContent paddingLeft={1}>ID: {flightDetails.bootCount}</FlightDetailContent>
        <FlightDetailContent>|</FlightDetailContent>

        <FlightDetailContent>
          <Dropdown
            overlay={
              <Menu>
                {timezoneItems.map(item => (
                  <Menu.Item key={item.key} onClick={() => onValueChange(item.key)}>
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
            <DropdownLabel data-testid="timezone-selector-dropdown-label">
              {displayTimezoneAbbr}
              <DownOutlined />
            </DropdownLabel>
          </Dropdown>
        </FlightDetailContent>

        <FlightDetailContent>{flightDetails.departedTime}</FlightDetailContent>
        <FlightDetailContent>|</FlightDetailContent>

        <TooltipComponentWrapper
          child={
            <FlightDetailContent>
              {<div data-testid="total-distance">{flightDetails.totalDistance}</div>}
            </FlightDetailContent>
          }
          tooltipText={<span>Total Distance</span>}
        />
        <FlightDetailContent>|</FlightDetailContent>

        <TooltipComponentWrapper
          child={
            <FlightDetailContent>
              {<div data-testid="total-time">{flightDetails.totalTime}</div>}
            </FlightDetailContent>
          }
          tooltipText={<span>Total Time</span>}
        />
      </FlightDetailSummaryWrapper>

      <PanelVisibilityControlButtons />

      <FlightDetailContent paddingRight={2}>
        <Button
          type="primary"
          data-testid="download-flight"
          onClick={() => setDownloadModalVisible(true)}
        >
          <DownloadFlightButtonContentWrapper>
            <DownloadFlight /> Export
          </DownloadFlightButtonContentWrapper>
        </Button>
      </FlightDetailContent>
    </FlightDetailPanelWrapper>
  );
};

export default FlightDetailPanel;
