import { FullState } from '../../store';
import { createSelector } from 'reselect';
import {
  ContactCategory,
  ContactType,
  Conversation,
  SpiderTxtState,
  UIContact,
  UIConversation
} from '../../types/spidertxt';
import { DefaultRootState } from 'react-redux';

export const getSpiderTxt = (state: FullState): SpiderTxtState => state.spiderTxt;

export const getActiveContactId = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): string | undefined => spiderTxt.runtime.activeContactId
);

export const getLastFullPolledOn = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): number => spiderTxt.runtime.lastFullPolledOn
);

export const getLastConversationsPolledOn = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): number => spiderTxt.runtime.lastConversationsPolledOn
);

export const getUnreadMessageCount = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): number => spiderTxt.unreadMessageCount
);

export const getContacts = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): UIContact[] => Object.values(spiderTxt.contacts)
);

export interface ContactContainerState {
  contacts: UIContact[];
  conversations: Conversation[];
  contactSearchText: string;
  contactFilteredCategory: ContactCategory;
  initialLoadComplete: boolean;
}
export const getContactContainerState = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState) => {
    return {
      initialLoadComplete: spiderTxt.runtime.initialLoadComplete,
      contacts: Object.values(spiderTxt.contacts),
      conversations: Object.values(spiderTxt.conversations),
      contactSearchText: spiderTxt.runtime.contactFilterText,
      contactFilteredCategory: spiderTxt.runtime.contactFilteredCategory
    };
  }
);

export const getConversations = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): UIConversation[] => Object.values(spiderTxt.conversations)
);

export const getConversationMap = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): { [key: string]: UIConversation } => spiderTxt.conversations
);

export const getConversationById = (state: DefaultRootState, contactId: string) =>
  createSelector(
    [getSpiderTxt],
    (spiderTxt: SpiderTxtState): UIConversation | undefined => spiderTxt.conversations[contactId]
  );

export const getContactById = (state: DefaultRootState, contactId: string) =>
  createSelector([getSpiderTxt], (spiderTxt: SpiderTxtState): UIContact | undefined => {
    const contact = spiderTxt.contacts[contactId];
    if (contact == undefined) {
      return contact;
    }
    const copy = {
      ...contact
    };
    if (copy.contactType == ContactType.INDIVIDUAL) {
      copy.organisations = [...copy.organisations];
    } else {
      copy.memberIds = [...copy.memberIds];
    }
    return copy;
  });

export const getContactFilterText = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): string => spiderTxt.runtime.contactFilterText
);

export const getReadMessageMap = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState) => spiderTxt.readMessageMap
);

export const getContactFilteredCategory = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): ContactCategory => spiderTxt.runtime.contactFilteredCategory
);

export const getDraftMessages = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState) => spiderTxt.runtime.draftMessages
);
