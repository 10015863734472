import { getInstance } from '../../../common/api/spidertracks-sdk';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';
import { StandardThunk } from '../../../store';
import { getTrackIdsForNavigation } from '../../selectors/flightExplorer';
import { FilterConfig } from '../../types';
import { setTrackIdsForNavigation, setTrackIdsForNavigationMetaData } from './flightExplorer';

interface loadTracksForNavigationParams {
  currentTrackId: string;
  indexTime: number;
  filterConfig: FilterConfig;
}

export const loadTracksForNavigation = (
  params: loadTracksForNavigationParams
): StandardThunk<void> => async (dispatch, getState) => {
  const { currentTrackId, indexTime, filterConfig } = params;
  const sdk = getInstance();

  const trackIdsForNavigation = getTrackIdsForNavigation(getState());
  const currentTrackIndex = trackIdsForNavigation.indexOf(currentTrackId);
  if (
    currentTrackIndex == -1 ||
    currentTrackIndex == 0 ||
    currentTrackIndex == trackIdsForNavigation.length - 1
  ) {
    const newTrackIdsForNavigation: string[] = [];

    const previousTracksParams = new URLSearchParams();
    const nextTracksParams = new URLSearchParams();

    if (Object.keys(filterConfig).length === 0) {
      // if filterConfig is empty, set default filters
      filterConfig.end = new Date().getTime() / 1000;
      // 3 months ago
      filterConfig.start = filterConfig.end - 7889229;
    }
    if (filterConfig.aircraft) {
      previousTracksParams.set('aircraftIds', filterConfig.aircraft.join(','));
      nextTracksParams.set('aircraftIds', filterConfig.aircraft.join(','));
    }

    if (filterConfig.sos) {
      previousTracksParams.set('sos', filterConfig.sos.join(',').toLowerCase());
      nextTracksParams.set('sos', filterConfig.sos.join(',').toLowerCase());
    }

    if (filterConfig.aircraftMoved) {
      previousTracksParams.set('zeroDistanceFlights', 'false');
      nextTracksParams.set('zeroDistanceFlights', 'false');
    }

    if (filterConfig.spiderTimeGreaterThanOneMinute) {
      previousTracksParams.set('zeroMinFlights', 'false');
      nextTracksParams.set('zeroMinFlights', 'false');
    }

    if (filterConfig.start) {
      previousTracksParams.set('startTime', `${filterConfig.start * 1000}`);
    }
    previousTracksParams.set('endTime', `${indexTime}`);

    nextTracksParams.set('sortOrder', 'ASC');
    nextTracksParams.set('startTime', `${indexTime}`);
    if (filterConfig.end) {
      nextTracksParams.set('endTime', `${filterConfig.end * 1000}`);
    }

    // Fetching 50 items will fix an issue in the backend where tracks are not returned when sos filter is enabled
    previousTracksParams.set('resultsPerPage', '50');
    nextTracksParams.set('resultsPerPage', '50');

    const previousTracks = await sdk.getHistoryTableTracks(previousTracksParams);

    const nextTracks = await sdk.getHistoryTableTracks(nextTracksParams);

    (nextTracks.items as PrivateTrackData[]).reverse().forEach((track: PrivateTrackData) => {
      const alreadyIn = newTrackIdsForNavigation.indexOf(track.trackId.toString());
      if (alreadyIn === -1) {
        newTrackIdsForNavigation.push(track.trackId.toString());
      }
    });
    (previousTracks.items as PrivateTrackData[]).forEach((track: PrivateTrackData) => {
      const alreadyIn = newTrackIdsForNavigation.indexOf(track.trackId.toString());
      if (alreadyIn === -1) {
        newTrackIdsForNavigation.push(track.trackId.toString());
      }
    });

    const totalTrackCount = previousTracks.meta.total + nextTracks.meta.total - 1;

    dispatch(setTrackIdsForNavigation({ trackIdsForNavigation: newTrackIdsForNavigation }));
    dispatch(
      setTrackIdsForNavigationMetaData({
        trackIdsForNavigationMetaData: {
          totalTrackCount,
          nextTrackPosition: nextTracks.meta.total - 1,
          previousTrackPosition: nextTracks.meta.total + 1
        }
      })
    );
  }
};
