import { FilterConfig } from '../../../redux/types';

export const buildGoBackToHistoryPageUrl = (filterConfig: FilterConfig) => {
  let queryParameters = '?';
  if (filterConfig.end !== undefined && filterConfig.end !== null) {
    queryParameters = queryParameters + `end=${filterConfig.end}`;
  }
  if (filterConfig.start !== undefined && filterConfig.start !== null) {
    queryParameters =
      queryParameters + `${queryParameters === '?' ? '' : '&'}start=${filterConfig.start}`;
  }
  if (
    filterConfig.spiderTimeGreaterThanOneMinute !== undefined &&
    filterConfig.spiderTimeGreaterThanOneMinute !== null
  ) {
    queryParameters = queryParameters + `${queryParameters === '?' ? '' : '&'}duration=true`;
  }
  if (filterConfig.aircraftMoved !== undefined && filterConfig.aircraftMoved !== null) {
    queryParameters = queryParameters + `${queryParameters === '?' ? '' : '&'}distance=true`;
  }
  if (
    filterConfig.sos !== undefined &&
    filterConfig.sos !== null &&
    filterConfig.sos.length !== 0
  ) {
    queryParameters =
      queryParameters +
      `${queryParameters === '?' ? '' : '&'}sos=${filterConfig.sos.join(',').toLowerCase()}`;
  }
  if (
    filterConfig.aircraft !== undefined &&
    filterConfig.aircraft !== null &&
    filterConfig.aircraft.length !== 0
  ) {
    queryParameters =
      queryParameters +
      `${queryParameters === '?' ? '' : '&'}aircraftIds=${filterConfig.aircraft?.join(',')}`;
  }
  if (filterConfig.page !== undefined && filterConfig.page !== null) {
    queryParameters =
      queryParameters + `${queryParameters === '?' ? '' : '&'}page=${filterConfig.page}`;
  }

  return encodeURI(`/history${queryParameters}`);
};
