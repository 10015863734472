import { Amplify } from '@spidertracks/common';

import { AircraftType } from '../../../../../types/aircraft';

import { ServiceAbstract } from '../../ServiceAbstract';

export interface InvitedUser {
  id: string;
  email: string;
  invitedAt: number;
  expiresAt: number;
  expired: boolean;
  registered: boolean;
  inviteAccepted: boolean;
  invitedBy: string;
}

interface PhoneNumber {
  type: string;
  countryIdd: string;
  fullNumber: string;
  number: string;
}

export interface OrganisationMember {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isPilot: boolean;
  spiderTxtEnabled: boolean;
  defaultAircraftAccess: boolean;
  organisationId: string;
  groups: { id: string; name: string }[];
  aircraft: { id: string; registration: string }[] | ['*'];
  phoneNumbers: PhoneNumber[];
}

export interface OrganisationGuest {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumbers: PhoneNumber[];
  spiderTxtEnabled: boolean;
}

export interface OrganisationGuestList {
  organisationId: string;
  guests: OrganisationGuest[];
}

export interface OrganisationGroupMember {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  joinedAt: number;
}

export enum GroupType {
  EXPLICIT = 'EXPLICIT',
  ALL_USERS = 'USERS',
  ALL_ADMINS = 'ADMINS'
}

export interface OrganisationAccessibleAircraft {
  id: string;
  registration: string;
}

export interface OrganisationGroupBase {
  id?: string;
  name: string;
  members?: OrganisationGroupMember[];
  accessibleAircraft?: (OrganisationAccessibleAircraft | string)[];
  textingAvailable: boolean;
  notificationsAvailable: boolean;
  active: boolean;
  canDelete: boolean;
  canDeactivate: boolean;
  canEditMembership: boolean;
  type: GroupType;
}

export interface OrganisationGroup extends OrganisationGroupBase {
  id: string;
  name: string;
  createdAt: number;
  updatedAt: number;
  updatedBy: string;
  spidertxtId: number;
}

export class UserService extends ServiceAbstract {
  public async getInvitedUsers(organisationId: string): Promise<InvitedUser[]> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/invites`
    );
    return apiResponse ?? [];
  }

  public async sendInvites(
    organisationId: string,
    emails: string[],
    message?: string
  ): Promise<void> {
    await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/invites`,
      {
        body: {
          emails,
          ...(message && { customMessage: message })
        }
      }
    );
    return;
  }

  public async cancelInvites(organisationId: string, inviteIds: string[]): Promise<void> {
    for (const id of inviteIds) {
      await Amplify.API.del(
        'api.spidertracks.io-authenticated',
        `organisations/${organisationId}/invites/${id}`
      );
    }
    return;
  }

  public async getOrganisationMembers(organisationId: string): Promise<OrganisationMember[]> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users`
    );
    return apiResponse.users ?? [];
  }

  public async assignAircraftAccess(
    userId: string,
    organisationId: string,
    vehicleIds: string[]
  ): Promise<void> {
    await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${userId}/accessible-aircraft`,
      {
        body: vehicleIds
      }
    );
  }

  private async deleteOrganisationMember(memberId: string, organisationId: string): Promise<void> {
    await Amplify.API.del(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${memberId}`
    );
  }

  public async deleteOrganisationMembers(
    memberIds: string[],
    organisationId: string
  ): Promise<void> {
    await Promise.all(
      memberIds.map(memberId => this.deleteOrganisationMember(memberId, organisationId))
    );
  }

  public async updateOrganisationMemberRole(
    organisationId: string,
    userId: string,
    role: string
  ): Promise<void> {
    await Amplify.API.patch(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${userId}`,
      {
        body: { role }
      }
    );
  }

  public async updateSpiderTxtStatus(
    organisationId: string,
    userId: string,
    textingEnabled: boolean
  ): Promise<void> {
    await Amplify.API.patch(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/users/${userId}`,
      {
        body: { textingEnabled }
      }
    );
  }

  public async getOrganisationGuests(organisationId: string): Promise<OrganisationGuestList> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/guests`
    );
    return apiResponse ?? [];
  }

  private async deleteOrganisationGuest(guestId: string, organisationId: string): Promise<void> {
    await Amplify.API.del(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/guests/${guestId}`
    );
  }

  public async deleteOrganisationGuests(guestIds: string[], organisationId: string): Promise<void> {
    await Promise.all(
      guestIds.map(guestId => this.deleteOrganisationGuest(guestId, organisationId))
    );
  }

  public async getOrganisationGroups(organisationId: string): Promise<OrganisationGroup[]> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups`
    );
    return apiResponse ?? [];
  }

  public async getOrganisationGroupMembers(
    organisationId: string,
    groupId: string
  ): Promise<OrganisationGroupMember[]> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups/${groupId}/users`
    );
    return apiResponse ?? [];
  }

  public async createOrganisationGroup(
    organisationId: string,
    groupProps: OrganisationGroupBase
  ): Promise<void> {
    await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups`,
      {
        body: {
          name: groupProps.name,
          active: groupProps.active,
          textingAvailable: groupProps.textingAvailable,
          notificationsAvailable: groupProps.notificationsAvailable
        }
      }
    );
    return;
  }

  public async updateOrganisationGroup(
    organisationId: string,
    groupProps: OrganisationGroupBase
  ): Promise<void> {
    await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups/${groupProps.id}`,
      {
        body: {
          name: groupProps.name,
          textingAvailable: groupProps.textingAvailable,
          notificationsAvailable: groupProps.notificationsAvailable,
          active: groupProps.active
        }
      }
    );
  }

  public async assignMembersToOrganisationGroup(
    organisationId: string,
    groupId: string,
    userIds: string[]
  ): Promise<void> {
    await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups/${groupId}/users`,
      {
        body: userIds
      }
    );
  }

  public async deleteOrganisationGroup(organisationId: string, groupId: string): Promise<void> {
    await Amplify.API.del(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups/${groupId}`
    );
    return;
  }

  public async getOrganisationGroupAccessibleAircraft(
    organisationId: string,
    groupId: string
  ): Promise<AircraftType[]> {
    const apiResponse = await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups/${groupId}/accessible-aircraft`,
      {
        queryStringParameters: {
          resolve: false
        }
      }
    );
    if (!apiResponse || !apiResponse.aircraft) {
      return [];
    }

    return (
      apiResponse.aircraft.map((a: AircraftType | string) => {
        if (typeof a === 'string') {
          return {
            id: a,
            registration: a,
            type: 'group'
          };
        } else {
          return {
            ...a,
            type: 'aircraft'
          };
        }
      }) ?? []
    );
  }

  public async assignAircraftToOrganisationGroup(
    organisationId: string,
    groupId: string,
    aircraftIds: string[]
  ): Promise<void> {
    await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/groups/${groupId}/accessible-aircraft`,
      {
        body: aircraftIds
      }
    );
  }
}
