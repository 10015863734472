import { FlightDataSample } from '../../../types/FlightData';

import { availabilityFromTimestamps } from './czmlHelpers';
import { TimelineEvent } from '../utilities/pointClassifier';
import { getEncodedIconUrl } from '../../Flying/Map/utils/drawing/marker';
import { lockTo3DFRTrack } from './lockTo3DFRTrack';
import { EventMarkerSVG } from './eventMarkerSVG';

export async function czmlLabels(
  timelineEvents: TimelineEvent[],
  lockedToGround: FlightDataSample[],
  markerColor: string,
  options: {
    visible: boolean;
    showName: boolean;
    eventVisibleDuration: number;
  }
) {
  // This is an optimisation to avoid generating labels when they are not visible
  if (!options.visible) {
    return timelineEvents.reduce<any[]>((acc, val) => {
      acc.push({
        id: `lollipop_${val.id}`,
        delete: true
      });
      return acc;
    }, []);
  }
  /* How this will work
   * 1. Convert all to metric
   * 2. Lock the events to the ground so that they match the path
   * 3. Lock the events to the track  so that they match the path, this is required because of the smoothing from FlightDataService
   * 4. For each timeline event, create a new label
   */

  const timelineEventsAsMetric = timelineEvents.map(item => ({
    ...item,
    point: {
      ...item.point,
      altitude: item.point.altitude * (item.point.altitudeUnit == 'ft' ? 0.3048 : 1)
    }
  }));

  const lockedToTrack = lockTo3DFRTrack(
    timelineEventsAsMetric.map(e => e.point),
    lockedToGround
  );

  const events = timelineEventsAsMetric.map((item, index) => ({
    ...item,
    point: {
      ...item.point,
      ...lockedToTrack[index]
    }
  }));

  return events.reduce<any[]>((acc, val) => {
    if (val.name) {
      const billboard = new EventMarkerSVG(val, {
        showName: options.showName,
        showDescription: false,
        stickColor: markerColor
      });
      const billboardOrigin = [-billboard.stickOrigin.x, 0];
      const availability = availabilityFromTimestamps(
        val.point.timestamp / 1000 - options.eventVisibleDuration,
        val.point.timestamp / 1000 + options.eventVisibleDuration
      );
      const position = {
        cartographicDegrees: [val.point.longitude, val.point.latitude, val.point.altitude]
      };
      const translucencyByDistance = {
        nearFarScalar: [1.0, 1.0, 2000.0, 0.5]
      };
      acc.push({
        id: `lollipop_${val.id}`,
        availability,
        position,
        properties: {
          event: val
        },
        billboard: {
          heightReference: {
            heightReference: 'NONE'
          },
          image: getEncodedIconUrl(billboard.generateSVG()),
          scale: 1,
          translucencyByDistance,
          horizontalOrigin: {
            horizontalOrigin: 'LEFT'
          },
          verticalOrigin: {
            verticalOrigin: 'BOTTOM'
          },
          pixelOffset: {
            cartesian2: billboardOrigin
          }
        }
      });
    }
    return acc;
  }, []);
}
