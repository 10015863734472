import { AircraftRenderProperties, FlightDataSample } from '../../../types/FlightData';
import { Cartographic, sampleTerrainMostDetailed, TerrainProvider } from 'cesium';
import { availability, hexToRGB, rgba } from './czmlHelpers';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';

function wall(color: rgba, positions: number[], groundHeights: number[]) {
  if (positions.length !== 0) {
    return {
      material: {
        solidColor: {
          color: {
            rgba: color
          }
        }
      },
      positions: {
        cartographicRadians: positions
      },
      minimumHeights: {
        array: groundHeights
      }
    };
  } else {
    return {
      material: {
        solidColor: {
          color: {
            rgba: color
          }
        }
      }
    };
  }
}

function normalize(flightData: FlightDataSample[]) {
  const sortedFlightData = [...flightData] //FIXME: why are we copying this?
    .sort((a, b) => (a.sampleTimeEpochMillis < b.sampleTimeEpochMillis ? -1 : 1));

  const result = {
    wall: new Array<number>(sortedFlightData.length * 3),
    cartesians: new Array<Cartographic>(sortedFlightData.length)
  };
  sortedFlightData.forEach((sample, index) => {
    const { latitude, longitude, amslMetres: altitude } = sample;
    const pos = Cartographic.fromDegrees(longitude, latitude, altitude);
    let offset = index * 3;
    result.wall[offset++] = pos.longitude;
    result.wall[offset++] = pos.latitude;
    result.wall[offset] = pos.height;
    result.cartesians[index] = pos;
  });

  return result;
}

export async function czmlWall(
  selectedTrack: PrivateTrackData,
  lockedToGround: FlightDataSample[],
  aircraft: AircraftRenderProperties,
  markerColor: string,
  terrainProvider: TerrainProvider,
  visible: boolean
) {
  if (!visible) {
    return [
      {
        id: 'ribbon',
        delete: true
      }
    ];
  }

  const wallColor: rgba = aircraft.color ? hexToRGB(markerColor, 127) : [255, 0, 255, 255];

  const result = normalize(lockedToGround);
  const terrainProm = (async () => {
    return await sampleTerrainMostDetailed(terrainProvider, result.cartesians);
  })();

  const clampedCartesians = await terrainProm;
  return [
    {
      id: 'ribbon',
      availability: availability(selectedTrack),
      wall: wall(
        wallColor,
        result.wall,
        clampedCartesians.map(c => c.height)
      )
    }
  ];
}
