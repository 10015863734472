import React, { useEffect, useState } from 'react';
import { Drawer, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  GroupType,
  OrganisationGroup,
  OrganisationMember
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import {
  PrivateAircraftData,
  PublicAircraftData
} from '../../../../common/api/spidertracks-sdk/types/AircraftData';
import { OrganisationGroupForm, OrganisationGroupFormData } from './OrganisationGroupForm';

interface ComponentProps extends FormComponentProps {
  organisationGroup: OrganisationGroup | undefined;
  organisationAircraft: (PublicAircraftData | PrivateAircraftData)[];
  organisationMembers: OrganisationMember[];
  isOrgSpidertxtEnabled: boolean;
  isAircraftVisibilityGroupsAdminEnabled: boolean;
  isNotificationsAdminEnabled: boolean;
  removeOrganisationGroup: (ids: string[]) => void;
  onClose: () => void;
  onSave: (success: boolean, actionType: string) => void;
}

const OrganisationGroupDrawer = ({
  organisationGroup,
  organisationAircraft,
  organisationMembers,
  isOrgSpidertxtEnabled,
  isAircraftVisibilityGroupsAdminEnabled,
  isNotificationsAdminEnabled,
  removeOrganisationGroup,
  onSave,
  onClose,
  form
}: ComponentProps) => {
  const [formData, setFormData] = useState<OrganisationGroupFormData | null>(null);

  useEffect(() => {
    setFormData({
      groupId: organisationGroup?.id ?? undefined,
      groupName: organisationGroup?.name ?? undefined,
      groupMembers: organisationGroup?.members ?? [],
      organisationAircraft: organisationAircraft,
      groupSelectedAircraft: organisationGroup?.accessibleAircraft ?? [],
      notificationsAvailable: organisationGroup?.notificationsAvailable ?? true,
      spiderTxtAvailable: organisationGroup?.textingAvailable ?? true,
      status: organisationGroup?.active ?? true,
      canDelete: organisationGroup?.canDelete ?? true,
      canDeactivate: organisationGroup?.canDeactivate ?? true,
      canEditMembership: organisationGroup?.canEditMembership ?? true,
      type: organisationGroup?.type ?? GroupType.EXPLICIT
    });
  }, [organisationGroup, organisationAircraft]);

  return (
    <Drawer
      data-testid={'organisation-group-edit-drawer'}
      title={organisationGroup ? 'Edit' : 'Add'}
      placement="right"
      onClose={onClose}
      visible={true}
      width={400}
    >
      <div style={{ marginLeft: '3em' }}>
        <div style={{ paddingTop: '2em' }}>
          <OrganisationGroupForm
            initialData={formData}
            organisationMembers={organisationMembers}
            isOrgSpidertxtEnabled={isOrgSpidertxtEnabled}
            isAircraftVisibilityGroupsAdminEnabled={isAircraftVisibilityGroupsAdminEnabled}
            isNotificationsAdminEnabled={isNotificationsAdminEnabled}
            onClose={onClose}
            onSave={onSave}
            removeOrganisationGroup={removeOrganisationGroup}
            form={form}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Form.create<ComponentProps>()(OrganisationGroupDrawer);
