import { Amplify } from '@spidertracks/common';
import { Contact, ContactType } from '../../../../../../types/spidertxt';
import { ServiceAbstract } from '../../../ServiceAbstract';

interface UserOrganisationMembership {
  orgId: string;
  orgName: string;
  role: string;
}
interface UserFromAPI {
  kind: 'user';
  id: string;
  spiderTxtId: number;
  displayName: string;
  avatarUrl?: string;
  organisationMembership: UserOrganisationMembership[];
}

interface GroupFromAPI {
  kind: 'group';
  id: string;
  spiderTxtId: number;
  displayName: string;
  organisationName: string;
  organisationId: string;
  memberIds: string[];
}

export type ContactFromAPI = UserFromAPI | GroupFromAPI;

export interface ContactsAPIResponse {
  contacts: ContactFromAPI[];
}

export class ContactService extends ServiceAbstract {
  private transformToDomainType(contact: ContactFromAPI): Contact {
    if (contact.kind === 'user') {
      return {
        id: contact.id,
        organisations: contact.organisationMembership.map(membership => membership.orgName),
        displayName: contact.displayName,
        contactType: ContactType.INDIVIDUAL,
        avatarUrl: contact.avatarUrl
      };
    } else {
      return {
        id: contact.id,
        displayName: contact.displayName,
        contactType: ContactType.GROUP,
        organisationId: contact.organisationId,
        organisationName: contact.organisationName,
        memberIds: contact.memberIds
      };
    }
  }

  public async getContacts(): Promise<Contact[]> {
    try {
      const { contacts } = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `v2/v2/contacts`
      );
      return contacts.map((contact: ContactFromAPI) => this.transformToDomainType(contact));
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  public async getContactImage(contact: Contact): Promise<string | undefined> {
    if (contact.contactType == ContactType.GROUP || contact.avatarUrl == undefined) {
      return undefined;
    }
    const urlParts = contact.avatarUrl!.split('v2');
    const url = `v2/v2${urlParts[1]}`;
    try {
      const response = await Amplify.API.get('api.spidertracks.io-authenticated', url, {
        responseType: 'blob' // Ensures the response is treated as binary (image)
      });
      const imageUrl = URL.createObjectURL(response);
      return imageUrl;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }
}
