import React, { useEffect, useState } from 'react';
import { Drawer, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { OrganisationGuestForm, OrganisationGuestFormData } from './OrganisationGuestForm';
import { OrganisationGuest } from '../../../../common/api/spidertracks-sdk/private/services/UserService';

interface ComponentProps extends FormComponentProps {
  organisationGuest: OrganisationGuest;
  organisationId: string;
  onClose: () => void;
  inviteAsMembers: (emailAddresses: string[], customMessage?: string) => void;
  removeOrganisationGuests: (ids: string[]) => void;
}

// WIP: Guest drawer is not used in the application atm
// Will be used in Phase 2
const OrganisationGuestDrawer = ({
  organisationGuest,
  organisationId,
  onClose,
  inviteAsMembers,
  removeOrganisationGuests,
  form
}: ComponentProps) => {
  const [formData, setFormData] = useState<OrganisationGuestFormData | null>(null);

  useEffect(() => {
    if (organisationGuest) {
      setFormData({
        guestId: organisationGuest.id,
        firstName: organisationGuest.firstName,
        lastName: organisationGuest.lastName,
        email: organisationGuest.email,
        phoneNumber: organisationGuest.phoneNumbers?.[0]?.fullNumber ?? '',
        organisationId: organisationId
      });
    }
  }, [organisationGuest, organisationId]);

  return (
    <Drawer
      data-testid={'organisation-guest-edit-drawer'}
      title="Edit"
      placement="right"
      onClose={onClose}
      visible={true}
      width={400}
    >
      <div style={{ marginLeft: '3em' }}>
        <div style={{ paddingTop: '2em' }}>
          <OrganisationGuestForm
            initialData={formData}
            onClose={onClose}
            inviteAsMembers={inviteAsMembers}
            removeOrganisationGuests={removeOrganisationGuests}
            form={form}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Form.create<ComponentProps>()(OrganisationGuestDrawer);
