import React, { useEffect, useState } from 'react';

import { Button, Card } from 'antd';
import PropTypes from 'prop-types';
import { TableFullRowSelect } from '@spidertracks/components';
import { OrganisationGuest } from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import { getFilterDropdown } from '../../../common/filters';
import { showRemoveModal } from '../RemoveModal';
import { showInviteAsMembersModal } from './InviteAsMembersModal';

interface ComponentProps {
  guests: OrganisationGuest[];
  loading: boolean;
  inviteAsMembers: (emailAddresses: string[], customMessage?: string) => void;
  removeOrganisationGuests: (guestIds: string[]) => void;
}

interface TableRow {
  key: React.Key;
  email: string;
  name: string;
  mobileNumber: string;
}

export const GuestsContainer: React.FC<ComponentProps> = ({
  guests,
  loading,
  inviteAsMembers,
  removeOrganisationGuests
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [filteredGuests, setFilteredGuests] = useState([]);

  const onChange = (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys);
  const onSelectAll = (selected: boolean) => !selected && setSelectedRowKeys([]);

  const guestNameFilter = guests.map(guest => ({
    key: `${guest.id}`,
    value: `${guest.id}`,
    label: `${guest.firstName} ${guest.lastName}`
  }));

  const guestNameOnFilter = (value: string, record: TableRow) => {
    return record.key === value;
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a: TableRow, b: TableRow) =>
        a.name
          .toLowerCase()
          .localeCompare(b.name.toLowerCase(), undefined, { sensitivity: 'base' }),
      filters: guestNameFilter,
      filterMultiple: true,
      onFilter: guestNameOnFilter,
      ...getFilterDropdown({
        displayName: 'Name',
        onFilter: setFilteredGuests,
        clearSelection: () => setFilteredGuests([]),
        showSearch: true,
        updateOnConfirm: true,
        style: {}
      })(),
      filteredValue: filteredGuests
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      defaultSortOrder: 'ascend',
      sorter: (a: TableRow, b: TableRow) =>
        a.email
          .toLowerCase()
          .localeCompare(b.email.toLowerCase(), undefined, { sensitivity: 'base' }),
      width: '30%'
    },
    {
      title: 'MOBILE NUMBER',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber'
    }
  ];

  const guestToTableRow = (guest: OrganisationGuest): TableRow => ({
    key: guest.id,
    name: `${guest.firstName} ${guest.lastName}`,
    email: guest.email,
    mobileNumber: guest.phoneNumbers.map(phoneNumber => phoneNumber.fullNumber).join(', ')
  });

  const tableRows = guests.map(guestToTableRow);

  const handleOnRow = (record: TableRow) => {
    const selectedMemberId = record.key.toString();
    if (selectedRowKeys.includes(selectedMemberId)) {
      const filteredKeys = selectedRowKeys.filter(id => id !== selectedMemberId);
      setSelectedRowKeys(filteredKeys);
    } else {
      setSelectedRowKeys([...selectedRowKeys, selectedMemberId]);
    }
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [loading]);

  return (
    <div style={{ margin: '2.5em 0' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'flex-end',
          paddingBottom: '4px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <Button
            data-testid={'invite-as-members'}
            type="link"
            icon="idcard"
            size="small"
            onClick={() => {
              const guestEmails = guests
                .filter(guest => selectedRowKeys.includes(guest.id))
                .map(({ email }) => email);
              showInviteAsMembersModal({
                guestEmails: guestEmails,
                inviteAsMembers: () => {
                  inviteAsMembers(guestEmails);
                }
              });
            }}
            disabled={selectedRowKeys.length === 0}
          >
            Invite as Member
          </Button>
          <Button
            data-testid={'delete-guests'}
            type="link"
            icon="delete"
            size="small"
            onClick={() => {
              showRemoveModal({
                modalType: 'guest',
                ids: selectedRowKeys,
                setIsSaving: () => {},
                removeFunction: removeOrganisationGuests
              });
            }}
            disabled={selectedRowKeys.length === 0}
          >
            Remove
          </Button>
        </div>
      </div>
      <TableFullRowSelect
        data-testid={'guests-table'}
        pagination={{ pageSize: 10, hideOnSinglePage: true }}
        rowKey="key"
        size="middle"
        bordered
        dataSource={tableRows}
        columns={columns}
        loading={loading}
        onRow={(record: TableRow) => ({
          onClick: () => handleOnRow(record)
        })}
        rowSelection={{
          selectedRowKeys,
          onChange,
          onSelectAll
        }}
      />
      <Card style={{ backgroundColor: '#FFFBE6', margin: '4em 0', padding: '0 2em' }}>
        <p style={{ padding: '0' }}>
          Guest users are available when setting SMS and email notification configurations.{' '}
          <i>(coming soon...)</i>
        </p>
        <p style={{ margin: '0' }}>
          Guest users are not able to see flights or use Spidertxt messaging.{' '}
        </p>
      </Card>
    </div>
  );
};

GuestsContainer.propTypes = {
  guests: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  inviteAsMembers: PropTypes.func.isRequired,
  removeOrganisationGuests: PropTypes.func.isRequired
};
