import {
  InsightRuleCondition,
  InsightRuleConditionSource,
  InsightRuleConditionType
} from '../common/api/spidertracks-sdk/private/services/InsightRulesService';
import {
  convertDegreesToRadians,
  convertMetresPerSecondTo,
  convertMetresTo,
  convertRadiansToDegrees,
  convertToMetres,
  convertToMetresPerSecond
} from '../components/Flying/Map/utils/helper';
import { DisplayInsightRuleCondition } from '../types/insightRules';
import { UserData } from './types';

export function createDisplayCondition(
  condition: InsightRuleCondition,
  userData: UserData
): DisplayInsightRuleCondition {
  const thresholdUnit = getUserUnitForConditionType(condition.type, userData);

  let converter: (number: number) => number;
  switch (condition.source) {
    case InsightRuleConditionSource.aglMetres:
    case InsightRuleConditionSource.amslMetres:
      converter = (num: number) => {
        return convertMetresTo(num, thresholdUnit, 2)[0];
      };
      break;
    case InsightRuleConditionSource.groundSpeedMetresPerSecond:
    case InsightRuleConditionSource.verticalSpeedMetresPerSecond:
      converter = (num: number) => {
        return convertMetresPerSecondTo(num, thresholdUnit, 2);
      };
      break;
    case InsightRuleConditionSource.aircraftPitchRadians:
    case InsightRuleConditionSource.aircraftRollRadians:
    case InsightRuleConditionSource.aircraftYawRadians:
      converter = (num: number) => {
        return Number(convertRadiansToDegrees(num).toFixed(0));
      };
      break;
    default:
      // temperature, rpm
      converter = (num: number) => {
        return num;
      };
      break;
  }

  return {
    ...condition,
    displayThreshold: {
      ...condition.threshold,
      thresholdUnit,
      thresholdValue: converter(condition.threshold.thresholdValue),
      thresholdValueLow: converter(condition.threshold.thresholdValueLow),
      thresholdValueMedium: converter(condition.threshold.thresholdValueMedium),
      thresholdValueHigh: converter(condition.threshold.thresholdValueHigh)
    }
  };
}

export function createEntityCondition(
  condition: DisplayInsightRuleCondition,
  userData: UserData
): InsightRuleCondition {
  const thresholdUnit = getUserUnitForConditionType(condition.type, userData);

  let converter: (number: number) => number;
  switch (condition.source) {
    case InsightRuleConditionSource.aglMetres:
    case InsightRuleConditionSource.amslMetres:
      converter = (num: number) => {
        return convertToMetres(num, thresholdUnit, 2)[0];
      };
      break;
    case InsightRuleConditionSource.groundSpeedMetresPerSecond:
    case InsightRuleConditionSource.verticalSpeedMetresPerSecond:
      converter = (num: number) => {
        return convertToMetresPerSecond(num, thresholdUnit, 6);
      };
      break;
    case InsightRuleConditionSource.aircraftPitchRadians:
    case InsightRuleConditionSource.aircraftRollRadians:
    case InsightRuleConditionSource.aircraftYawRadians:
      converter = (num: number) => {
        return Number(convertDegreesToRadians(num).toFixed(6));
      };
      break;
    default:
      converter = (num: number) => {
        return num;
      };
      break;
  }

  return {
    ...condition,
    threshold: {
      thresholdValue: converter(condition.displayThreshold.thresholdValue),
      severityEnabled: condition.displayThreshold.severityEnabled,
      thresholdValueLow: converter(condition.displayThreshold.thresholdValueLow),
      thresholdValueMedium: converter(condition.displayThreshold.thresholdValueMedium),
      thresholdValueHigh: converter(condition.displayThreshold.thresholdValueHigh)
    }
  };
}

export function getStandardUnitForConditionType(type: InsightRuleConditionType): string {
  switch (type) {
    case InsightRuleConditionType.gForce:
      return 'g';
    case InsightRuleConditionType.temperature:
      return '°C';
    case InsightRuleConditionType.lowSpeed:
    case InsightRuleConditionType.highSpeed:
    case InsightRuleConditionType.rateOfClimb:
    case InsightRuleConditionType.rateOfDescent:
      return 'm/s';
    case InsightRuleConditionType.highAltitudeAgl:
    case InsightRuleConditionType.highAltitudeAmsl:
    case InsightRuleConditionType.lowAltitudeAgl:
    case InsightRuleConditionType.lowAltitudeAmsl:
      return 'm';
    case InsightRuleConditionType.rpm:
      return 'rpm';
    case InsightRuleConditionType.roll:
    case InsightRuleConditionType.pitchDown:
    case InsightRuleConditionType.pitchUp:
      return 'degrees';
    default:
      return `unknown property ${type}`;
  }
}

export function getUserUnitForConditionType(
  type: InsightRuleConditionType,
  userData: UserData
): string {
  switch (type) {
    case InsightRuleConditionType.rateOfClimb:
    case InsightRuleConditionType.rateOfDescent:
      return 'ft/min';
    case InsightRuleConditionType.lowSpeed:
    case InsightRuleConditionType.highSpeed:
      return userData.speedUnit == undefined || userData.speedUnit == ''
        ? getStandardUnitForConditionType(type)
        : userData.speedUnit;
    case InsightRuleConditionType.highAltitudeAgl:
    case InsightRuleConditionType.highAltitudeAmsl:
    case InsightRuleConditionType.lowAltitudeAgl:
    case InsightRuleConditionType.lowAltitudeAmsl:
      return userData.altitudeUnit == undefined || userData.speedUnit == ''
        ? getStandardUnitForConditionType(type)
        : userData.altitudeUnit;
    default:
      return getStandardUnitForConditionType(type);
  }
}
