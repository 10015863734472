import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleMapContainer from '../Map/GoogleMap/GoogleMapContainer';
import { setMapProperties } from './utils/skyVectorMap';
import { getNormalisedCoordinates } from './utils/vectorUtils';
import { sosData } from '../../../redux/reducers/mapReducer/actions/map';
import { clearSelectedFlight } from '../../../redux/reducers/aircraftReducer/thunk';
import { mapInitialLoaded } from '../../../redux/selectors/mapData';
import './styles/scss/map.scss';
import { populateFavourites } from '../../../redux/reducers/navigationReducer/actions/thunk';
import { getGoogleView, getSkyVectorView } from '../../../redux/selectors/mapData';
import SkyVectorLogger from './SkyVectorLogger';

export const { google } = window;

class MapContainer extends PureComponent {
  static mapDetails(skyVectorView) {
    setMapProperties({
      map: window.gmap,
      getNormalisedCoordinates,
      skyVectorView
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      slide: false
    };
    this.onToggle = this.onToggle.bind(this);
    this.setMapView = this.setMapView.bind(this);
  }

  componentDidMount() {
    this.props.populateFavourites();
  }

  componentDidUpdate(prevProps) {
    const { googleView, skyVectorView } = this.props;
    if (googleView !== prevProps.googleView || skyVectorView !== prevProps.skyVectorView) {
      this.setMapView();
    }

    if (
      !prevProps.mapInitialLoaded && // Map was previously not loaded
      this.props.mapInitialLoaded // Map is now loaded
    ) {
      this.setMapView();
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedFlight();
  }

  onToggle() {
    this.setState(prevState => {
      const slideState = !prevState.slide;
      return {
        slide: slideState
      };
    });
  }

  setMapView() {
    const { googleView, skyVectorView } = this.props;
    if (window.gmap) {
      if (googleView.length) {
        window.gmap.setMapTypeId(googleView);
      } else {
        MapContainer.mapDetails(skyVectorView);
      }
    }
  }

  methods = {
    onToggle: this.onToggle
  };

  render() {
    const { slide } = this.state;

    return (
      <GoogleMapContainer className="map-wrapper__map" slide={slide} methods={this.methods}>
        {this.props.children}
        <SkyVectorLogger IsPublicUser={false} />
      </GoogleMapContainer>
    );
  }
}

MapContainer.defaultProps = {
  googleView: '',
  skyVectorView: ''
};

MapContainer.propTypes = {
  googleView: PropTypes.string,
  skyVectorView: PropTypes.string,
  populateFavourites: PropTypes.func,
  clearSelectedFlight: PropTypes.func,
  mapInitialLoaded: PropTypes.bool,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  googleView: getGoogleView(state),
  skyVectorView: getSkyVectorView(state),
  mapInitialLoaded: mapInitialLoaded(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearSelectedFlight,
      sosData,
      populateFavourites
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
