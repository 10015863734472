import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from 'antd';
import Button from 'antd/lib/button';

import {
  getAircraftRegistrationVerboseLabel,
  getExceedenceLabel,
  getGeofenceLabel,
  getRuleLabel,
  getUsersLabel
} from '@spidertracks/components';

import { ExceedenceNotificationTableRow, GeofenceNotificationTableRow } from './NotificationsTable';
import { NotificationsTabsContainer } from './NotificationsTabsContainer';
import NotificationDrawer from './NotificationDrawer';
import { NotificationConfiguration } from './NotificationForm';
import { EventRule } from './EventRuleSelector';
import {
  getSendNotifcationForSeverityLabel,
  mapExcludedSeveritiesToSendNotificationForSeverity
} from './ExcludedSeveritiesDropdown';

import { getDescriptionForInsightRule } from '../insightRules/utils';

import { getInstance } from '../../../common/api/spidertracks-sdk';
import { Role } from '../../../common/api/spidertracks-sdk/types/Member';
import { AircraftDetails } from '../../../types/aircraft';
import { User } from '../../common/form/UserSelector';
import {
  showErrorNotification,
  showSuccessNotification
} from '../../../helpers/globalNotifications';
import { fetchInsightRules } from '../../../redux/slice/insightRules';
import { getOrganisationInsightRules } from '../../../redux/selectors/insightRules';
import { InsightRule } from '../../../types/insightRules';
import {
  GroupType,
  OrganisationGroup
} from '../../../common/api/spidertracks-sdk/private/services/UserService';
import { Recipient } from '../../../common/api/spidertracks-sdk/private/services/NotificationConfigurationService';

const style = { overflow: 'scroll', height: '100%' };

interface OrganisationId {
  organisationId: string;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-object-literal-type-assertion */

function expand(userStrings: string[], users: User[], groups: OrganisationGroup[]): Recipient[] {
  return userStrings.map(id => {
    const user = users.find(u => u.id === id);
    if (user) {
      return { id: user.id, name: user.name, type: 'user' };
    }

    const group = groups.find(g => g.id === id);
    if (group) {
      return { id: group.id, name: group.name, type: 'group' };
    }

    const needle = id === '*' ? GroupType.ALL_USERS : GroupType.ALL_ADMINS;
    const specialGroup = groups.find(g => g.type === needle);
    if (specialGroup) {
      return { id: specialGroup.id, name: specialGroup.name, type: 'group' };
    }

    throw new Error('Unknown user or group: ' + id);
  });
}

export const NotificationsContainer = () => {
  const SpidertracksSDK = getInstance();
  const { organisationId } = useParams<OrganisationId>();
  const [geofenceNotificationTableRows, setGeofenceNotificationTableRows] = useState<
    GeofenceNotificationTableRow[]
  >();
  const [exceedenceNotificationTableRows, setExceedenceNotificationTableRows] = useState<
    ExceedenceNotificationTableRow[]
  >();
  const [notificationConfigurations, setNotificationConfigurations] = useState<
    NotificationConfiguration[]
  >();
  const [
    selectedNotificationConfiguration,
    setSelectedNotificationConfiguration
  ] = useState<NotificationConfiguration | null>(null);

  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const [orgDataLoading, setOrgDataLoading] = useState(true);
  const [notificationDataLoading, setNotificationDataLoading] = useState(true);
  const [userActionLoading, setUserActionLoading] = useState(false);
  const dispatch = useDispatch();
  const allOrganisationRules = useSelector(getOrganisationInsightRules);

  const [users, setUsers] = useState<User[]>([]);
  const [aircraft, setAircraft] = useState<AircraftDetails[]>([]);
  const [geofenceEventRules, setGeofenceEventRules] = useState<EventRule[]>([]);
  const [groups, setGroups] = useState<OrganisationGroup[]>([]);

  useEffect(() => {
    dispatch(fetchInsightRules(organisationId));
  }, [dispatch, organisationId]);

  const fetchGeofences = async (): Promise<EventRule[]> => {
    const geofences = await SpidertracksSDK.getGeofencesService().getGeofences(organisationId);
    return geofences.features.map(g => {
      return { id: g.properties.id, label: g.properties.name };
    });
  };

  const rules: InsightRule[] = allOrganisationRules[organisationId] ?? [];
  const describedRules = rules.map(rule => {
    const description = getDescriptionForInsightRule(rule);
    return {
      ...rule,
      description: description
    };
  });

  const eventClasses = [
    {
      name: 'geofence',
      label: 'Geofences',
      fetchOptions: fetchGeofences
    },
    {
      name: 'fsi',
      label: 'Exceedances (by name)',
      fetchOptions: () => {
        return describedRules;
      }
    }
  ];

  const openAddNotificationConfigDrawer = () => {
    setDrawerVisibility(true);
    setSelectedNotificationConfiguration(null);
  };

  const onDrawerClose = () => {
    setDrawerVisibility(false);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const [membersData, aircraftData, groupsData] = await Promise.all([
          await SpidertracksSDK.getMembers(organisationId),
          await SpidertracksSDK.getOrgAircraft(organisationId),
          await SpidertracksSDK.getUserService().getOrganisationGroups(organisationId)
        ]);

        const membersAsUsers: User[] = membersData
          .map(member => ({
            id: member.id,
            name: `${member.firstName} ${member.lastName}`,
            isAdmin: member.role === Role.ADMIN
          }))
          .sort((user1, user2) => user1.name.localeCompare(user2.name));
        setUsers(membersAsUsers);

        const orgAircraft = aircraftData.sort((aircraft1, aircraft2) =>
          aircraft1.registration.localeCompare(aircraft2.registration)
        );
        setAircraft(orgAircraft as AircraftDetails[]);

        setGroups(groupsData.sort((a, b) => a.name.localeCompare(b.name)));
      } catch (err) {
        showErrorNotification('Error fetching organisation data');
      } finally {
        setOrgDataLoading(false);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    const init = async () => {
      try {
        const notificationsData = await SpidertracksSDK.getNotificationConfigurationService().get(
          organisationId
        );

        const geofences = await fetchGeofences();
        setGeofenceEventRules(geofences);
        const geofenceNotificationTableData: GeofenceNotificationTableRow[] = notificationsData
          .filter(entry => {
            return entry.eventClass === 'geofence';
          })
          .map(
            entry =>
              ({
                key: entry.id,
                category: entry.eventClass,
                people: getUsersLabel(
                  entry.people.map(o => (typeof o === 'object' ? ('name' in o ? o.name : o) : o))
                ),
                peopleToolTip: users
                  .filter(
                    v =>
                      entry.people.filter(
                        u => (typeof u === 'object' ? ('userId' in u ? u.userId : '') : '') === v.id
                      ).length > 0
                  )
                  .map(o => o.name)
                  .join(', '),
                notificationType: entry.how,
                aircraft: getAircraftRegistrationVerboseLabel(
                  entry.aircraft.map(aircraftId => {
                    const aircraftDetail = aircraft.find(a => a.id == aircraftId);
                    if (aircraftDetail) {
                      return aircraftDetail.registration;
                    }
                    return aircraftId;
                  })
                ),
                aircraftToolTip: aircraft
                  .filter(v => entry.aircraft.includes(v.id))
                  .map(o => o.registration)
                  .join(', '),
                geofence: getGeofenceLabel(
                  entry.eventRules.map(geofenceId => {
                    if (geofenceId == '*') {
                      return geofenceId;
                    }
                    const geofenceDetail = geofences.find(g => g.id === geofenceId);
                    if (geofenceDetail) {
                      return geofenceDetail.label;
                    }
                    return '(Deleted geofence)';
                  })
                ),
                geofenceToolTip: geofences
                  .filter(e => entry.eventRules.includes(e.id))
                  .map(o => o.label)
                  .join(', ')
              } as GeofenceNotificationTableRow)
          );
        setGeofenceNotificationTableRows(geofenceNotificationTableData);

        const exceedencesNotificationTableData: ExceedenceNotificationTableRow[] = notificationsData
          .filter(entry => {
            return entry.eventClass === 'fsi';
          }) // Assumes all fsi events have eventRuleIds property
          .map(
            entry =>
              ({
                key: entry.id,
                category: entry.eventClass,
                people: getUsersLabel(
                  entry.people.map(o => (typeof o === 'object' ? ('name' in o ? o.name : o) : o))
                ),
                peopleToolTip: users
                  .filter(
                    v =>
                      entry.people.filter(
                        u => (typeof u === 'object' ? ('userId' in u ? u.userId : '') : '') === v.id
                      ).length > 0
                  )
                  .map(o => o.name)
                  .join(', '),
                notificationType: entry.how,
                exceedence: entry.eventRuleIds!.includes('*')
                  ? 'All Exceedances'
                  : getExceedenceLabel(
                      entry.eventRuleIds!.map(ruleId => {
                        const rule = describedRules.find(e => e.id === ruleId);
                        if (rule == undefined) {
                          return '(Deleted Exceedance)';
                        }
                        return rule.name;
                      })
                    ),
                exceedenceToolTip: entry.eventRuleIds!.includes('*')
                  ? describedRules.map(o => o.name).join(', ')
                  : describedRules
                      .filter(rule => entry.eventRuleIds!.includes(rule.id))
                      .map(o => o.name)
                      .join(', '),
                exceedenceRule: getRuleLabel(
                  entry.eventRuleIds!.includes('*')
                    ? describedRules
                        .filter(rule => rule.description != undefined)
                        .map(rule => rule.description!)
                    : describedRules
                        .filter(rule => entry.eventRuleIds!.includes(rule.id))
                        .map(rule => rule.description!)
                ),
                exceedenceRuleToolTip: entry.eventRuleIds!.includes('*')
                  ? describedRules
                      .filter(rule => rule.description != undefined)
                      .map(rule => rule.description)
                      .join(', ')
                  : describedRules
                      .filter(rule => entry.eventRuleIds!.includes(rule.id))
                      .map(rule => rule.description)
                      .join(', '),
                severity: getSendNotifcationForSeverityLabel(
                  mapExcludedSeveritiesToSendNotificationForSeverity(entry.excludedSeverities)
                ),
                severityToolTip: getSendNotifcationForSeverityLabel(
                  mapExcludedSeveritiesToSendNotificationForSeverity(entry.excludedSeverities)
                )
              } as ExceedenceNotificationTableRow)
          );
        setExceedenceNotificationTableRows(exceedencesNotificationTableData);

        const notificationConfigurations = notificationsData.map(entry => {
          const users = entry.people.map(o => {
            //LATER: #NotificationRecipientRefactor remove the User/string handling & just leave recipient part here:
            if (typeof o === 'object') {
              if ('userId' in o) {
                // Handle a User object:
                return o.userId;
              } else {
                // Handle a Recipient object, which might be a user, a group or a special group which we need to remap:
                const recipient = o as Recipient;
                const id = recipient.id;

                if (recipient.type === 'group') {
                  const groupType = groups.find(group => group.id === id)?.type;
                  switch (groupType) {
                    case GroupType.ALL_USERS:
                      return '*';
                    case GroupType.ALL_ADMINS:
                      return 'ADMINS';
                  }
                }

                return id;
              }
            } else {
              return o; // string
            }
          });

          return {
            ...entry,
            eventClass: eventClasses.find(eventClass => eventClass.label === entry.eventClass)
              ?.name,
            users,
            notifyMethod: entry.how.split(', ').map(method => method.toLowerCase())
          } as NotificationConfiguration;
        });
        setNotificationConfigurations(notificationConfigurations);
      } catch (err) {
        console.log(err);
        showErrorNotification('Error fetching notification configurations');
      } finally {
        setNotificationDataLoading(false);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActionLoading, orgDataLoading, allOrganisationRules]);

  const createNotification = async (config: NotificationConfiguration) => {
    try {
      setUserActionLoading(true);
      await SpidertracksSDK.getNotificationConfigurationService().save(organisationId, {
        aircraft: config.aircraft,
        recipients: expand(config.users, users, groups),
        eventClass: config.eventClass,
        eventRules: config.eventRules,
        eventRuleIds: config.eventRuleIds,
        notificationTypes: config.notifyMethod,
        excludedSeverities: config.excludedSeverities
      });
      showSuccessNotification('Notification configuration was added successfully');
    } catch (err) {
      showErrorNotification('Error saving notification configuration');
    } finally {
      setUserActionLoading(false);
    }
  };

  const updateNotification = async (config: NotificationConfiguration) => {
    const errorMessage = 'Error updating notification configuration';
    if (!config.id) {
      showErrorNotification(errorMessage);
      return;
    }

    try {
      setUserActionLoading(true);
      await SpidertracksSDK.getNotificationConfigurationService().update(
        organisationId,
        {
          id: config.id,
          aircraft: config.aircraft,
          recipients: expand(config.users, users, groups),
          eventClass: config.eventClass,
          eventRules: config.eventRules,
          eventRuleIds: config.eventRuleIds,
          notificationTypes: config.notifyMethod,
          excludedSeverities: config.excludedSeverities
        },
        config.id
      );
      showSuccessNotification('Notification configuration was updated successfully');
    } catch (err) {
      showErrorNotification(errorMessage);
    } finally {
      setUserActionLoading(false);
    }
  };

  const deleteNotification = async (notificationConfigurationId: string) => {
    const errorMessage = 'Error deleting notification configuration';
    if (!notificationConfigurationId) {
      showErrorNotification(errorMessage);
      return;
    }

    try {
      setUserActionLoading(true);
      await SpidertracksSDK.getNotificationConfigurationService().delete(
        organisationId,
        notificationConfigurationId
      );
      showSuccessNotification('Notification configuration deleted successfully');
    } catch (err) {
      showErrorNotification(errorMessage);
    } finally {
      setUserActionLoading(false);
    }
  };

  const openEditNotificationConfigDrawer = (
    record: GeofenceNotificationTableRow | ExceedenceNotificationTableRow
  ) => ({
    onClick: () => {
      setDrawerVisibility(true);
      setSelectedNotificationConfiguration(
        notificationConfigurations?.find(rule => rule.id === record.key) ?? null
      );
    }
  });

  return (
    <div className="px-5 py-4" style={style}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Notifications"
          subTitle="View and manage event notifications"
        />
        <Button
          size="large"
          onClick={openAddNotificationConfigDrawer}
          type="primary"
          ghost
          style={{ padding: '0 50px' }}
          disabled={notificationDataLoading || orgDataLoading}
        >
          Add
        </Button>
      </div>
      <NotificationsTabsContainer
        geofenceNotificationTableRows={geofenceNotificationTableRows}
        exceedenceNotificationTableRows={exceedenceNotificationTableRows}
        loading={notificationDataLoading || orgDataLoading}
        onRow={openEditNotificationConfigDrawer}
        organisationId={organisationId}
      />
      {drawerVisibility && (
        <NotificationDrawer
          initialData={selectedNotificationConfiguration}
          visibility={drawerVisibility}
          onClose={onDrawerClose}
          users={users}
          groups={groups}
          aircraft={aircraft}
          createNotification={createNotification}
          updateNotification={updateNotification}
          deleteNotification={deleteNotification}
          geofenceEventRules={geofenceEventRules}
          exceedenceEventRules={describedRules}
        />
      )}
    </div>
  );
};

export default NotificationsContainer;
