import React, { useState, useEffect } from 'react';
import {
  Avatar,
  ConversationHeader as CSConversationHeader,
  ConversationHeaderProps
} from '@chatscope/chat-ui-kit-react';
import {
  getContactById,
  getConversationById,
  getActiveContactId,
  getContacts
} from '../../redux/selectors/spiderTxt';
import { useSelector } from 'react-redux';
import { ContactType, MessageType } from '../../types/spidertxt';

interface ExtentedConversationHeader extends ConversationHeaderProps {
  as?: string | typeof CSConversationHeader;
}

const ConversationHeader: React.FC<ExtentedConversationHeader> = () => {
  const contacts = useSelector(getContacts);
  const activeContactId = useSelector(getActiveContactId)!;
  const activeContact = useSelector(useSelector(state => getContactById(state, activeContactId)))!;
  const activeConversation = useSelector(
    useSelector(state => getConversationById(state, activeContactId))
  );

  const [isOpen, setIsOpen] = useState(false);
  const changeOpenState = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const nonSystemMessageCount =
      activeConversation != undefined
        ? activeConversation.messages.filter(el => el.type != MessageType.SYSTEM).length
        : 0;
    setIsOpen(activeConversation ? (nonSystemMessageCount > 0 ? false : true) : true);
  }, [activeConversation]);

  const groupMembers =
    activeContact.contactType == ContactType.GROUP
      ? activeContact.memberIds
          .map(memberId => contacts.find(contact => contact.id === memberId))
          .filter(contact => contact !== undefined)
          .sort(function(a, b) {
            return a!.displayName.toLowerCase().localeCompare(b!.displayName.toLowerCase());
          })
      : undefined;

  return (
    <CSConversationHeader>
      <CSConversationHeader.Content userName={activeContact.displayName}>
        <div className={`cs-expansion-panel ${isOpen ? 'cs-expansion-panel--open' : ''}`}>
          <div className="cs-expansion-panel__header">
            <div style={{ paddingRight: '1.5em' }}>
              <Avatar as="" src={activeContact.imageUrl} />
            </div>
            <div
              onClick={changeOpenState}
              className="cs-expansion-panel__icon"
              style={{
                transform: isOpen ? 'rotate(180deg)' : '',
                transition: 'transform 150ms ease'
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                className="svg-inline--fa fa-chevron-down fa-w-14 "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                ></path>
              </svg>
            </div>
            <div className="cs-expansion-panel__title">{activeContact.displayName}</div>
          </div>
          <div className="cs-expansion-panel__content">
            <div className="cs-expansion-panel__content-centered">
              <div className="cs-expansion-panel__content_label">
                {activeContact.contactType == ContactType.GROUP && 'ORGANISATION'}

                {activeContact.contactType == ContactType.INDIVIDUAL &&
                  `${activeContact.organisations.length} COMMON ORGANISATION${
                    activeContact.organisations.length > 1 ? 'S' : ''
                  }${activeContact.organisations.length > 5 ? ' (scroll to see all)' : ''}`}
              </div>
              <div className="cs-expansion-panel__content_value" style={{ maxHeight: '100px' }}>
                {activeContact.contactType == ContactType.GROUP
                  ? activeContact.organisationName
                  : activeContact.organisations
                      .sort(function(a: string, b: string) {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                      })
                      .map((organisation: string) => <div key={organisation}>{organisation}</div>)}
              </div>
              {groupMembers && (
                <>
                  <div className="cs-expansion-panel__content_label">
                    {`${groupMembers.length} `}
                    MEMBERS{groupMembers.length > 10 && ' (scroll to see all)'}
                  </div>
                  <div className="cs-expansion-panel__content_value">
                    {groupMembers!.map(member => (
                      <div key={member!.id}>{member!.displayName}</div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </CSConversationHeader.Content>
    </CSConversationHeader>
  );
};

export default ConversationHeader;
