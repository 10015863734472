import React, { FC, useEffect } from 'react';
import { Form, Row } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';

import { collapseGroups, GroupSelectionOption, MultiSelectForm } from '@spidertracks/components';

import {
  GroupType,
  OrganisationGroup
} from '../../../common/api/spidertracks-sdk/private/services/UserService';

export interface User {
  id: string;
  name: string;
  isAdmin: boolean;
}

interface UserSelectorProps extends FormComponentProps {
  initialValue: CheckboxValueType[];
  isEditForm: boolean;
  showAdminChoice?: boolean;
  showAllChoice?: boolean;
  users: User[];
  userOptionTransformer: (u: User) => CheckboxOptionType;
  groups?: OrganisationGroup[];
  groupOptionTransformer?: (g: OrganisationGroup) => GroupSelectionOption;
  required?: boolean;
  disabled?: boolean;
}

export const UserSelector: FC<UserSelectorProps> = ({
  form,
  initialValue,
  users,
  userOptionTransformer,
  groups,
  groupOptionTransformer,
  showAdminChoice,
  showAllChoice,
  required = true,
  disabled = false
}) => {
  const onReset = () => form.setFieldsValue({ users: initialValue });

  useEffect(() => {
    form.resetFields();
  }, [initialValue.length]);

  let allUsersGroupId: string | undefined;
  let allAdminsGroupId: string | undefined;
  if (groups?.length) {
    const allUsersIndex = groups.findIndex(
      (g: OrganisationGroup) => g.type === GroupType.ALL_USERS
    );
    const allAdminsIndex = groups.findIndex(
      (g: OrganisationGroup) => g.type === GroupType.ALL_ADMINS
    );

    allUsersGroupId = allUsersIndex > -1 ? groups[allUsersIndex].id : undefined;
    allAdminsGroupId = allAdminsIndex > -1 ? groups[allAdminsIndex].id : undefined;
  }

  const options = users.map(userOptionTransformer);

  const userById = new Map<string, User>([
    ...(users.map(value => {
      return [value.id, value];
    }) as [string, User][])
  ]);

  const specialOptions: GroupSelectionOption[] =
    groups && groupOptionTransformer
      ? groups
          .filter(g => g.id !== allAdminsGroupId && g.id !== allUsersGroupId)
          .map(groupOptionTransformer)
      : [];

  if (showAdminChoice) {
    specialOptions.unshift({
      label: 'All admin',
      id: allAdminsGroupId ?? 'ADMINS',
      onSelectFilter: (value: CheckboxValueType) => userById.get(value as string)?.isAdmin || false
    });
  }
  if (showAllChoice) {
    specialOptions.unshift({
      label: 'All members',
      id: allUsersGroupId ?? '*',
      onSelectFilter: () => true,
      isSelectAll: true
    });
  }

  const groupMapping = new Map<string, string>(
    specialOptions.map((value: GroupSelectionOption) => [value.id, value.label])
  );

  return (
    <Form.Item label={'Members'} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {form.getFieldDecorator('users', {
          initialValue,
          rules: [
            {
              required: required,
              message: 'Please select user'
            }
          ]
        })(
          <MultiSelectForm
            disabled={disabled}
            closedLabel={collapseGroups(
              groupMapping,
              'user',
              'users',
              required ? 'Select members' : 'Select members (optional)'
            )(form.getFieldValue('users').map((u: string) => userById.get(u)?.name || u))}
            fieldName="users"
            form={form}
            options={options}
            onReset={onReset}
            showSearch
            shifted={true}
            groups={specialOptions}
          />
        )}
      </Row>
    </Form.Item>
  );
};

export default UserSelector;
