import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChartSeries,
  getElapsedTime,
  getPlaybackSpeed,
  getSelectedTrackFlightData
} from '../../../redux/selectors/flightExplorer';
import useChartScripts from './useChartScripts';
import {
  DisplayPoint,
  PrivateTrackData
} from '../../../common/api/spidertracks-sdk/types/TrackData';
import { setElapsedTime } from '../../../redux/slice/flightExplorer/flightExplorer';
import {
  FlightReportDataAxis,
  FlightReportDataSeries,
  getFlightReportDataAxes,
  toAircraftSeries,
  toAxis,
  toSeries,
  transformFlightDataSamplesToSeries,
  transformTrackDataToSeries
} from './transform';
import { FullState } from '../../../store';
import { Point } from 'highcharts';
import { ChartParameters } from './ChartParameters';
import { getUserData } from '../../../redux/selectors/userData';
import { getLatestPointBefore } from '../utilities/getLatestPointBefore';
import { setSelectedDisplayPoint } from '../../../redux/slice/events';
import { aircraftMarkerColors } from '../../Flying/Map/GoogleMap/markers/markerColorCodes';
import styled from 'styled-components';

export interface FlightExplorerChartProps {
  selectedTrack: PrivateTrackData;
  displayPoints: DisplayPoint[];
  aircraftId: string;
  timezone: string;
}

const ChartContainerWrapper = styled.div`
  display: flex;
  width: 100%;
`;

type symbolTypes = 'circle' | 'diamond' | 'square' | 'triangle' | 'triangle-down';

const getOptions = (
  timezone: string,
  dateFormat: string,
  axes: FlightReportDataAxis[],
  series: FlightReportDataSeries[],
  aircraftColor: string,
  clickCallback: (category: string) => void
): Highcharts.Options => {
  const aircraftSeries = toAircraftSeries(series);
  // @ts-ignore
  const generateName = series => {
    const symbolUnicode = {
      circle: '●',
      diamond: '♦',
      square: '■',
      triangle: '▲',
      'triangle-down': '▼'
    };
    const symbol = symbolUnicode[series.symbol as symbolTypes] || '●';
    return `${symbol} ${series.name}`;
  };

  return {
    boost: {
      enabled: true,
      useGPUTranslations: true
    },
    tooltip: {
      shared: true,
      animation: false,
      enabled: true,
      useHTML: true,
      headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
      pointFormatter: function() {
        if (this.series?.name != 'AIRCRAFT_POSITION') {
          // @ts-ignore
          return `
            <tr>
              <td>
                <span style="color:${this.series?.color};font-weight:bold;">
                  ${generateName(this.series)}

                </span>
              </td>
              <td>
                <span style="margin-left:10px">
                  ${this.y && this.y.toFixed(2)}
                </span>
              </td>
            </tr>`;
        }
        return '';
      },
      xDateFormat: dateFormat,
      footerFormat: '</table>',
      valueDecimals: 2,
      positioner: function() {
        return { x: 80, y: 10 };
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Time'
      },
      gridLineWidth: 1,
      dateTimeLabelFormats: {
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e %H:%M',
        week: '%e %H:%M',
        month: '%e %H:%M',
        year: '%e %H:%M'
      },
      plotLines: [
        {
          color: aircraftColor,
          width: 2,
          value: 0
        }
      ]
    },
    yAxis: axes.map(toAxis),
    series: [
      aircraftSeries,
      ...series.filter(s => s.name === 'Terrain').map((v, i) => toSeries(v, clickCallback, i)),
      ...series
        .filter(s => s.axis === 'LEFT' && s.name != 'Terrain')
        .map((v, i) => toSeries(v, clickCallback, i)),
      ...series
        .filter(s => s.axis === 'RIGHT' && s.name != 'Terrain')
        .map((v, i) => toSeries(v, clickCallback, i))
    ],
    legend: {
      enabled: false,
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top'
    },
    plotOptions: {
      bar: {
        minPointLength: 3
      },
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    navigation: {
      buttonOptions: {
        enabled: true
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
            //"viewData"
          ]
        }
      }
    },
    time: {
      timezone
    },
    credits: {
      enabled: false
    }
  };
};

const THROTTLE_LIMIT_MS = 1000;
const CHART_REDRAW = 250;

export const FlightExplorerChart: React.FC<FlightExplorerChartProps> = (
  props: FlightExplorerChartProps
) => {
  const dispatch = useDispatch();
  const playbackSpeed = useSelector(getPlaybackSpeed);
  const { displayPoints, timezone } = props;
  const uniqueDisplayPoints = useMemo(() => {
    const seen = new Set();
    return displayPoints.filter(
      (() => {
        return displayPoint => {
          const key = `${displayPoint.timestamp}`;
          if (seen.has(key)) {
            return false;
          } else {
            seen.add(key);
            return true;
          }
        };
      })()
    );
  }, [displayPoints]);

  const scriptLoadStatus = useChartScripts();
  const [nav, setNav] = useState<Highcharts.Chart>();
  const [chart, setChart] = useState<Highcharts.Chart>();
  const [dateFormat, setDateFormat] = useState<string>('');
  const [lastNavRedraw, setLastNavRedraw] = useState<number>(0);
  const [lastChartUpdate, setLastChartUpdate] = useState<number>(0);

  const elapsedTime = useSelector(getElapsedTime);
  const selectedTrack = props.selectedTrack;
  const { isFlightDataFetchingInProgress, isPlaying } = useSelector(
    (state: FullState) => state.flightExplorer,
    (prev, next) =>
      prev.isFlightDataFetchingInProgress === next.isFlightDataFetchingInProgress &&
      prev.isPlaying === next.isPlaying
  );
  const flightData = useSelector(getSelectedTrackFlightData);
  const trackStartTime = selectedTrack.departedTime;
  const trackEndTime = selectedTrack.points[selectedTrack.points.length - 1].timestamp;
  const currentPointTime = trackStartTime + elapsedTime * 1000;
  const chartSeries = useSelector(getChartSeries);
  const userData = useSelector(getUserData);
  const flightDataEmpty = flightData.raw.length === 0;

  const { iconColour: iconColor } = useSelector(
    // @ts-ignore
    state => state.aircraft[props?.aircraftId] || { iconColour: null, type: null, model: null }
  );
  const aircraftColor = iconColor ? iconColor.toLowerCase() : 'default';
  const markerColor = aircraftMarkerColors[aircraftColor as keyof typeof aircraftMarkerColors];
  const clickCallback = (category: string) => {
    const elapsedTimeSeconds = (Number(category) - selectedTrack.departedTime) / 1000;
    dispatch(
      setElapsedTime({ elapsedTime: elapsedTimeSeconds, updateSelectedDisplayPoint: false })
    );

    const latestDisplayPointBefore = getLatestPointBefore(
      selectedTrack.points,
      selectedTrack.departedTime,
      elapsedTimeSeconds
    );
    if (latestDisplayPointBefore) {
      dispatch(setSelectedDisplayPoint(latestDisplayPointBefore));
    }
  };

  const getExtremes = (range: { min: number; max: number }): { newMin: number; newMax: number } => {
    const window = range.max - range.min;
    if (currentPointTime - window / 2 < trackStartTime) {
      return { newMin: trackStartTime, newMax: trackStartTime + window };
    } else if (currentPointTime + window / 2 > trackEndTime) {
      return { newMin: trackEndTime - window, newMax: trackEndTime };
    } else {
      return { newMin: currentPointTime - window / 2, newMax: currentPointTime + window / 2 };
    }
  };

  const setGlobalTimezone = (timezone: string) => {
    if (chart && nav && timezone) {
      // @ts-ignore
      Highcharts.setOptions({
        time: {
          timezone
        }
      });
    }
  };

  useEffect(() => {
    if (chart) {
      chart.update({
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: !isPlaying
              }
            }
          }
        }
      });
    }
  }, [chart, isPlaying]);

  useEffect(() => {
    let df = '%Y-%m-%d %H:%M:%S';
    if (userData.dateFormat === 'MM_DD_YYYY') {
      df = '%m/%d/%Y %H:%M:%S';
    } else if (userData.dateFormat === 'DD_MM_YYYY') {
      df = '%d/%m/%Y %H:%M:%S';
    }
    setDateFormat(df);
  }, [userData]);

  useEffect(() => {
    setGlobalTimezone(timezone);
  }, [timezone]);

  useEffect(() => {
    // Creates a default chart with data from the track
    if (scriptLoadStatus === 'ready') {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      const navigator = Highcharts.navigator('chart-navigator-container', {
        handles: {
          symbols: ['doublearrow', 'doublearrow'],
          backgroundColor: '#666',
          borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(180,180,255,0.2)'
      });

      const axes = getFlightReportDataAxes(chartSeries, userData);
      const series: FlightReportDataSeries[] =
        !isFlightDataFetchingInProgress && !flightDataEmpty
          ? transformFlightDataSamplesToSeries(flightData, userData, chartSeries)
          : transformTrackDataToSeries(uniqueDisplayPoints, userData, chartSeries);

      setGlobalTimezone(timezone);

      // @ts-ignore
      const highChart = Highcharts.chart(
        'chart-container',
        getOptions(timezone, dateFormat, axes, series, markerColor, clickCallback)
      );

      // @ts-ignore
      setNav(navigator);
      // @ts-ignore
      setChart(highChart);
      navigator.bind(highChart);
      // @ts-ignore
      // eslint-disable-next-line no-undef
      Highcharts.Pointer.prototype.reset = function() {
        return undefined;
      };
    }
  }, [scriptLoadStatus, isFlightDataFetchingInProgress, flightData, chartSeries, timezone]);

  const redrawCharts = useCallback(() => {
    if (nav && chart) {
      chart.redraw(false);
      if (isPlaying) {
        if (lastNavRedraw + THROTTLE_LIMIT_MS < Date.now()) {
          // @ts-ignore
          nav.navigator.chart.redraw(false);
          setLastNavRedraw(Date.now());
        }
      } else {
        // @ts-ignore
        nav.navigator.chart.redraw(false);
        setLastNavRedraw(Date.now());
      }
    }
  }, [isPlaying, nav, chart]);

  //FIXME: when we modify elapsedTime, pass a reason so the lowRes point that subsequently gets selected doesn't dispatch a quantised elapsedTime unless it was clicked directly.
  useEffect(() => {
    if (nav && chart) {
      if (chart.xAxis === undefined) {
        return;
      }
      if (isPlaying && lastChartUpdate + CHART_REDRAW > Date.now()) {
        return;
      }

      // Update plot line first
      if (chart.xAxis[0].options.plotLines && chart.xAxis[0].options.plotLines[0]) {
        chart.xAxis[0].options.plotLines[0].value = currentPointTime;
        chart.xAxis[0].update(chart.xAxis[0].options, false);
      }

      // @ts-ignore
      const extremes = getExtremes(nav.getRange());
      chart?.axes[0].setExtremes(extremes.newMin, extremes.newMax, false);
      // @ts-ignore
      nav.setRange(extremes.newMin, extremes.newMax, false, false);

      //FIXME: if we track where else changes it, this could be a direct index to reset where it was and set where it is:
      //QUESTION: how do we index directly by .x?
      const pointsToUpdate: { point: Point; state: boolean }[] = [];

      for (const series of chart.series.filter(s => s.name !== 'AIRCRAFT_POSITION')) {
        for (const point of series.points) {
          if (point.x == currentPointTime) {
            pointsToUpdate.push({ point, state: true });
          }
          if (point.options.marker?.enabled) {
            pointsToUpdate.push({ point, state: false });
          }
        }
      }

      pointsToUpdate.forEach(p => {
        p.point.update({ marker: { enabled: p.state } }, false);
      });
      redrawCharts();
      const pointsForeLegend = pointsToUpdate.filter(p => p.state);
      if (pointsForeLegend.length > 0) {
        chart.tooltip.refresh(pointsForeLegend.map(p => p.point));
      } else {
        chart.tooltip.hide();
      }
      setLastChartUpdate(Date.now());
    }
  }, [elapsedTime, chart, nav, playbackSpeed]);

  return (
    <div className={'bottomContainer shadow content'}>
      <ChartContainerWrapper data-testid={'chart-container-wrapper'}>
        <ChartParameters />
        <div className="chart-container">
          <div id="chart-container" className="highcharts-light"></div>
          <div id="chart-navigator-container" className="navBar highcharts-light"></div>
        </div>
      </ChartContainerWrapper>
    </div>
  );
};

export default FlightExplorerChart;
