import { createSlice } from '@reduxjs/toolkit';
import {
  SpiderTxtState,
  ContactCategory,
  UIConversation,
  UIMessage,
  MessageStatus,
  UIContact
} from '../../../types/spidertxt';

const initialState: SpiderTxtState = {
  runtime: {
    activeContactId: undefined,
    contactFilterText: '',
    contactFilteredCategory: ContactCategory.ALL,
    draftMessages: {},
    initialLoadComplete: false,
    lastFullPolledOn: 0,
    lastConversationsPolledOn: 0
  },
  unreadMessageCount: 0,
  readMessageMap: {},
  contacts: {},
  conversations: {}
};

export const spiderTxtSlice = createSlice({
  name: 'spiderTxt',
  initialState,
  reducers: {
    setActiveContactId: (state, action: { payload: string; type: string }) => {
      state.runtime.activeContactId = action.payload;
    },
    setDraftMessage: (
      state,
      action: { payload: { contactId: string; body: string }; type: string }
    ) => {
      state.runtime.draftMessages[action.payload.contactId] = action.payload.body;
    },
    setContactFilterText: (state, { payload }) => {
      state.runtime.contactFilterText = payload.contactFilterText;
    },
    setContactFilteredCategory: (state, { payload }) => {
      state.runtime.contactFilteredCategory = payload.contactFilteredCategory;
    },
    resetFilteredTextAndCategory: state => {
      state.runtime.contactFilteredCategory = ContactCategory.ALL;
      state.runtime.contactFilterText = '';
    },
    reset: state => {
      state.runtime.activeContactId = undefined;
      state.contacts = {};
      state.runtime.contactFilterText = '';
      state.unreadMessageCount = 0;
    },
    setContacts: (
      state,
      action: {
        payload: {
          contacts: { [key: string]: UIContact };
        };
      }
    ) => {
      for (let contact of Object.values(action.payload.contacts)) {
        const existing = state.contacts[contact.id];

        // Makes sure if we've downloaded an image, it doesn't get overwritten if we poll
        const oldImage = existing?.imageUrl ?? contact.imageUrl;
        state.contacts[contact.id] = contact;
        state.contacts[contact.id].imageUrl = oldImage;
      }
    },
    setContactImage: (
      state,
      action: {
        payload: {
          contactId: string;
          imageUrl: string;
        };
      }
    ) => {
      state.contacts[action.payload.contactId].imageUrl = action.payload.imageUrl;
    },
    setConversations: (
      state,
      action: {
        payload: {
          conversations: { [key: string]: UIConversation };
        };
      }
    ) => {
      // Update conversations
      for (let conversationId in action.payload.conversations) {
        const conversation = action.payload.conversations[conversationId];
        if (state.conversations[conversation.contactId] == undefined) {
          // add conversation
          state.conversations[conversation.contactId] = conversation;
        } else {
          // update conversation messages
          for (let message of conversation.messages) {
            if (
              !state.conversations[conversation.contactId].messages.some(el => el.id === message.id)
            ) {
              state.conversations[conversation.contactId].messages.push(message);
            }
          }
          state.conversations[conversation.contactId].messages.sort(
            (a, b) => a.timestamp - b.timestamp
          );
          state.conversations[conversation.contactId].unreadMessageCount =
            conversation.unreadMessageCount;
        }
      }

      // runtime
      state.runtime.initialLoadComplete = true;
      const messageCounts = Object.values(action.payload.conversations).map(
        el => el.unreadMessageCount
      );
      state.unreadMessageCount = messageCounts.reduce((acc, count) => acc + count, 0);
      state.runtime.lastFullPolledOn = Date.now();
      state.runtime.lastConversationsPolledOn = Date.now();
    },
    addSendingMessageToConversation: (
      state,
      action: {
        payload: { contactId: string; message: UIMessage };
      }
    ) => {
      state.conversations[action.payload.contactId].messages.push(action.payload.message);

      state.conversations[action.payload.contactId].messages.sort(
        (a, b) => a.timestamp - b.timestamp
      );
    },
    addMessagesToConversation: (
      state,
      action: {
        payload: { contactId: string; messages: UIMessage[] };
      }
    ) => {
      // remove "sending" messages
      state.conversations[action.payload.contactId].messages = state.conversations[
        action.payload.contactId
      ].messages.filter(el => el.status !== MessageStatus.SENDING);

      const allExisting = state.conversations[action.payload.contactId].messages;
      for (let message of action.payload.messages) {
        const existingMessage = allExisting.find(el => el.id === message.id);
        if (existingMessage == undefined) {
          state.conversations[action.payload.contactId].messages.push(message);
        } else {
          existingMessage.status = message.status;
        }
      }

      state.conversations[action.payload.contactId].messages.sort(
        (a, b) => a.timestamp - b.timestamp
      );
    },
    updateDraftText: (
      state,
      action: {
        payload: { contactId: string; draftMessage: string | undefined };
      }
    ) => {
      if (action.payload.draftMessage !== undefined) {
        state.runtime.draftMessages[action.payload.contactId] = action.payload.draftMessage;
      }
    },
    updateIsAllMessagesLoadedForConversation: (
      state,
      action: { payload: { contactId: string; isAllMessagesLoaded: boolean } }
    ) => {
      state.conversations[action.payload.contactId].isAllMessagesLoaded =
        action.payload.isAllMessagesLoaded;
    },
    updateLastReadMessageForContact: (
      state,
      action: { payload: { contactId: string; messageId: string } }
    ) => {
      state.readMessageMap[action.payload.contactId] = action.payload.messageId;
    },
    setUnreadMessageCountForConversation: (
      state,
      action: { payload: { conversationId: number; count: number } }
    ) => {
      const conversation = Object.values(state.conversations).find(
        el => el.id === action.payload.conversationId
      );
      if (conversation) {
        conversation.unreadMessageCount = action.payload.count;
      }
    },
    updateTotalUnreadMessageCount: (state, action: { payload: { unreadMessageCount: number } }) => {
      state.unreadMessageCount = action.payload.unreadMessageCount;
    },
    updateConversation: (
      state,
      action: {
        payload: {
          conversation: UIConversation;
        };
      }
    ) => {
      state.conversations[action.payload.conversation.contactId] = action.payload.conversation;
    }
  }
});

export const {
  setActiveContactId,
  setDraftMessage,
  setContacts,
  setContactImage,
  setConversations,
  addMessagesToConversation,
  addSendingMessageToConversation,
  updateDraftText,
  setContactFilterText,
  resetFilteredTextAndCategory,
  updateLastReadMessageForContact,
  setContactFilteredCategory,
  setUnreadMessageCountForConversation,
  updateTotalUnreadMessageCount,
  updateIsAllMessagesLoadedForConversation,
  updateConversation
} = spiderTxtSlice.actions;

export default spiderTxtSlice;
