// todo move these types elsewhere

export enum MessageType {
  SYSTEM = 'SYSTEM',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}

export enum MessageStatus {
  /** Created from frontend */
  SENDING = 'SENDING',
  /** Received from the backend */
  SENT = 'SENT',
  /** Received from the backend and all statuses are "DELIVERED" */
  DELIVERED = 'DELIVERED'
}

export interface MessageToSend {
  body: string;
  conversationId: number;
}

export interface Message extends MessageToSend {
  id: string;
  /** Is where the message should go (individual/group). Is the groupId for group convos */
  contactId: string;
  /** User who sent the message. Is an all 0 UUID for system messages (SYSTEM_SENDER_ID) */
  attributionId: string;
  timestamp: number;
  type: MessageType;
  status: MessageStatus;
}

/** Is render-ready because it has the sender display name */
export interface UIMessage extends Message {
  senderDisplayName: string;
  /** String in user's timezone */
  messageDay: string;
  /** String in user's timezone */
  messageTime: string;
}

export interface Conversation {
  readonly id: number;
  readonly contactId: string;
  readonly messages: Message[];
  readonly unreadMessageCount: number;
}
/** Render ready because the message is of UIMessage type */
export interface UIConversation extends Omit<Conversation, 'messages'> {
  readonly messages: UIMessage[];
  readonly isAllMessagesLoaded: boolean;
}

export enum ContactType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP'
}

export interface BaseContact {
  id: string;
  displayName: string;
}

export interface UserContact extends BaseContact {
  contactType: ContactType.INDIVIDUAL;
  /** URL given by backend. Requires auth to fetch so cannot be placed in img tag */
  avatarUrl: string | undefined;
  organisations: string[];
}

export interface GroupContact extends BaseContact {
  contactType: ContactType.GROUP;
  memberIds: string[];
  organisationId: string;
  organisationName: string;
}

export type Contact = UserContact | GroupContact;

export type UIContact = Contact & {
  /** Actual URL to put in the img tag */
  imageUrl: string;
};

export enum ContactCategory {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL'
}

export interface SpiderTxtState {
  runtime: {
    activeContactId: string | undefined;
    contactFilterText: string;
    contactFilteredCategory: ContactCategory;
    /** contactId -> string | undefined */
    draftMessages: { [key: string]: string };
    initialLoadComplete: boolean;
    /** Epoch ms we last polled the full spidertxt update of contacts + conversations. Used to prevent overloading the server */
    lastFullPolledOn: number;
    /** Epoch ms we last polled conversations. Used to prevent overloading the server */
    lastConversationsPolledOn: number;
  };
  unreadMessageCount: number;
  readMessageMap: { [key: string]: string };
  /** contactId -> Contact */
  contacts: { [key: string]: UIContact };
  /** contactId -> Conversation */
  conversations: { [key: string]: UIConversation };
}
