import React from 'react';
import { Message as CSMessage } from '@chatscope/chat-ui-kit-react';
import { MessageType, UIMessage, MessageStatus } from '../../types/spidertxt';

export enum MessagePosition {
  SINGLE = 'single',
  FIRST = 'first',
  NORMAL = 'normal',
  LAST = 'last'
}

interface MessageProps {
  message: UIMessage;
  position: MessagePosition;
  showSenderName: boolean;
  showStatus: boolean;
}

const MessageStatusMap = {
  [MessageStatus.SENT]: 'Sent',
  [MessageStatus.SENDING]: 'Sending',
  [MessageStatus.DELIVERED]: 'Delivered'
};

const Message: React.FC<MessageProps> = ({ message, position, showSenderName, showStatus }) => {
  if (message.type != MessageType.RECEIVED && message.type != MessageType.SENT) {
    return <></>;
  }
  return (
    <CSMessage
      model={{
        direction: message.type == MessageType.RECEIVED ? 'incoming' : 'outgoing',
        message: message.body,
        position: position,
        sender: message.senderDisplayName
      }}
    >
      {showSenderName && <CSMessage.Header sender={message.senderDisplayName} />}

      <CSMessage.Footer
        sender={showStatus ? MessageStatusMap[message.status] : undefined}
        sentTime={message.messageTime}
      />
    </CSMessage>
  );
};

export default Message;
