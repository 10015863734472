import React, { FunctionComponent, useCallback, useEffect } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import {
  populateSosNotifications,
  populateUnreadSpidertxt,
  populateUserData
} from '../../redux/reducers/navigationReducer/actions/thunk';
import {
  incrementPendingSpiderTxtNotifications,
  setUnreadSpidertxt
} from '../../redux/reducers/navigationReducer/actions';
import { bindActionCreators } from 'redux';
import { THIRTY_SECONDS, THREE_MINUTES } from '../constants';
import { FullState, StandardDispatch } from '../../store';
import {
  getSosNotifications,
  getSpiderTxtCount,
  getUserData,
  isOrgRoleOwnerOrAdmin
} from '../../redux/selectors/userData';
import Pendo from '../../components/Pendo';
import { notificationsToRemove, openNotification } from '../../components/SosNotification';
import { setSentryUserContext } from '../../sentry';
import {
  populateContactsAndConversations,
  processPushMessage
} from '../../redux/slice/spidertxt/thunk';
import {
  transformToDomainMessage,
  MessageFromSocket
} from '../../helpers/transformToDomainMessage';
import { useFeatureFlag } from '../../hooks';

const mapStateToProps = (state: FullState) => {
  const userData = getUserData(state);
  return {
    userData,
    userDataLoaded: userData && userData.id,
    spidertxtCount: getSpiderTxtCount(state),
    sosData: getSosNotifications(state)
  };
};

const mapDispatchToProps = (dispatch: StandardDispatch) => ({
  ...bindActionCreators(
    {
      populateUserData,
      setUnreadSpidertxt,
      populateUnreadSpidertxt,
      populateSosNotifications,
      incrementPendingNotifications: incrementPendingSpiderTxtNotifications,
      populateContactsAndConversations,
      processPushMessage
    },
    dispatch
  )
});

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IComponentProps = IStateProps & IDispatchProps;

export const PrivateDataLoader: FunctionComponent<IComponentProps> = ({
  sosData,
  userData,
  userDataLoaded,
  spidertxtCount,
  populateSosNotifications,
  populateUnreadSpidertxt,
  populateUserData,
  setUnreadSpidertxt,
  incrementPendingNotifications,
  populateContactsAndConversations,
  processPushMessage
}) => {
  /*
    Removed until we make a decision on LD
  let ldClient: LDClient | undefined;
  if (window.env.ENV === 'local' || window.env.ENV === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ldClient = useLDClient();
  }
  */
  const socket: ReturnType<typeof io> = io(window.env.STL_NODE_ORIGIN, {
    transports: ['websocket']
  });

  const { orgs = [] } = userData;
  const adminUserOrgIds = orgs
    .filter(isOrgRoleOwnerOrAdmin)
    .reduce((acc: string[], o) => acc.concat(o.org.id), []);
  const isNewSpidertxtFeatureEnabled = useFeatureFlag('new-spidertxt', adminUserOrgIds);

  const initSocket = useCallback(() => {
    socket.on('connect', () => {
      socket.emit('join', userData.id);
    });
    socket.on('spiderTxt', (message: MessageFromSocket) => {
      setUnreadSpidertxt(spidertxtCount + 1);
      incrementPendingNotifications(1);
      if (isNewSpidertxtFeatureEnabled) {
        console.log('Getting spidertxt contacts and conversations');
        populateContactsAndConversations().then(() => {
          processPushMessage(transformToDomainMessage(message));
        });
      }
    });
    /*
    if (window.env.ENV === 'local' || window.env.ENV === 'development') {
      if (ldClient && userData.orgs && userData.orgs.length > 0) {
        const userOrgIds = userData.orgs.map<string>(o => o.org.id);
        let context = {
          kind: 'user',
          key: userData.id,
          name: userData.displayName,
          email: userData.email,
          orgs: userOrgIds
        };
        ldClient.identify(context, undefined);
      }
    }
    */
  }, [userData, spidertxtCount, setUnreadSpidertxt, socket]);

  useEffect(() => {
    populateUserData();
    populateUnreadSpidertxt();
    populateSosNotifications();
    // This setInterval is necessary, PrivateDataLoader will poll every 30 secs for sosData and MapNavigation will compare newProps to prevProps and open a new open notification if needed
    const spidertxtInterval = setInterval(populateUnreadSpidertxt, THIRTY_SECONDS);
    const sosInterval = setInterval(populateSosNotifications, THIRTY_SECONDS);
    let fullSpidertxtInterval: NodeJS.Timer | undefined = undefined;
    if (isNewSpidertxtFeatureEnabled) {
      fullSpidertxtInterval = setInterval(() => {
        console.log(Date.now() + '- new SpiderTxt privatedataloader poll');
        populateContactsAndConversations();
      }, THREE_MINUTES);
    }

    return () => {
      if (socket) {
        socket.close();
      }
      clearInterval(spidertxtInterval);
      clearInterval(sosInterval);
      if (fullSpidertxtInterval) {
        clearInterval(fullSpidertxtInterval);
      }
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDataLoaded) {
      populateContactsAndConversations();
    }
    initSocket();
  }, [userDataLoaded]);

  useEffect(() => {
    if (sosData && sosData.length > 0) {
      const idsArray = [];
      for (let i = 0; i < sosData.length; i += 1) {
        idsArray.push(sosData[i].id);
      }
      notificationsToRemove(idsArray);
      sosData.forEach(sosItem => {
        if (sosItem.tier === 'TWO') {
          openNotification(sosItem, true);
        } else {
          openNotification(sosItem, false);
        }
      });
    }
    if (sosData && sosData.length === 0) {
      notificationsToRemove([]);
    }
  }, [sosData]);

  if (userDataLoaded) {
    setSentryUserContext(userData);
  }

  return (userDataLoaded && <Pendo />) || null;
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateDataLoader);
