import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import './styles.scss';
import {
  DisplayPoint,
  PrivateTrackData
} from '../../../common/api/spidertracks-sdk/types/TrackData';
import { getSpinner } from '../../../util';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../helpers/formatTime';
import EventRow from './EventRow';
import { getTimelineEvents, TimelineEvent } from '../utilities/pointClassifier';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDisplayPoint } from '../../../redux/selectors/eventData';
import { getVehicleMarkerIconForEventsTimeline } from '../../Flying/Map/utils/drawing/marker';
import { setElapsedTime } from '../../../redux/slice/flightExplorer/flightExplorer';
import useOnScreen from './useOnScreen';

interface EventsTimelineProps {
  selectedTrack?: PrivateTrackData;
  displayPoints?: DisplayPoint[];
  timezone: string;
}

const EventsTimeline: React.FC<EventsTimelineProps> = ({
  selectedTrack,
  displayPoints,
  timezone
}) => {
  const eventsTimelineContainerRef = useRef<null | HTMLDivElement>(null);
  const scrollTargetDivPreviousRef = useRef<null | HTMLDivElement>(null);
  const scrollTargetDivNextRef = useRef<null | HTMLDivElement>(null);

  const scrollTargetDivPreviousVisible = useOnScreen(scrollTargetDivPreviousRef);
  const scrollTargetDivNextVisible = useOnScreen(scrollTargetDivNextRef);

  const onScroll = () => {
    // To prevent auto scrolling when user is scrolling in the event timeline.
    scrollTargetDivPreviousRef.current = null;
    scrollTargetDivNextRef.current = null;
  };

  useEffect(() => {
    const containerRef = eventsTimelineContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener('scroll', onScroll);

      return () => {
        if (containerRef) {
          containerRef.removeEventListener('scroll', onScroll);
        }
      };
    }
  }, []);

  const dispatch = useDispatch();
  const formatTimefn = dateFormatter('HH:mm:ss');
  let timelineEvents =
    displayPoints !== undefined ? getTimelineEvents(displayPoints, formatTimefn, timezone) : [];

  const getLatestEventBeforeCurrentTime = (eventData: TimelineEvent[], eventTime: number) => {
    const eventBefore = eventData.filter(e => e.point.timestamp <= eventTime);
    return eventBefore[eventBefore.length - 1];
  };

  const selectedDisplayPoint = useSelector(getSelectedDisplayPoint);
  if (!selectedDisplayPoint) {
    return <Fragment />;
  }
  if (!selectedTrack || !displayPoints) {
    return (
      <div data-testid="events-timeline_empty" className="event-panel">
        {getSpinner({})}
      </div>
    );
  }

  const latestEventBeforeCurrentTime = getLatestEventBeforeCurrentTime(
    timelineEvents,
    selectedDisplayPoint.timestamp
  );
  if (!latestEventBeforeCurrentTime) {
    return <Fragment />;
  }

  const currentPositionIndicator = {
    id: latestEventBeforeCurrentTime.point.id.toString(),
    name: 'Aircraft Marker',
    iconUrl: getVehicleMarkerIconForEventsTimeline(selectedTrack.aircraft.type),
    uniqueIcon: false,
    time: formatStandardDateStrings(
      formatTimefn,
      fromTimestamp(selectedDisplayPoint.timestamp),
      timezone
    )[0],
    point: selectedDisplayPoint,
    simulated: false
  };

  const eventPresentAtPositionIndicator =
    timelineEvents.find(e => e.point.timestamp === currentPositionIndicator.point.timestamp) !==
    undefined;

  if (!eventPresentAtPositionIndicator) {
    currentPositionIndicator.id += '_simulated';
    currentPositionIndicator.simulated = true;
    timelineEvents = [...timelineEvents, currentPositionIndicator].sort(
      (a, b) => a.point.timestamp - b.point.timestamp
    );
  }

  const selectedEventIndex = timelineEvents.findIndex(e => e.id === currentPositionIndicator.id);

  const scrollToPreviousEventDiv = () => {
    scrollTargetDivPreviousRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePointSelection = (point: DisplayPoint) => {
    const elapsedTime = Math.round((point.timestamp - selectedTrack.departedTime) / 1000);
    dispatch(setElapsedTime({ elapsedTime, updateSelectedDisplayPoint: true }));
  };

  if (selectedEventIndex === 0) {
    eventsTimelineContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  } else if (selectedEventIndex === timelineEvents.length - 1) {
    eventsTimelineContainerRef.current?.scrollTo({
      top: eventsTimelineContainerRef.current.scrollHeight,
      behavior: 'smooth'
    });
  } else {
    const scrollTargetAssigned =
      scrollTargetDivPreviousRef.current !== null && scrollTargetDivNextRef.current !== null;
    if (scrollTargetAssigned) {
      if (!scrollTargetDivNextVisible || !scrollTargetDivPreviousVisible) {
        scrollToPreviousEventDiv();
      }
    }
  }

  const isFutureEventSelected = (
    selectedEventIndex: number,
    currentEventIndex: number,
    totalEventCount: number
  ) => {
    if (selectedEventIndex === 0) {
      return false;
    }

    const eventsLeft = totalEventCount - currentEventIndex;
    const delta = Math.min(2, eventsLeft);
    const futureEventIndex = currentEventIndex + delta;

    return selectedEventIndex === futureEventIndex;
  };

  const isPastEventSelected = (
    selectedEventIndex: number,
    currentEventIndex: number,
    totalEventCount: number
  ) => {
    if (selectedEventIndex === 0) {
      return false;
    }

    const eventsLeft = totalEventCount - currentEventIndex;
    const delta = Math.min(2, eventsLeft);
    const pastEventIndex = currentEventIndex - delta;

    return selectedEventIndex === pastEventIndex;
  };

  return (
    <div className={'shadow eventsTimelineContainer'} ref={eventsTimelineContainerRef}>
      <Timeline data-testid="events-timeline">
        {timelineEvents.map((event, index) => {
          return (
            <EventRow
              key={index}
              encodedIconUrl={event.iconUrl}
              name={event.name}
              time={event.time}
              point={event.point}
              isSelectedEvent={event.id === currentPositionIndicator.id}
              isSimulatedEvent={event.simulated}
              handlePointSelection={handlePointSelection}
              scrollTargetDivPrevious={
                isFutureEventSelected(selectedEventIndex, index, timelineEvents.length)
                  ? scrollTargetDivPreviousRef
                  : undefined
              }
              scrollTargetDivNext={
                isPastEventSelected(selectedEventIndex, index, timelineEvents.length)
                  ? scrollTargetDivNextRef
                  : undefined
              }
            />
          );
        })}
      </Timeline>
    </div>
  );
};

EventsTimeline.propTypes = {
  selectedTrack: PropTypes.any,
  displayPoints: PropTypes.array,
  timezone: PropTypes.string.isRequired
};

export default EventsTimeline;
