import React, { useEffect, useRef } from 'react';
import { Form, Input, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';
import { Cancel } from '../../../common/form';
import { showRemoveModal } from '../RemoveModal';
import { showInviteAsMembersModal } from './InviteAsMembersModal';

export interface OrganisationGuestFormData {
  guestId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  organisationId: string;
}

export interface OrganisationGuestFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface ComponentProps extends FormComponentProps {
  initialData: OrganisationGuestFormData | null;
  onClose: () => void;
  inviteAsMembers: (emailAddresses: string[], customMessage?: string) => void;
  removeOrganisationGuests: (ids: string[]) => void;
  form: WrappedFormUtils;
}

export const OrganisationGuestForm: React.FC<ComponentProps> = ({
  initialData,
  onClose,
  inviteAsMembers,
  removeOrganisationGuests,
  form
}) => {
  const formRef = useRef(form);

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    formRef.current.setFields({
      firstName: { value: initialData.firstName },
      lastName: { value: initialData.lastName },
      email: { value: initialData.email },
      phoneNumber: { value: initialData.phoneNumber }
    });
  }, [initialData]);

  return (
    <div>
      <Form layout="vertical">
        <Form.Item label="First Name" style={{ marginRight: '10px' }}>
          {formRef.current.getFieldDecorator('firstName')(
            <Input placeholder={'First Name'} disabled={true} />
          )}
        </Form.Item>

        <Form.Item label="Last Name" style={{ marginRight: '10px' }}>
          {formRef.current.getFieldDecorator('lastName')(
            <Input placeholder={'Last Name'} disabled={true} />
          )}
        </Form.Item>

        <Form.Item label="Email Address" style={{ marginRight: '10px' }}>
          {formRef.current.getFieldDecorator('email')(
            <Input placeholder={'Email Address'} disabled={true} />
          )}
        </Form.Item>

        <Form.Item label="Mobile Phone Number" style={{ marginRight: '10px' }}>
          {formRef.current.getFieldDecorator('phoneNumber')(
            <Input placeholder={'Mobile Phone Number'} disabled={true} />
          )}
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'flex-end'
          }}
        >
          <Button
            type="danger"
            size="large"
            style={{ width: '85px', margin: '16px 8px 0 0' }}
            onClick={() => {
              if (initialData) {
                showRemoveModal({
                  modalType: 'guest',
                  ids: [initialData.guestId],
                  setIsSaving: () => {},
                  removeFunction: removeOrganisationGuests
                });
              }
            }}
          >
            Remove
          </Button>

          <Cancel onClose={onClose} />

          <Button
            block={true}
            type="primary"
            size="large"
            style={{
              whiteSpace: 'normal',
              height: 'auto',
              width: '85px',
              margin: '16px 8px 0 0',
              fontSize: '12px'
            }}
            onClick={() => {
              if (initialData?.email) {
                showInviteAsMembersModal({
                  guestEmails: [initialData.email],
                  inviteAsMembers: () => {
                    inviteAsMembers([initialData.email]);
                  }
                });
              }
            }}
          >
            Invite As Member
          </Button>
        </div>
      </Form>
    </div>
  );
};

OrganisationGuestForm.propTypes = {
  initialData: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  inviteAsMembers: PropTypes.func.isRequired,
  removeOrganisationGuests: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired
};
