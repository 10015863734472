import React, { useEffect } from 'react';
import {
  getActiveContactId,
  getContactById,
  getConversations
} from '../../redux/selectors/spiderTxt';
import { setActiveContactId } from '../../redux/slice/spidertxt/spiderTxt';
import { MessageStatus, UIMessage } from '../../types/spidertxt';
import { Avatar, Conversation } from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { createConversation, loadAvatar } from '../../redux/slice/spidertxt/thunk';
import { getUserData } from '../../redux/selectors/userData';
import { ClockCircleOutlined } from '@ant-design/icons';
import SingleCheck from '../../assets/single-check.svg';
import DoubleCheck from '../../assets/double-check.svg';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

interface ContactListItemProps {
  as: string | typeof Conversation;
  contactId: string;
}

const ContactListItem: React.FC<ContactListItemProps> = ({ contactId }) => {
  const dispatch = useDispatch();
  const activeContactId = useSelector(getActiveContactId);
  const user = useSelector(getUserData);
  /* Get only the required conversation, otherwise probaby re-render on every conversation update */
  const allConversations = useSelector(getConversations);
  const contact = useSelector(useSelector(state => getContactById(state, contactId)))!;
  const conversation = allConversations.find(conversation => conversation.contactId === contact.id);

  const { displayName } = contact;
  const { messages, unreadMessageCount } = conversation
    ? conversation
    : { messages: [], unreadMessageCount: 0 };

  let lastMessage: UIMessage | undefined;
  if (messages.length > 0) {
    const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);
    lastMessage = sortedMessages[sortedMessages.length - 1];
  }

  useEffect(() => {
    // load image asynchronously
    dispatch(loadAvatar(contact));
  }, []);

  let lastSenderName: React.ReactNode | undefined = undefined;
  if (lastMessage != undefined) {
    if (lastMessage.attributionId == user.id) {
      const icon =
        lastMessage.status == MessageStatus.SENDING ? (
          <ClockCircleOutlined style={{ color: '#999' }} />
        ) : lastMessage.status == MessageStatus.SENT ? (
          <SingleCheck />
        ) : (
          <DoubleCheck />
        );

      lastSenderName = <span>{icon} You:</span>;
    } else {
      lastSenderName = lastMessage.senderDisplayName;
    }
  }

  let messagePeek = lastMessage ? lastMessage.body : '';
  if (messagePeek.length > 15) {
    messagePeek = messagePeek.substring(0, 15) + '...';
  }

  return (
    <Conversation
      info={messagePeek}
      lastSenderName={lastSenderName}
      name={displayName}
      unreadCnt={unreadMessageCount}
      lastActivityTime={lastMessage ? timeAgo.format(lastMessage.timestamp, 'round') : undefined}
      onClick={() => {
        dispatch(setActiveContactId(contactId));
        if (conversation == undefined) {
          dispatch(createConversation(contact));
        }
      }}
      active={activeContactId === contactId}
      key={contactId}
    >
      <Avatar src={contact.imageUrl} />
    </Conversation>
  );
};

export default ContactListItem;
