import React, { useState } from 'react';
import { Drawer, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { ValidationRule, WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';
import { ConfirmationButtons } from '../../../common/form';
import { OrganisationMember } from '../../../../common/api/spidertracks-sdk/private/services/UserService';

interface ComponentProps extends FormComponentProps {
  form: WrappedFormUtils;
  existingOrganisationMembers: OrganisationMember[];
  onClose: () => void;
  onCreate: (emailAddresses: string, message?: string) => void;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const InviteUsersDrawer: React.FC<ComponentProps> = ({
  onClose,
  onCreate,
  form,
  existingOrganisationMembers
}) => {
  const [value, setValue] = useState('');

  const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { getFieldDecorator } = form;

  const memberEmails = existingOrganisationMembers.map(member => member.email);

  const formSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    form.validateFields();
    const emailAddresses = form.getFieldValue('emailAddresses');
    const customMessage = form.getFieldValue('customMessage');
    onCreate(emailAddresses, customMessage);
    form.resetFields();
  };

  const validateEmails = (
    _rule: ValidationRule,
    value: string,
    callback: (message?: unknown) => void
  ) => {
    if (value) {
      const emails = value.split(',').map(email => email.trim());
      const invalidEmail = emails.find((email: string) => !EMAIL_REGEX.test(email));
      const alreadyMembers = emails.filter(email => memberEmails.includes(email)).length > 0;
      const hasDuplicates = emails.some((email, index) => emails.indexOf(email) !== index);
      if (invalidEmail || alreadyMembers || hasDuplicates) {
        callback(
          <div>
            {alreadyMembers && (
              <div style={{ color: 'blue', marginBottom: '4px' }}>
                Already members in this organisation
              </div>
            )}
            {invalidEmail && <div style={{ color: 'red' }}>Invalid email addresses</div>}
            {hasDuplicates && (
              <div style={{ color: 'orange' }}>Duplicate email addresses detected</div>
            )}
          </div>
        );
        return;
      }
    }
    callback();
  };

  const getStyledText = (input: string) => {
    const emails = input.split(',');
    return emails
      .map((emailPlusLeading, index, array) => {
        const email = emailPlusLeading.trim();
        const isDuplicate = array.findIndex(e => e.trim() === email) !== index;
        if (memberEmails.includes(email)) {
          return `<span style="color: blue;">${emailPlusLeading}</span>`;
        } else if (!EMAIL_REGEX.test(email)) {
          return `<span style="color: red;">${emailPlusLeading}</span>`;
        } else if (isDuplicate) {
          return `<span style="color: orange;">${emailPlusLeading}</span>`;
        }
        return `<span style="color: black;">${emailPlusLeading}</span>`;
      })
      .join('<span style="color: black;">,</span>');
  };

  return (
    <Drawer title="Invite" placement={'right'} visible={true} width={400} onClose={onClose}>
      <Form
        layout="vertical"
        onChange={() => {
          setInviteButtonDisabled(
            form.getFieldValue('emailAddresses') === '' ||
              form.getFieldError('emailAddresses') !== undefined
          );
        }}
        onSubmit={formSubmitHandler}
      >
        <Form.Item
          label="Email addresses (comma separated):"
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {getFieldDecorator('emailAddresses', {
            initialValue: '',
            validateFirst: true,
            rules: [
              { required: true, message: 'Please enter at least one email address' },
              { validator: validateEmails }
            ]
          })(
            <div style={{ position: 'relative', width: '100%' }}>
              {/*
              This uses a styled div to provide the formatting required for the text area, it's
              not great, but seems to work
              Styled content (Overlay) */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  padding: '6px 12px', // Match AntD TextArea padding
                  height: '100%',
                  color: 'transparent',
                  pointerEvents: 'none', // Prevent this layer from interfering with typing
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  zIndex: 1
                }}
                dangerouslySetInnerHTML={{ __html: getStyledText(value) }}
              />

              <Input.TextArea
                aria-label="Email addresses (comma separated):"
                rows={4}
                disabled={isSaving}
                spellCheck={false}
                style={{
                  position: 'relative',
                  zIndex: 2,
                  width: '100%',
                  height: '100%',
                  padding: '4px 11px',
                  lineHeight: '1.5715', // Match Ant Design TextArea styles
                  resize: 'none',
                  background: 'transparent', // Overlay shows through
                  color: 'transparent', // Hides the actual text
                  caretColor: 'black' // Keeps the caret visible
                }}
                value={value}
                onChange={e => setValue(e.target.value)}
              />
            </div>
          )}
        </Form.Item>
        <Form.Item
          label={'Custom message (optional):'}
          style={{
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {getFieldDecorator('customMessage', {
            initialValue: ''
          })(<Input.TextArea rows={4} disabled={isSaving} />)}
        </Form.Item>
        <ConfirmationButtons
          onClose={onClose}
          isSaving={isSaving}
          disabled={inviteButtonDisabled}
          text={'Invite'}
        />
      </Form>
    </Drawer>
  );
};

InviteUsersDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired
};

export default Form.create<ComponentProps>()(InviteUsersDrawer);
