import { InsightRuleConditionType } from '../../../common/api/spidertracks-sdk/private/services/InsightRulesService';

export function getPrimaryConditionSourceOptions(eventType: string) {
  switch (eventType) {
    case 'excessive_temperature':
      return [
        {
          key: 'engine1Cht1DegreesC',
          label: getPrimaryConditionSourceLabel('engine1Cht1DegreesC')
        },
        {
          key: 'engine1Egt1DegreesC',
          label: getPrimaryConditionSourceLabel('engine1Egt1DegreesC')
        },
        { key: 'engine1MgtDegreesC', label: getPrimaryConditionSourceLabel('engine1MgtDegreesC') }
      ];
    case 'excessive_rpm':
      return [
        { key: 'engine1Rpm', label: getPrimaryConditionSourceLabel('engine1Rpm') },
        { key: 'n1Rpm', label: getPrimaryConditionSourceLabel('n1Rpm') },
        { key: 'n2Rpm', label: getPrimaryConditionSourceLabel('n2Rpm') },
        { key: 'rotorSpeedRpm', label: getPrimaryConditionSourceLabel('rotorSpeedRpm') }
      ];
    case 'excessive_speed':
      return [
        {
          key: 'indicatedAirSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('indicatedAirSpeedMetresPerSecond')
        },
        {
          key: 'groundSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('groundSpeedMetresPerSecond')
        }
      ];
    default:
      return undefined;
  }
}

export function getInsightRuleConditionSourceOptions(type: InsightRuleConditionType) {
  switch (type) {
    case InsightRuleConditionType.temperature:
      return [
        {
          key: 'engine1Cht1DegreesC',
          label: getPrimaryConditionSourceLabel('engine1Cht1DegreesC')
        },
        {
          key: 'engine1Egt1DegreesC',
          label: getPrimaryConditionSourceLabel('engine1Egt1DegreesC')
        },
        { key: 'engine1MgtDegreesC', label: getPrimaryConditionSourceLabel('engine1MgtDegreesC') }
      ];
    case InsightRuleConditionType.rpm:
      return [
        { key: 'engine1Rpm', label: getPrimaryConditionSourceLabel('engine1Rpm') },
        { key: 'n1Rpm', label: getPrimaryConditionSourceLabel('n1Rpm') },
        { key: 'n2Rpm', label: getPrimaryConditionSourceLabel('n2Rpm') },
        { key: 'rotorSpeedRpm', label: getPrimaryConditionSourceLabel('rotorSpeedRpm') }
      ];
    case InsightRuleConditionType.highSpeed:
      return [
        {
          key: 'indicatedAirSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('indicatedAirSpeedMetresPerSecond')
        },
        {
          key: 'groundSpeedMetresPerSecond',
          label: getPrimaryConditionSourceLabel('groundSpeedMetresPerSecond')
        }
      ];
    default:
      return undefined;
  }
}

export function getPrimaryConditionSourceLabel(key: string) {
  switch (key) {
    case 'engine1Cht1DegreesC':
      return 'Engine 1 Cylinder Head Temperature';
    case 'engine1Egt1DegreesC':
      return 'Engine 1 Exhaust Gas Temperature';
    case 'engine1MgtDegreesC':
      return 'Engine 1 Measured Gas Temperature';
    case 'engine1Rpm':
      return 'Engine 1';
    case 'n1Rpm':
      return 'Low pressure compressor (N1)';
    case 'n2Rpm':
      return 'High pressure compressor (N2)';
    case 'rotorSpeedRpm':
      return 'Rotor Speed';
    case 'indicatedAirSpeedMetresPerSecond':
      return 'Air Speed';
    case 'groundSpeedMetresPerSecond':
      return 'Ground Speed';
    default:
      return '';
  }
}
