import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useResourcePerformanceObserver } from '../../../hooks/usePerformanceObserver';
import { getUserData } from '../../../redux/selectors/userData';
import { datadogLogs } from '@datadog/browser-logs';

type IComponentProps = {
  IsPublicUser?: boolean;
};
const SkyVectorLogger: FunctionComponent<IComponentProps> = ({ IsPublicUser }) => {
  const userData = useSelector(getUserData);

  if (!('PerformanceObserver' in window)) {
    datadogLogs.logger.info('skyvector-api-monitoring-not-supported', {
      userId: IsPublicUser ? 'public' : userData?.id
    });
    return null;
  }

  if (
    datadogLogs.getInitConfiguration()?.clientToken === undefined ||
    datadogLogs.getInitConfiguration()?.site === undefined
  ) {
    console.error('Datadog logs not initialized');
    return null;
  }

  const resourcePerformanceEntries = useResourcePerformanceObserver('skyvector');
  useEffect(() => {
    if (resourcePerformanceEntries.length > 0) {
      for (let entry of resourcePerformanceEntries) {
        /*
        we assume that this is cached if the transferSize is 0 and the duration(fetch start- response end) is less than 150ms
        For non-CORS requests, the transferSize is 0 for cache hits. But this is a CORS fetch, thus lacks most of the properties of a normal fetch
        performance entry. This is why we need to check the duration and assume cache hit.
        */

        if (entry.transferSize === 0 && entry.duration <= 5) {
          // This is a cached entry, ignore it
          continue;
        }
        /*
        sample: https://t.skyvector.com/id1/lo/2502/2/3/2.jpg
        mapType: lo/hi/vfr, zoom: 2, gridX: 3, gridY: 2
        The identifier immediately after the tld seems to be client identifier (68b6ba18)
        2502 seems to be a some sort of map identifier internal to skyvector
        */
        const UrlParts = entry.name.split('/');
        const mapType = UrlParts[UrlParts.length - 5];
        const mapId = UrlParts[UrlParts.length - 4];
        const zoom = UrlParts[UrlParts.length - 3];
        const gridX = UrlParts[UrlParts.length - 2];
        const gridY = UrlParts[UrlParts.length - 1].split('.')[0];

        datadogLogs.logger.info('skyvector-api-call', {
          /* For unique tile count */
          fullUrl: entry.name,
          mapId,
          mapType,
          gridX,
          gridY,
          zoom,
          userId: IsPublicUser ? 'public' : userData?.id,
          /* To be used in the backend to filter out possible cache hits */
          duration: entry.duration
        });
      }
    }
  }, [resourcePerformanceEntries]);
  return null;
};

export default SkyVectorLogger;
