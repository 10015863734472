import React, { useEffect } from 'react';
import { Slider, Dropdown, Menu, Button, Spin } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import {
  GotoStartOfTrack,
  StepBack,
  PlayFlight,
  PauseFlight,
  StepForward,
  GoToEndOfTrack
} from './icons';
import { SliderValue } from 'antd/lib/slider';
import { useDispatch, useSelector } from 'react-redux';
import {
  setElapsedTime,
  setPlaybackSpeed,
  togglePlaybackStatus
} from '../../../redux/slice/flightExplorer/flightExplorer';
import { setSelectedDisplayPoint } from '../../../redux/slice/events';
import { Track } from '../../../types/Track';
import styled from 'styled-components';
import {
  getFlightReportDisplayPoints,
  getSelectedDisplayPoint
} from '../../../redux/selectors/eventData';
import {
  getElapsedTime,
  getIsPlaying,
  getPlaybackSpeed,
  getUpdateSelectedDisplayPoint
} from '../../../redux/selectors/flightExplorer';
import { getSelectedTrack } from '../../../redux/selectors/aircraftData';
import { getLatestPointBefore } from '../utilities/getLatestPointBefore';
import './styles.scss';

const BASE = 'Control-Panel';

export const formatTime = (elapsedTimeSec: number) => {
  const hours = Math.floor(elapsedTimeSec / 3600);
  const minutes = Math.floor((elapsedTimeSec - hours * 3600) / 60);
  const seconds = elapsedTimeSec - hours * 3600 - minutes * 60;

  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  const secondsStr = seconds.toString().padStart(2, '0');

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const getTotalDurationSec = (track: Track) => {
  return Math.round((track.endTime - track.departedTime) / 1000);
};

const playbackSpeedItems = [
  {
    label: '20x',
    key: 20
  },
  {
    label: '10x',
    key: 10
  },
  {
    label: '5x',
    key: 5
  },
  {
    label: '2x',
    key: 2
  },
  {
    label: '1x',
    key: 1
  }
];

const DropdownLabel = styled(Button)`
  &&& {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 3.25rem;
    padding-left: 5px;
    height: 25px;
  }
`;

interface ComponentProps {
  dataLoadComplete: boolean;
}

const ControlsPanel = ({ dataLoadComplete }: ComponentProps) => {
  const dispatch = useDispatch();
  const isPlaying = useSelector(getIsPlaying);
  const playbackSpeed = useSelector(getPlaybackSpeed);
  const elapsedTime = useSelector(getElapsedTime);
  const updateSelectedDisplayPoint = useSelector(getUpdateSelectedDisplayPoint);
  const selectedDisplayPoint = useSelector(getSelectedDisplayPoint);
  const selectedTrack = useSelector(getSelectedTrack);
  const trackPoints = useSelector(
    getFlightReportDisplayPoints,
    (prev, next) => prev !== undefined && next !== undefined && prev.length === next.length
  );

  const points = trackPoints ? trackPoints[selectedTrack.trackId] : []; // points is known as displayPoints in other places.

  useEffect(() => {
    if (selectedDisplayPoint) {
      const pointTime = selectedDisplayPoint.timestamp;
      const trackStartTime = selectedTrack.departedTime;
      const elapsedTimeSeconds = Math.round((pointTime - trackStartTime) / 1000);
      dispatch(
        setElapsedTime({ elapsedTime: elapsedTimeSeconds, updateSelectedDisplayPoint: false })
      );
    }
  }, [selectedDisplayPoint]);

  useEffect(() => {
    if (points.length > 0) {
      const sortedTrackPoints = points.sort((a, b) => a.timestamp - b.timestamp);

      const update2DView = (elapsedTime: number) => {
        const latestPointBefore = getLatestPointBefore(
          sortedTrackPoints,
          selectedTrack.departedTime,
          elapsedTime
        );

        // updateSelectedDisplayPoint is true when selected display point is updated by scrubbing the player control timeline or when the Cesium clock ticks.
        // If the player controls is used navigate to the next/previous/first/last point, we don't want to update the selected display point, since that action is already handled by the setPoint function.
        if (latestPointBefore && updateSelectedDisplayPoint) {
          dispatch(setSelectedDisplayPoint(latestPointBefore));
        }
      };

      update2DView(elapsedTime);
    }
  }, [elapsedTime]);

  if (!selectedDisplayPoint || !selectedTrack || !trackPoints) {
    return null;
  }

  const selectedPointIndex = points.findIndex(p => p.id === selectedDisplayPoint.id);
  const isStartOfTrack = selectedDisplayPoint.id === points[0].id;
  const isEndOfTrack = selectedDisplayPoint.id === points[points.length - 1].id;
  const totalDurationSec = getTotalDurationSec(selectedTrack as Track);

  const handleTogglePlaybackStatus = () => {
    dispatch(togglePlaybackStatus());
  };

  const onValueChange = (value: SliderValue) => {
    if (typeof value === 'number') {
      dispatch(setPlaybackSpeed({ playbackSpeed: value }));
    }
  };

  const onTimelineUpdate = (value: SliderValue) => {
    if (typeof value === 'number') {
      dispatch(setElapsedTime({ elapsedTime: value }));
    }
  };

  const setPoint = (i: number) => {
    dispatch(setSelectedDisplayPoint(points[i]));
    if (i === 0) {
      dispatch(setElapsedTime({ elapsedTime: 0, updateSelectedDisplayPoint: false }));
    } else if (i === points.length - 1) {
      dispatch(
        setElapsedTime({ elapsedTime: totalDurationSec, updateSelectedDisplayPoint: false })
      );
    } else {
      const point = points[i];
      const elapsedTime = Math.round((point.timestamp - selectedTrack.departedTime) / 1000);
      dispatch(setElapsedTime({ elapsedTime, updateSelectedDisplayPoint: false }));
    }
  };

  const goToStartOfTrack = () => setPoint(0);

  const goToEndOfTrack = () => setPoint(points.length - 1);

  const goToPreviousPoint = () => {
    if (selectedPointIndex > 0) {
      setPoint(selectedPointIndex - 1);
    }
  };

  const goToNextPoint = () => {
    if (selectedPointIndex < points.length - 1) {
      setPoint(selectedPointIndex + 1);
    }
  };

  const playPauseProgress = dataLoadComplete ? (
    isPlaying ? (
      <PauseFlight onClick={handleTogglePlaybackStatus} enabled={dataLoadComplete} />
    ) : (
      <PlayFlight onClick={handleTogglePlaybackStatus} enabled={dataLoadComplete} />
    )
  ) : (
    <Spin style={{ width: '30px' }} />
  );

  return (
    <div data-testid="controls-panel" className="controlsPanelContainer">
      <div data-testid="player-controls-panel" className="playerControlsContainer">
        <GotoStartOfTrack
          onClick={goToStartOfTrack}
          enabled={!isPlaying && !isStartOfTrack && dataLoadComplete}
        />
        <StepBack
          onClick={goToPreviousPoint}
          enabled={!isPlaying && !isStartOfTrack && dataLoadComplete}
        />
        {playPauseProgress}
        <StepForward
          onClick={goToNextPoint}
          enabled={!isPlaying && !isEndOfTrack && dataLoadComplete}
        />
        <GoToEndOfTrack
          onClick={goToEndOfTrack}
          enabled={!isPlaying && !isEndOfTrack && dataLoadComplete}
        />
      </div>

      <div data-testid="player-speed-controls-panel" className="playerSpeedControlContainer">
        <Dropdown
          overlay={
            <Menu>
              {playbackSpeedItems.map(item => (
                <Menu.Item key={item.key} onClick={() => onValueChange(item.key)}>
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <DropdownLabel data-testid="playback-speed-dropdown-label">
            {playbackSpeed}x
            <UpOutlined />
          </DropdownLabel>
        </Dropdown>
      </div>

      <div data-testid="player-timeline-panel" className="playerTimelineContainer">
        <span
          id={`${BASE}_elapsed_time`}
          style={{
            marginLeft: 50,
            marginRight: 5
          }}
        >
          {formatTime(elapsedTime)}
        </span>

        <Slider
          data-testid="player-timeline-slider"
          className={`${BASE}_timeline_tracker`}
          style={{
            width: '100%',
            marginRight: 5,
            marginLeft: 5
          }}
          min={0}
          max={totalDurationSec}
          tipFormatter={formatTime}
          onChange={onTimelineUpdate}
          value={elapsedTime}
        />

        <span
          style={{
            marginLeft: 5,
            marginRight: 25
          }}
        >
          {formatTime(totalDurationSec)}
        </span>
      </div>
    </div>
  );
};

export default ControlsPanel;
