import React, { useState, useEffect } from 'react';
import { MessageList, MessageListProps } from '@chatscope/chat-ui-kit-react';
import {
  getActiveContactId,
  getConversationById,
  getContactById
} from '../../redux/selectors/spiderTxt';
import { useDispatch, useSelector } from 'react-redux';
import { MessagePosition } from './Message';
import Message from './Message';
import DateDivider from './DateDivider';
import { ContactType, MessageType, MessageStatus, UIMessage } from '../../types/spidertxt';
import { getMoreMessagesForConversation } from '../../redux/slice/spidertxt/thunk';
import SystemMessage from './SystemMessage';
import { Spin } from 'antd';
import { getUserData } from '../../redux/selectors/userData';
import moment from 'moment';
import { setActiveContactId } from '../../redux/slice/spidertxt/spiderTxt';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    as?: string | typeof MessageList;
  }
}

interface ConversationViewProps extends MessageListProps {
  as?: string | typeof MessageList;
}

const ConversationView: React.FC<ConversationViewProps> = () => {
  const dispatch = useDispatch();
  const activeContactId = useSelector(getActiveContactId)!;
  const contact = useSelector(useSelector(state => getContactById(state, activeContactId)));
  const user = useSelector(getUserData);
  const activeConversation = useSelector(
    useSelector(state => getConversationById(state, activeContactId))
  );
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);

  const loadMoreMessages = (contactId: string) => {
    if (!isLoadingMoreMessages) {
      setIsLoadingMoreMessages(true);
      dispatch(getMoreMessagesForConversation(contactId, setIsLoadingMoreMessages));
    }
  };

  useEffect(() => {
    if (contact == undefined || activeConversation == undefined) {
      return;
    }
    loadMoreMessages(activeContactId);
  }, [activeContactId]);

  let sortedMessages: UIMessage[] = [];

  /** Prevents the page crashing if we've selected a contact that gets deleted */
  if (contact == undefined) {
    dispatch(setActiveContactId(undefined!));
    return <></>;
  }

  if (activeConversation) {
    const { messages } = activeConversation;
    sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);
    if (sortedMessages.length === 0) {
      const messageTime = moment(Date.now()).tz(user.timezone);

      sortedMessages.push({
        id: 'system',
        contactId: '',
        attributionId: '',
        messageDay: messageTime.format(user.dateFormat.replace('_', '/').replace('_', '/')),
        messageTime: messageTime.format('HH:mm'),
        timestamp: new Date().getTime(),
        type: MessageType.SYSTEM,
        status: MessageStatus.DELIVERED,
        senderDisplayName: 'System',
        body: `This is the beginning of the conversation between you and ${
          contact.contactType === ContactType.GROUP ? 'the messaging group ' : ''
        }${contact.displayName}`,
        conversationId: activeConversation.id
      });
    }
  }

  return (
    <div className={'cs-conversation-view'}>
      {activeConversation == undefined ? (
        <Spin />
      ) : (
        <MessageList
          loadingMore={isLoadingMoreMessages}
          onYReachStart={() => {
            if (activeContactId) {
              loadMoreMessages(activeContactId);
            }
          }}
        >
          {contact &&
            sortedMessages.map((message, index, messageList) => {
              const previousMessage = index > 0 ? messageList[index - 1] : undefined;
              const nextMessage =
                index < messageList.length - 1 ? messageList[index + 1] : undefined;
              const isLastMessage =
                nextMessage === undefined || nextMessage.contactId !== message.contactId;
              const isFirstMessage =
                previousMessage === undefined || previousMessage.contactId !== message.contactId;
              const isSingleMessage = isFirstMessage && isLastMessage;
              const hasDateChanged =
                previousMessage !== undefined && previousMessage.messageDay != message.messageDay;
              const isFirstMessageInConversation = previousMessage === undefined;
              const position = isSingleMessage
                ? MessagePosition.SINGLE
                : isFirstMessage
                ? MessagePosition.FIRST
                : isLastMessage
                ? hasDateChanged
                  ? MessagePosition.SINGLE
                  : MessagePosition.LAST
                : hasDateChanged
                ? MessagePosition.FIRST
                : MessagePosition.NORMAL;
              const showStatus = message.type == MessageType.SENT;
              const showSenderName =
                message.type == MessageType.RECEIVED && contact.contactType == ContactType.GROUP;
              const isSystemMessage = message.type == MessageType.SYSTEM;
              return (
                <div as="MessageListContent2" key={message.id}>
                  {isFirstMessageInConversation && <DateDivider date={message.messageDay} />}
                  {hasDateChanged && <DateDivider date={message.messageDay} />}
                  {isSystemMessage ? (
                    <SystemMessage message={message} />
                  ) : (
                    <Message
                      message={message}
                      position={position}
                      showStatus={showStatus}
                      showSenderName={showSenderName}
                    />
                  )}
                </div>
              );
            })}
        </MessageList>
      )}
    </div>
  );
};

export default ConversationView;
