import { CheckboxOptionType } from 'antd/lib/checkbox';
import {
  EventParameter,
  EventParameterItem,
  EventRule,
  EventRuleBase,
  EventType
} from '../../../common/api/spidertracks-sdk/private/services/EventRulesService';
import { AircraftBase } from '../../../types/aircraft';
import {
  DisplayInsightRuleCondition,
  EventRuleType,
  InsightRule
} from '../../../types/insightRules';
import {
  InsightRuleConditionSource,
  InsightRuleConditionType
} from '../../../common/api/spidertracks-sdk/private/services/InsightRulesService';
import { TableRow } from './EventsConfigTable';

export type KeyedEventParameter = Record<string, EventParameter[]>;
export const transformParameters = (eventParameters: EventParameterItem[]) =>
  eventParameters.reduce<KeyedEventParameter>(
    (acc, param) => ({ ...acc, [param.eventType]: param.parameters }),
    {}
  );

export function getOperationForConditionType(op: InsightRuleConditionType): string {
  switch (op) {
    case InsightRuleConditionType.lowAltitudeAgl:
    case InsightRuleConditionType.lowAltitudeAmsl:
    case InsightRuleConditionType.pitchDown:
    case InsightRuleConditionType.lowSpeed:
      return 'less than';
    default:
      return 'greater than';
  }
}

export const getThresholdDescriptionForInsightRule = (condition: DisplayInsightRuleCondition) => {
  const threshold = condition.displayThreshold;

  if (threshold.severityEnabled) {
    return `<span style="color:green">${threshold.thresholdValueLow}</span>/<span style="color:orange">${threshold.thresholdValueMedium}</span>/<span style="color:red">${threshold.thresholdValueHigh}</span> ${threshold.thresholdUnit}`;
  }

  return threshold.thresholdValue.toString() + ' ' + threshold.thresholdUnit;
};

export const getDescriptionForInsightRule = (rule: InsightRule) => {
  let description: string = getDisplayConditionName(rule.primaryCondition.type);
  const operation = getOperationForConditionType(rule.primaryCondition.type);

  description += ` ${operation} ${getThresholdDescriptionForInsightRule(rule.primaryCondition)}`;
  if (rule.secondaryCondition) {
    const operation = getOperationForConditionType(rule.secondaryCondition.type);
    description += ` & ${getDisplayConditionName(
      rule.secondaryCondition.type
    )} ${operation} ${getThresholdDescriptionForInsightRule(rule.secondaryCondition)}`;
  }
  return description;
};

function getDisplayConditionName(conditionType: InsightRuleConditionType): string {
  switch (conditionType) {
    case InsightRuleConditionType.highAltitudeAgl:
    case InsightRuleConditionType.lowAltitudeAgl:
      return 'Altitude AGL';
    case InsightRuleConditionType.highAltitudeAmsl:
    case InsightRuleConditionType.lowAltitudeAmsl:
      return 'Altitude AMSL';
    case InsightRuleConditionType.roll:
      return 'Roll';
    case InsightRuleConditionType.pitchUp:
    case InsightRuleConditionType.pitchDown:
      return 'Pitch';
    case InsightRuleConditionType.lowSpeed:
      return 'Speed';
    case InsightRuleConditionType.rateOfClimb:
      return 'Rate of Climb';
    case InsightRuleConditionType.rateOfDescent:
      return 'Rate of Descent';
    case InsightRuleConditionType.gForce:
      return 'G-Force';
    case InsightRuleConditionType.highSpeed:
      return 'Speed';
    case InsightRuleConditionType.rpm:
      return 'RPM';
    case InsightRuleConditionType.temperature:
      return 'Temperature';
    default:
      throw new Error(`Unknown condition received: ${conditionType}`);
  }
}

const eventTypeMap = new Map<
  EventRuleType,
  [InsightRuleConditionType, InsightRuleConditionType | undefined]
>([
  [EventRuleType.EXCESSIVE_G_FORCE, [InsightRuleConditionType.gForce, undefined]],
  [EventRuleType.EXCESSIVE_PITCH_DOWN, [InsightRuleConditionType.pitchDown, undefined]],
  [
    EventRuleType.EXCESSIVE_PITCH_DOWN_AT_LOW_ALTITUDE_AGL,
    [InsightRuleConditionType.pitchDown, InsightRuleConditionType.lowAltitudeAgl]
  ],
  [EventRuleType.EXCESSIVE_PITCH_UP, [InsightRuleConditionType.pitchUp, undefined]],
  [
    EventRuleType.EXCESSIVE_PITCH_UP_AT_LOW_SPEED,
    [InsightRuleConditionType.pitchUp, InsightRuleConditionType.lowSpeed]
  ],
  [
    EventRuleType.EXCESSIVE_PITCH_UP_AT_LOW_ALTITUDE_AGL,
    [InsightRuleConditionType.pitchUp, InsightRuleConditionType.lowAltitudeAgl]
  ],
  [EventRuleType.EXCESSIVE_RATE_OF_CLIMB, [InsightRuleConditionType.rateOfClimb, undefined]],
  [EventRuleType.EXCESSIVE_ROLL, [InsightRuleConditionType.roll, undefined]],
  [
    EventRuleType.EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AGL,
    [InsightRuleConditionType.roll, InsightRuleConditionType.lowAltitudeAgl]
  ],
  [
    EventRuleType.EXCESSIVE_ROLL_AT_LOW_ALTITUDE_AMSL,
    [InsightRuleConditionType.roll, InsightRuleConditionType.lowAltitudeAmsl]
  ],
  [EventRuleType.HIGH_RATE_OF_DESCENT, [InsightRuleConditionType.rateOfDescent, undefined]],
  [
    EventRuleType.HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AGL,
    [InsightRuleConditionType.rateOfDescent, InsightRuleConditionType.lowAltitudeAgl]
  ],
  [
    EventRuleType.HIGH_RATE_OF_DESCENT_AT_LOW_ALTITUDE_AMSL,
    [InsightRuleConditionType.rateOfDescent, InsightRuleConditionType.lowAltitudeAmsl]
  ],
  [EventRuleType.MAXIMUM_ALTITUDE_AGL, [InsightRuleConditionType.highAltitudeAgl, undefined]],
  [EventRuleType.MAXIMUM_ALTITUDE_AMSL, [InsightRuleConditionType.highAltitudeAmsl, undefined]],
  [EventRuleType.EXCESSIVE_TEMPERATURE, [InsightRuleConditionType.temperature, undefined]],
  [EventRuleType.EXCESSIVE_RPM, [InsightRuleConditionType.rpm, undefined]],
  [EventRuleType.EXCESSIVE_SPEED, [InsightRuleConditionType.highSpeed, undefined]]
]);

const conditionMap = new Map<
  InsightRuleConditionType,
  Map<InsightRuleConditionType | undefined, EventRuleType>
>([]);
for (const [eventType, [primaryCondition, secondaryCondition]] of eventTypeMap) {
  if (!conditionMap.has(primaryCondition)) {
    conditionMap.set(primaryCondition, new Map());
  }
  conditionMap.get(primaryCondition)!.set(secondaryCondition, eventType);
}

export function getEventRuleTypeFromConditions(event: {
  primaryCondition: DisplayInsightRuleCondition;
  secondaryCondition?: DisplayInsightRuleCondition;
}): EventRuleType {
  const conditions = {
    primaryCondition: event.primaryCondition,
    secondaryCondition: event.secondaryCondition
  };

  const secondaryConditionType = conditions.secondaryCondition?.type;
  const primaryConditionType = conditions.primaryCondition.type;
  if (conditionMap.has(primaryConditionType)) {
    const secondaryMap = conditionMap.get(primaryConditionType);
    if (secondaryMap && secondaryMap.has(secondaryConditionType)) {
      return secondaryMap.get(secondaryConditionType)!;
    }
  }

  throw new Error(
    `No event type for conditions: ${JSON.stringify({
      secondaryConditionType,
      primaryConditionType
    })}`
  );
}

export function getConditionTypesFromEventRuleType(key: string) {
  return eventTypeMap.get(key as EventRuleType)!;
}

export type EventConfigTableDataItem = EventRuleBase & {
  parameters: any;
  description: string;
};
export type EventConfigTableData = EventConfigTableDataItem[];

export type TableData = InsightRule & {
  description: string;
};

export const getTableData = (rules: InsightRule[]): TableData[] => {
  return rules.map(el => {
    const description = getDescriptionForInsightRule(el);
    return {
      ...el,
      description
    };
  });
};

export const getMessage = (minDisplayValue: string | number, maxDisplayValue: string | number) =>
  `Must be between ${minDisplayValue} and ${maxDisplayValue}`;

export const checkValidProps = (minDisplayValue: number, maxDisplayValue: number, type: string) => {
  return (
    type === 'numeric' && typeof minDisplayValue === 'number' && typeof maxDisplayValue === 'number'
  );
};

export const getCustomValidationRules = ({
  minDisplayValue,
  maxDisplayValue
}: Pick<EventParameter, 'minDisplayValue' | 'maxDisplayValue'>) => [
  {
    type: 'number',
    message: getMessage(minDisplayValue, maxDisplayValue),
    min: minDisplayValue,
    max: maxDisplayValue,
    required: true
  }
];

const defaultValidationRules = [
  {
    pattern: /^[-]?([1-9]\d*\.?|0\.)([1-9]|\d[1-9]|\d\d[1-9]|\d\d\d[1-9])?$/,
    message: 'Please enter valid values',
    required: true
  }
];

export const getValidationRules = ({ minDisplayValue, maxDisplayValue, type }: EventParameter) =>
  checkValidProps(minDisplayValue, maxDisplayValue, type)
    ? getCustomValidationRules({ minDisplayValue, maxDisplayValue })
    : defaultValidationRules;

export const validateParameter = (eventParameter: EventParameter) =>
  ['displayName', 'op'].every(p => p in eventParameter);

const getOperation = (op: string) => (op === 'gt' ? 'greater than' : 'less than');

export const getThresholdInputLabel = (eventParameter: EventParameter) => {
  if (!validateParameter(eventParameter)) {
    return `[Data Error: Please Contact Support]`;
  }
  return `${eventParameter.displayName} is ${getOperation(eventParameter.op)}`;
};

export type ExtendedEventType = EventType & {
  ruleAlreadyConfigured?: boolean;
  description?: string;
};

export const sortDescriptionsByAZ = (a: TableRow, b: TableRow): number => {
  const descriptionA = a.description?.toUpperCase() ?? '';
  const descriptionB = b.description?.toUpperCase() ?? '';

  if (descriptionA < descriptionB) {
    return -1;
  }
  if (descriptionA > descriptionB) {
    return 1;
  }
  return 0;
};

export const sortRuleNamesByAZ = (a: TableRow, b: TableRow): number => {
  const ruleNameA = a.name ?? '';
  const ruleNameB = b.name ?? '';
  return ruleNameA.localeCompare(ruleNameB, undefined, { sensitivity: 'base' });
};

export const sortEventTypesByAZ = (a: ExtendedEventType, b: ExtendedEventType): number => {
  const eventTypeLabelA = a.eventTypeDisplayName.toUpperCase();
  const eventTypeLabelB = b.eventTypeDisplayName.toUpperCase();

  if (eventTypeLabelA < eventTypeLabelB) {
    return -1;
  }
  if (eventTypeLabelA > eventTypeLabelB) {
    return 1;
  }
  return 0;
};

export const sortEventTypesByEnabled = (a: ExtendedEventType, b: ExtendedEventType): number => {
  if (b.ruleAlreadyConfigured === true && a.ruleAlreadyConfigured === false) {
    return -1;
  }
  if (b.ruleAlreadyConfigured === false && a.ruleAlreadyConfigured === true) {
    return 1;
  }
  return 0;
};

export const sortEventTypes = (eventTypes: ExtendedEventType[]): ExtendedEventType[] => {
  return eventTypes.sort(sortEventTypesByAZ);
};

export const byRegistration = <T extends AircraftBase>(a: T, b: T) =>
  a.registration < b.registration ? -1 : a.registration > b.registration ? 1 : 0;

export const checkboxOptionTransformer = (
  valueTransformer: (aircraft: {
    id: string;
    registration: string;
    unassigned?: boolean;
  }) => string = aircraft => aircraft.id
) => (aircraft: {
  id: string;
  registration: string;
  unassigned?: boolean;
}): CheckboxOptionType => ({
  label: aircraft.registration,
  value: valueTransformer(aircraft)
});

export const findRuleForEventType = (eventType: string) => (rules: EventRule[]) => (aircraft: {
  id: string;
}) => {
  const eventTypeRules = rules.filter(r => r.eventType === eventType);
  return (
    eventTypeRules.find(r => r.allAircraft) ||
    eventTypeRules.find(rule => rule.aircraftIds && rule.aircraftIds.includes(aircraft.id))
  );
};

export const buildAircraftOptions = (
  aircraft: { id: string; registration: string }[],
  selectedRule?: InsightRule
) => {
  const selected = [];
  const remaining = [];

  for (let i = 0; i < aircraft.length; i++) {
    const aircraftId = aircraft[i].id;
    if (
      selectedRule &&
      (selectedRule.aircraftIds.includes(aircraftId) || selectedRule.allAircraft)
    ) {
      selected.push(aircraft[i]);
    } else {
      remaining.push(aircraft[i]);
    }
  }

  const result = [
    ...selected.sort(byRegistration).map(checkboxOptionTransformer()),
    ...remaining.sort(byRegistration).map(checkboxOptionTransformer())
  ];
  return result;
};

export function getDefaultConditionSourceForType(
  type: InsightRuleConditionType
): InsightRuleConditionSource | undefined {
  switch (type) {
    case InsightRuleConditionType.highAltitudeAgl:
    case InsightRuleConditionType.lowAltitudeAgl:
      return InsightRuleConditionSource.aglMetres;
    case InsightRuleConditionType.highAltitudeAmsl:
    case InsightRuleConditionType.lowAltitudeAmsl:
      return InsightRuleConditionSource.amslMetres;
    case InsightRuleConditionType.lowSpeed:
      return InsightRuleConditionSource.groundSpeedMetresPerSecond;
    case InsightRuleConditionType.pitchDown:
    case InsightRuleConditionType.pitchUp:
      return InsightRuleConditionSource.aircraftPitchRadians;
    case InsightRuleConditionType.rateOfClimb:
    case InsightRuleConditionType.rateOfDescent:
      return InsightRuleConditionSource.verticalSpeedMetresPerSecond;
    case InsightRuleConditionType.roll:
      return InsightRuleConditionSource.aircraftRollRadians;
    case InsightRuleConditionType.gForce:
      return InsightRuleConditionSource.gForce;

    // These 3 types don't have a default
    case InsightRuleConditionType.temperature:
    case InsightRuleConditionType.rpm:
    case InsightRuleConditionType.highSpeed:
      return undefined;
    default:
      throw new Error(`Unknown condition type enum: ${type}`);
  }
}
