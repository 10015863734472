import { OrganisationDetails } from './organisation';
import { SpiderDetails } from './spiders';
import { AircraftTrackState } from '../common/api/spidertracks-sdk/types/TrackData';

export interface AircraftBase {
  id: string;
  registration: string;
}

export interface AircraftType extends AircraftBase {
  type?: string;
}

export interface AircraftDetails extends AircraftType {
  isPublic: boolean;
  make: string | null;
  model: string | null;
  year: number | null;
  type?: string;
  // NOTE: at time of writing, GET /aircrafts returns snake case, and GET /aircrafts/:id returns
  // camelcase. Trap for the unwary.
  aircraft_icon?: string;
  aircraft_icon_colour?: string;
  aircraftIcon?: string;
  aircraftIconColour?: string;
  status: string | null;
  org: OrganisationDetails;
  spider: SpiderDetails | null;
  unassigned?: boolean;
}

export interface AircraftTrackProperties {
  trackState: AircraftTrackState;
  isActive: boolean;
  aircraftRegistration: string;
  aircraftIcon: string;
  aircraftIconColour: string;
}

export const isAircraftDetails = (aircraft: any): aircraft is AircraftDetails => {
  return (
    typeof aircraft === 'object' &&
    aircraft !== null &&
    typeof aircraft.id === 'string' &&
    typeof aircraft.registration === 'string' &&
    typeof aircraft.isPublic === 'boolean' &&
    (aircraft.make === null || typeof aircraft.make === 'string') &&
    (aircraft.model === null || typeof aircraft.model === 'string') &&
    (aircraft.year === null || typeof aircraft.year === 'number') &&
    (aircraft.type === undefined || typeof aircraft.type === 'string') &&
    (aircraft.status === null || typeof aircraft.status === 'string') &&
    typeof aircraft.org === 'object' &&
    (aircraft.spider === null || typeof aircraft.spider === 'object') &&
    (aircraft.unassigned === undefined || typeof aircraft.unassigned === 'boolean')
  );
};
