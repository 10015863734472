import React from 'react';
import { MessageSeparator } from '@chatscope/chat-ui-kit-react';
interface DateDividerProps {
  date: string;
}

const DateDivider: React.FC<DateDividerProps> = ({ date }) => {
  return <MessageSeparator>{date}</MessageSeparator>;
};

export default DateDivider;
