import { Amplify } from '@spidertracks/common';
import { ServiceAbstract } from '../../ServiceAbstract';

interface User {
  userId: string;
  name: string;
  email?: string;
  phoneNumbers?: string[];
}

export interface Recipient {
  id: string;
  name: string;
  type: 'user' | 'group';
}

export interface NotificationConfig {
  id?: string;
  aircraft: string[];
  recipients: (User | string)[] | Recipient[]; //LATER: clean up to just Recipient[].
  eventClass: string;
  eventRules: string[];
  eventRuleIds: string[] | undefined;
  notificationTypes: string[];
  excludedSeverities: string[];
}

interface NotificationConfigAPIResponse {
  items: NotificationConfig[];
}

export enum Severity {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NOT_SPECIFIED = 'NOT_SPECIFIED'
}
export interface EventNotification {
  id: string;
  eventClass: string;
  eventRules: string[];
  how: string;
  people: (User | string | Recipient)[]; //LATER: #NotificationRecipientRefactor clean up to just Recipient[].
  aircraft: string[];
  excludedSeverities: Severity[];
  eventRuleIds: string[] | undefined;
}

const normalizeChannelName = (channel: string) => {
  switch (channel) {
    case 'email':
      return 'Email';
    case 'sms':
      return 'SMS';
    default:
      return channel;
  }
};

export const getDeliveryChannelsLabel = (channels: string[]) => {
  return channels.map(ch => `${normalizeChannelName(ch)}`).join(', ');
};

export class NotificationConfigurationService extends ServiceAbstract {
  public async get(orgId: string): Promise<EventNotification[]> {
    const configs = (await Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${orgId.toLowerCase()}/notifications/configurations`
    )) as NotificationConfigAPIResponse;

    return configs.items.map(config => ({
      id: config.id || '',
      eventClass: config.eventClass,
      eventRules: config.eventRules,
      how: getDeliveryChannelsLabel(config.notificationTypes),
      people: config.recipients,
      aircraft: config.aircraft,
      excludedSeverities: config.excludedSeverities as Severity[],
      eventRuleIds: config.eventRuleIds
    }));
  }

  public async save(orgId: string, config: NotificationConfig): Promise<object> {
    const configs = (await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `organisations/${orgId.toLowerCase()}/notifications/configurations`,
      {
        body: config
      }
    )) as NotificationConfigAPIResponse;
    return configs;
  }

  public async update(orgId: string, config: NotificationConfig, id: string): Promise<object> {
    const configs = (await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `organisations/${orgId.toLowerCase()}/notifications/configurations/${id}`,
      {
        body: config
      }
    )) as NotificationConfigAPIResponse;
    return configs;
  }

  public async delete(orgId: string, notificationConfigId: string): Promise<object> {
    const configs = (await Amplify.API.del(
      'api.spidertracks.io-authenticated',
      `organisations/${orgId.toLowerCase()}/notifications/configurations/${notificationConfigId}`
    )) as NotificationConfigAPIResponse;
    return configs;
  }
}
