import React, { useEffect, useState } from 'react';

import { PageHeader, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import PropTypes from 'prop-types';
import './styles.scss';
import { TableFullRowSelect } from '@spidertracks/components';
import { getCheckedIcon } from '../icons';
import { InvitedUser } from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import { showCancelInvitesModal } from './CancelInvitesModal';
import { showResendInvitesModal } from './ResendInvitesModal';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../redux/selectors/userData';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../../helpers/formatTime';

interface ComponentProps {
  invitedUsers: InvitedUser[];
  loading: boolean;
  sendInvites: (emailAddresses: string[], customMessage?: string) => void;
  cancelInvites: (inviteIds: string[]) => void;
}

interface TableRow {
  key: React.Key;
  email: string;
  invited: string;
  registered: boolean;
  expiry: string;
}

export const getExpiryColumnContent = (expiry: string, dateFormat: string) => {
  const expiryDate = moment(expiry.replace(/\//g, '-'), dateFormat);
  const expired = expiryDate.isBefore(moment.utc(new Date()), 'day');
  const className = expired ? 'expired' : '';
  return <div className={className}>{expiry}</div>;
};

export const InvitedUsersContainer: React.FC<ComponentProps> = ({
  invitedUsers,
  loading,
  sendInvites,
  cancelInvites
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const { dateFormat, timezone } = useSelector(getUserData);

  const onChange = (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys);
  const onSelectAll = (selected: boolean) => !selected && setSelectedRowKeys([]);

  const formatTimestampToUserPref = (timestamp: number) => {
    const localMoment = fromTimestamp(timestamp);
    const formatFn = dateFormatter(dateFormat);
    const [localTime] = formatStandardDateStrings(formatFn, localMoment, timezone);
    return localTime.replace(/_/g, '/');
  };

  const onRowClickHandler = (record: TableRow) => {
    const selectedUserId = record.key.toString();
    if (selectedRowKeys.includes(selectedUserId)) {
      const filteredKeys = selectedRowKeys.filter(id => id !== selectedUserId);
      setSelectedRowKeys(filteredKeys);
    } else {
      setSelectedRowKeys([...selectedRowKeys, selectedUserId]);
    }
  };

  const columns: ColumnProps<TableRow>[] = [
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        a.email
          .toLowerCase()
          .localeCompare(b.email.toLowerCase(), undefined, { sensitivity: 'base' }),
      width: '30%'
    },
    {
      title: 'INVITED',
      dataIndex: 'invited',
      key: 'invited'
    },
    {
      title: 'REGISTERED',
      dataIndex: 'registered',
      key: 'registered',
      align: 'center',
      width: '8em',
      render: (registered: boolean) => {
        return getCheckedIcon(registered);
      }
    },
    {
      title: 'EXPIRY',
      dataIndex: 'expiry',
      key: 'expiry',
      render: (expiry: string) => {
        return getExpiryColumnContent(expiry, dateFormat);
      }
    }
  ];
  const tableVisible = invitedUsers.length > 0 || loading;

  const invitedUserToTableRow = (invitedUser: InvitedUser): TableRow => ({
    key: invitedUser.id,
    email: invitedUser.email,
    invited: formatTimestampToUserPref(invitedUser.invitedAt),
    registered: invitedUser.registered,
    expiry: formatTimestampToUserPref(invitedUser.expiresAt)
  });

  const tableRows = invitedUsers.map(invitedUserToTableRow);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [loading]);

  return (
    <>
      {tableVisible && (
        <div style={{ marginBottom: '2.5em' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingBottom: '4px'
            }}
          >
            <PageHeader
              className="site-page-header invited-users-container"
              style={{ paddingLeft: '0px' }}
              title="Invited"
              subTitle=""
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'flex-end'
              }}
            >
              <Button
                data-testid={'invite-resend'}
                type="link"
                icon="reload"
                size="small"
                onClick={() => {
                  const resendEmails = invitedUsers
                    .filter(invitedUser => selectedRowKeys.includes(invitedUser.id))
                    .map(({ email }) => email);
                  showResendInvitesModal({
                    inviteEmails: resendEmails,
                    resendInvites: () => {
                      sendInvites(resendEmails);
                    }
                  });
                }}
                disabled={selectedRowKeys.length === 0}
              >
                Resend
              </Button>
              <Button
                data-testid={'invite-cancel'}
                type="link"
                icon="close-circle"
                size="small"
                onClick={() => {
                  showCancelInvitesModal({
                    inviteIds: selectedRowKeys,
                    cancelInvites: cancelInvites
                  });
                }}
                disabled={selectedRowKeys.length === 0}
              >
                Cancel
              </Button>
            </div>
          </div>
          <TableFullRowSelect
            className="invited-users-container"
            data-testid={'invited-users-table'}
            pagination={{ pageSize: 10, hideOnSinglePage: true }}
            rowKey="key"
            size="middle"
            bordered
            dataSource={tableRows}
            columns={columns}
            loading={loading}
            onRow={(record: TableRow) => ({
              onClick: () => onRowClickHandler(record)
            })}
            rowSelection={{
              selectedRowKeys,
              onChange,
              onSelectAll
            }}
          />
        </div>
      )}
    </>
  );
};

InvitedUsersContainer.propTypes = {
  invitedUsers: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  sendInvites: PropTypes.func.isRequired,
  cancelInvites: PropTypes.func.isRequired
};
