import { Tooltip } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTrackIdsForNavigationMetaData } from '../../../redux/slice/flightExplorer/flightExplorer';
import { getTrackIdsForNavigationMetaData } from '../../../redux/selectors/flightExplorer';

export interface TrackNavigationPreviousNextButtonProps {
  trackId: string | undefined;
  direction: 'previous' | 'next';
}

export const TrackNavigationPreviousNextButton: React.FC<TrackNavigationPreviousNextButtonProps> = ({
  trackId,
  direction
}: TrackNavigationPreviousNextButtonProps) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const trackIdsForNavigationMetaData = useSelector(getTrackIdsForNavigationMetaData);
  const dispatch = useDispatch();

  const prevNextOnClickFunction = (trackId: string | undefined, direction: 'previous' | 'next') => {
    console.log(trackId);
    if (direction === 'next') {
      dispatch(
        setTrackIdsForNavigationMetaData({
          trackIdsForNavigationMetaData: {
            totalTrackCount: trackIdsForNavigationMetaData.totalTrackCount,
            nextTrackPosition: trackIdsForNavigationMetaData.nextTrackPosition - 1,
            previousTrackPosition: trackIdsForNavigationMetaData.previousTrackPosition - 1
          }
        })
      );
    } else {
      dispatch(
        setTrackIdsForNavigationMetaData({
          trackIdsForNavigationMetaData: {
            totalTrackCount: trackIdsForNavigationMetaData.totalTrackCount,
            nextTrackPosition: trackIdsForNavigationMetaData.nextTrackPosition + 1,
            previousTrackPosition: trackIdsForNavigationMetaData.previousTrackPosition + 1
          }
        })
      );
    }
    const path = trackId
      ? `/history/${trackId}/flight-explorer?${queryParams.toString()}`
      : undefined;
    if (path) {
      history.push(path);
    }
  };

  if (direction == 'next') {
    return (
      <Tooltip
        title={
          trackId === undefined
            ? undefined
            : `Track ${trackIdsForNavigationMetaData.nextTrackPosition} of ${trackIdsForNavigationMetaData.totalTrackCount}`
        }
      >
        <svg
          className={
            trackId === undefined ? 'event-prev-next-button-disabled' : 'event-prev-next-button'
          }
          onClick={() => {
            prevNextOnClickFunction(trackId, 'next');
          }}
          width="13"
          height="17"
          viewBox="0 0 13 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.809 8.53664L3.94266 15.5015C3.61295 15.7607 3.12821 15.527 3.12821 15.1094V1.17933C3.12821 0.761692 3.61295 0.528233 3.94266 0.787375L12.809 7.75227C12.8685 7.79887 12.9166 7.8584 12.9497 7.92635C12.9828 7.9943 13 8.06888 13 8.14446C13 8.22003 12.9828 8.29462 12.9497 8.36257C12.9166 8.43051 12.8685 8.49004 12.809 8.53664ZM0.185101 16.2889H1.66591C1.715 16.2889 1.76208 16.2694 1.7968 16.2347C1.83151 16.2 1.85101 16.1529 1.85101 16.1038V0.185101C1.85101 0.136009 1.83151 0.088928 1.7968 0.0542148C1.76208 0.0195015 1.715 0 1.66591 0H0.185101C0.136009 0 0.0889286 0.0195015 0.0542153 0.0542148C0.0195021 0.088928 0 0.136009 0 0.185101V16.1038C0 16.1529 0.0195021 16.2 0.0542153 16.2347C0.0889286 16.2694 0.136009 16.2889 0.185101 16.2889Z"
            fill={trackId === undefined ? '#C7C7C7' : '#8C8C8C'}
          />
        </svg>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title={
          trackId === undefined
            ? undefined
            : `Track ${trackIdsForNavigationMetaData.previousTrackPosition} of ${trackIdsForNavigationMetaData.totalTrackCount}`
        }
      >
        <svg
          className={
            trackId === undefined ? 'event-prev-next-button-disabled' : 'event-prev-next-button'
          }
          onClick={() => {
            prevNextOnClickFunction(trackId, 'previous');
          }}
          width="13"
          height="17"
          viewBox="0 0 13 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.190989 8.46336L9.05734 1.49846C9.38705 1.23932 9.87179 1.47301 9.87179 1.89064L9.87179 15.8207C9.87179 16.2383 9.38705 16.4718 9.05734 16.2126L0.190989 9.24773C0.131491 9.20113 0.0833731 9.1416 0.0502834 9.07365C0.0171938 9.0057 0 8.93112 0 8.85554C0 8.77997 0.0171938 8.70538 0.0502834 8.63743C0.0833731 8.56949 0.131491 8.50996 0.190989 8.46336ZM12.8149 0.711086H11.3341C11.285 0.711086 11.2379 0.730589 11.2032 0.765301C11.1685 0.800014 11.149 0.847095 11.149 0.896187L11.149 16.8149C11.149 16.864 11.1685 16.9111 11.2032 16.9458C11.2379 16.9805 11.285 17 11.3341 17H12.8149C12.864 17 12.9111 16.9805 12.9458 16.9458C12.9805 16.9111 13 16.864 13 16.8149L13 0.896187C13 0.847095 12.9805 0.800014 12.9458 0.765301C12.9111 0.730589 12.864 0.711086 12.8149 0.711086Z"
            fill={trackId === undefined ? '#C7C7C7' : '#8C8C8C'}
          />
        </svg>
      </Tooltip>
    );
  }
};
