import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Badge, Button, PageHeader, Tabs } from 'antd';

import { getInstance } from '../../../common/api/spidertracks-sdk';
import { useFeatureFlag } from '../../../hooks';
import { getUserData } from '../../../redux/selectors/userData';
import {
  InvitedUser,
  OrganisationGroup,
  OrganisationGuest,
  OrganisationMember,
  UserService
} from '../../../common/api/spidertracks-sdk/private/services/UserService';
import { PersonAssociatedTag } from '../../../common/api/spidertracks-sdk/types/PersonAssociatedTag';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import {
  showErrorNotification,
  showSuccessNotification
} from '../../../helpers/globalNotifications';

import { InvitedUsersContainer } from './invited/InvitedUsersContainer';
import InviteUsersDrawer from './invited/InviteUsersDrawer';
import OrganisationMemberDrawer from './members/OrganisationMemberDrawer';
import { OrganisationMembersContainer } from './members/OrganisationMembersContainer';
import { OrganisationGroupsContainer } from './groups/OrganisationGroupsContainer';
import OrganisationGroupDrawer from './groups/OrganisationGroupDrawer';
import { GuestsContainer } from './guests/GuestsContainer';
import OrganisationGuestDrawer from './guests/OrganisationGuestDrawer';

export type UserSettingType = 'member' | 'guest' | 'group';

const style = {
  overflow: 'scroll',
  height: '100%'
};
const { TabPane } = Tabs;

const UsersContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const { organisationId } = useParams<{ organisationId: string }>();
  const { orgs } = useSelector(getUserData);
  const isUserLevelSpidertxtEnabled = useFeatureFlag('spidertxt-users-admin', [organisationId]);
  const isTagsAdminFlagEnabled = useFeatureFlag('manifest-tags-admin', [organisationId]);
  const isGroupsAdminEnabled = useFeatureFlag('groups-users-admin', [organisationId]);
  const isAircraftVisibilityGroupsAdminEnabled = useFeatureFlag('aircraft-groups-users-admin', [
    organisationId
  ]);
  const isNotificationsAdminEnabled = useFeatureFlag('notifications-admin', [organisationId]);
  const isGuestsAdminEnabled = useFeatureFlag('guests-users-admin', [organisationId]);

  const [loadingInvitedUsers, setLoadingInvitedUsers] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const [loadingOrganisationMembers, setLoadingOrganisationMembers] = useState<boolean>(false);
  const [organisationMembers, setOrganisationMembers] = useState<OrganisationMember[]>([]);
  const [loadingOrganisationGuests, setLoadingOrganisationGuests] = useState<boolean>(false);
  const [organisationGuests, setOrganisationGuests] = useState<OrganisationGuest[]>([]);
  const [loadingOrganisationGroups, setLoadingOrganisationGroups] = useState<boolean>(false);
  const [organisationGroups, setOrganisationGroups] = useState<OrganisationGroup[]>([]);

  const [tags, setTags] = useState<PersonAssociatedTag[]>([]);
  const [organisationAircraft, setOrganisationAircraft] = useState<PublicAircraftData[]>([]);
  const [selectedOrganisationMember, setSelectedOrganisationMember] = useState<
    OrganisationMember
  >();
  const [selectedOrganisationGuest, setSelectedOrganisationGuest] = useState<OrganisationGuest>();
  const [selectedOrganisationGroup, setSelectedOrganisationGroup] = useState<OrganisationGroup>();
  const [memberDrawerVisible, setMemberDrawerVisible] = useState<boolean>(false);
  const [inviteDrawerVisible, setInviteDrawerVisible] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setGuestDrawerVisible] = useState<boolean>(false);
  const [groupDrawerVisible, setGroupDrawerVisible] = useState<boolean>(false);
  const [currentActiveKey, setCurrentActiveKey] = useState(
    pathname.slice(pathname.lastIndexOf('/') + 1)
  );
  const orgObj = orgs?.find(obj => obj.org.id === organisationId)?.org;
  const isOrgSpidertxtEnabled = orgObj?.spiderTxtEnabled ?? false;
  const organisationGuestCount = organisationGuests?.length;

  useEffect(() => {
    history.listen(location => {
      const { pathname } = location;
      setCurrentActiveKey(pathname.slice(pathname.lastIndexOf('/') + 1));
    });
  }, [history]);

  const loadInvitedUsers = async () => {
    await getInstance()
      .getUserService()
      .getInvitedUsers(organisationId)
      .then(users => setInvitedUsers(users))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch invited members');
      })
      .finally(() => setLoadingInvitedUsers(false));
  };

  const loadOrganisationMembers = async () => {
    await getInstance()
      .getUserService()
      .getOrganisationMembers(organisationId)
      .then(members => setOrganisationMembers(members))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch organisation members');
      })
      .finally(() => setLoadingOrganisationMembers(false));
  };

  const loadOrganisationGuests = async () => {
    setLoadingOrganisationGuests(true);
    await getInstance()
      .getUserService()
      .getOrganisationGuests(organisationId)
      .then(guestsList => setOrganisationGuests(guestsList.guests))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch organisation guests');
      })
      .finally(() => setLoadingOrganisationGuests(false));
  };

  const fetchGroupsWithMembers = async (userService: UserService) => {
    return await userService.getOrganisationGroups(organisationId);
  };

  const loadGroups = async () => {
    setLoadingOrganisationGroups(true);
    try {
      const updatedGroupsWithMembers = await fetchGroupsWithMembers(getInstance().getUserService());
      setOrganisationGroups(updatedGroupsWithMembers);
    } catch (error) {
      console.error(error);
      showErrorNotification('Failed to fetch groups');
    } finally {
      setLoadingOrganisationGroups(false);
    }
  };

  const sendInvites = async (
    emailAddresses: string[],
    customMessage?: string,
    convertToInviteFromGuest?: boolean
  ) => {
    if (convertToInviteFromGuest) setLoadingOrganisationGuests(true);

    setLoadingInvitedUsers(true);
    await getInstance()
      .getUserService()
      .sendInvites(organisationId, emailAddresses, customMessage)
      .then(() =>
        showSuccessNotification(emailAddresses.length > 1 ? 'Invites sent' : 'Invite sent')
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to send invites');
      });
    setInviteDrawerVisible(false);
    await loadInvitedUsers();
    await loadOrganisationGuests();
  };

  const cancelInvites = async (inviteIds: string[]) => {
    setLoadingInvitedUsers(true);
    await getInstance()
      .getUserService()
      .cancelInvites(organisationId, inviteIds)
      .then(() =>
        showSuccessNotification(inviteIds.length > 1 ? 'Invites cancelled' : 'Invite cancelled')
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to cancel invites');
      });
    await loadInvitedUsers();
  };

  useEffect(() => {
    const userService = getInstance().getUserService();

    const fetchInvitedUsers = async () => {
      await userService.getInvitedUsers(organisationId).then(users => {
        setInvitedUsers(users);
      });
    };

    const fetchOrganisationMembers = async () => {
      const [members, tags, organisationAircraft] = await Promise.all([
        userService.getOrganisationMembers(organisationId),
        getInstance().getPersonAssociatedTags(organisationId),
        getInstance().getOrgAircraft(organisationId)
      ]);
      const formattedTags = tags.map(tag => {
        return {
          ...tag,
          macAddress:
            tag && tag.macAddress
              ? tag.macAddress
                  .replace(/:/g, '')
                  .replace(/-/g, '')
                  .replace(/(.{2})/g, '$1:')
                  .replace(/:$/g, '')
              : '-'
        };
      });
      setOrganisationMembers(members);
      setTags(formattedTags);
      setOrganisationAircraft(organisationAircraft);
    };

    const fetchOrganisationGuests = async () => {
      await userService.getOrganisationGuests(organisationId).then(guestsList => {
        setOrganisationGuests(guestsList.guests);
      });
    };

    const fetchOrganisationGroups = async () => {
      const updatedGroupsWithMembers = await fetchGroupsWithMembers(userService);
      setOrganisationGroups(updatedGroupsWithMembers);
    };

    setLoadingInvitedUsers(true);
    setLoadingOrganisationMembers(true);
    setLoadingOrganisationGuests(true);
    setLoadingOrganisationGroups(true);

    fetchInvitedUsers()
      .catch(console.error)
      .finally(() => {
        setLoadingInvitedUsers(false);
      });
    fetchOrganisationMembers()
      .catch(console.error)
      .finally(() => {
        setLoadingOrganisationMembers(false);
      });
    fetchOrganisationGuests()
      .catch(console.error)
      .finally(() => {
        setLoadingOrganisationGuests(false);
      });
    fetchOrganisationGroups()
      .catch(console.error)
      .finally(() => {
        setLoadingOrganisationGroups(false);
      });
  }, [organisationId]);

  useEffect(() => {
    if (selectedOrganisationMember) {
      setMemberDrawerVisible(true);
    }
  }, [selectedOrganisationMember]);

  useEffect(() => {
    if (selectedOrganisationGroup) {
      setGroupDrawerVisible(true);
    }
  }, [selectedOrganisationGroup]);

  const handleRowClick = (id: string, tableType: UserSettingType) => {
    if (id) {
      let resultFound;
      switch (tableType) {
        case 'member':
          resultFound = organisationMembers.find(m => m.id === id);
          if (resultFound) {
            setSelectedOrganisationMember(resultFound);
          }
          break;
        case 'guest':
          break;
        case 'group':
          resultFound = organisationGroups.find(g => g.id === id);
          if (resultFound) {
            setSelectedOrganisationGroup(resultFound);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleMemberDrawerClose = () => {
    setMemberDrawerVisible(false);
    setSelectedOrganisationMember(undefined);
  };

  const handleGuestDrawerClose = () => {
    setGuestDrawerVisible(false);
    setSelectedOrganisationGuest(undefined);
  };

  const handleGroupDrawerClose = () => {
    setGroupDrawerVisible(false);
    setSelectedOrganisationGroup(undefined);
  };

  const onSaveMember = async (success: boolean) => {
    setMemberDrawerVisible(false);
    if (success) {
      showSuccessNotification('Member updated successfully');
    } else {
      showErrorNotification('Failed to update member');
    }
    setSelectedOrganisationMember(undefined);
    await loadOrganisationMembers();
  };

  const onSaveGroup = async (success: boolean, actionType: string) => {
    handleGroupDrawerClose();
    if (success) {
      showSuccessNotification(`Group ${actionType} successfully`);
    } else {
      showErrorNotification(`Failed to ${actionType} group`);
    }
    setSelectedOrganisationGroup(undefined);
    await loadGroups();
  };

  const removeOrganisationMembers = async (memberIds: string[]) => {
    setLoadingOrganisationMembers(true);
    await getInstance()
      .getUserService()
      .deleteOrganisationMembers(memberIds, organisationId)
      .then(() =>
        showSuccessNotification(
          memberIds.length > 1 ? 'Members removed successfully' : 'Member removed successfully'
        )
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to remove members');
      });
    handleMemberDrawerClose();
    await loadOrganisationMembers();
    await loadGroups();
  };

  const removeOrganisationGroup = async (groupIds: string[]) => {
    // Current business logic does not allow deleting multiple groups at once
    if (Array.isArray(groupIds) && groupIds.length !== 1) {
      console.error('Only one group can be removed at a time');
      showErrorNotification('Failed to remove group');
      return;
    }
    setLoadingOrganisationGroups(true);
    await getInstance()
      .getUserService()
      .deleteOrganisationGroup(organisationId, groupIds[0])
      .then(() => showSuccessNotification('Group removed successfully'))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to remove group');
      });
    handleGroupDrawerClose();
    await loadGroups();
  };

  const removeOrganisationGuests = async (guestIds: string[]) => {
    setLoadingOrganisationGuests(true);
    await getInstance()
      .getUserService()
      .deleteOrganisationGuests(guestIds, organisationId)
      .then(() =>
        showSuccessNotification(
          guestIds.length > 1 ? 'Guests removed successfully' : 'Guest removed successfully'
        )
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to remove guests');
      });
    await loadOrganisationGuests();
  };

  const organisationGuestTab = (
    <div style={{ width: '10em' }}>
      <Badge count={organisationGuestCount} style={{ backgroundColor: '#1890FF' }} offset={[25, 6]}>
        Guests
      </Badge>
    </div>
  );

  const onTabChange = (activeKey: string): void => {
    const newPath = pathname.slice(0, pathname.lastIndexOf('/')).concat(`/${activeKey}`);
    history.push(newPath);
  };

  return (
    <div className="px-5 py-4" style={style}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Users & Groups"
          subTitle=""
        />
        {currentActiveKey !== 'guests' && (
          <Button
            type="primary"
            disabled={loadingOrganisationMembers || loadingOrganisationGuests}
            size="large"
            data-testid="primary-button"
            onClick={() =>
              currentActiveKey === 'members'
                ? setInviteDrawerVisible(true)
                : setGroupDrawerVisible(true)
            }
            style={{ padding: '0 50px', width: '140px' }}
          >
            {currentActiveKey === 'members' ? 'Invite' : 'Add'}
          </Button>
        )}
      </div>
      <Tabs
        defaultActiveKey="members"
        onChange={onTabChange}
        activeKey={currentActiveKey}
        tabBarGutter={30}
      >
        <TabPane tab={<div style={{ width: '10em' }}>Members</div>} key="members">
          <InvitedUsersContainer
            invitedUsers={invitedUsers}
            loading={loadingInvitedUsers}
            sendInvites={sendInvites}
            cancelInvites={cancelInvites}
          />
          <OrganisationMembersContainer
            organisationMembers={organisationMembers}
            tags={tags}
            loading={loadingOrganisationMembers}
            onRow={(memberId: string) => handleRowClick(memberId, 'member')}
            removeOrganisationMembers={removeOrganisationMembers}
            showTableTitle={invitedUsers.length > 0 || loadingInvitedUsers}
            isOrgSpidertxtEnabled={isOrgSpidertxtEnabled}
            isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
            isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
            isGroupsAdminEnabled={isGroupsAdminEnabled}
          />
        </TabPane>
        {isGuestsAdminEnabled && (
          <TabPane tab={organisationGuestTab} key="guests">
            <GuestsContainer
              guests={organisationGuests}
              loading={loadingOrganisationGuests}
              inviteAsMembers={(guestEmails, customMessage?) => {
                sendInvites(guestEmails, customMessage, true);
              }}
              removeOrganisationGuests={removeOrganisationGuests}
            />
          </TabPane>
        )}
        {isGroupsAdminEnabled && (
          <TabPane tab={<div style={{ width: '10em' }}>Groups</div>} key="groups">
            <OrganisationGroupsContainer
              groups={organisationGroups}
              loading={loadingOrganisationGroups}
              isOrgSpidertxtEnabled={isOrgSpidertxtEnabled}
              isAircraftVisibilityGroupsAdminEnabled={isAircraftVisibilityGroupsAdminEnabled}
              isNotificationsAdminEnabled={isNotificationsAdminEnabled}
              onRow={(groupId: string) => handleRowClick(groupId, 'group')}
            />
          </TabPane>
        )}
        {!isGroupsAdminEnabled && (
          <TabPane tab="Groups (coming soon)" disabled key="groups-placeholder" />
        )}
      </Tabs>
      {memberDrawerVisible && selectedOrganisationMember !== undefined && (
        <OrganisationMemberDrawer
          organisationMember={selectedOrganisationMember}
          allTags={tags}
          personTag={tags.find(tag => tag.personId === selectedOrganisationMember.id)}
          organisationAircraft={organisationAircraft}
          onClose={handleMemberDrawerClose}
          onSave={onSaveMember}
          removeOrganisationMembers={removeOrganisationMembers}
          isOrgSpidertxtEnabled={isOrgSpidertxtEnabled}
          isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
          isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
        />
      )}
      {inviteDrawerVisible && (
        <InviteUsersDrawer
          existingOrganisationMembers={organisationMembers}
          onClose={() => setInviteDrawerVisible(false)}
          onCreate={(emailAddresses: string, customMessage?: string) => {
            sendInvites(
              emailAddresses
                .toLowerCase()
                .split(',')
                .map(email => email.trim()),
              customMessage
            );
          }}
        />
      )}
      {/* Deliberately setting Guest Drawer to false because this can be used in phase 2*/}
      {selectedOrganisationGuest !== undefined && false && (
        <OrganisationGuestDrawer
          // @ts-ignore: because this is not used yet. Will be used in phase 2
          organisationGuest={selectedOrganisationGuest}
          organisationId={organisationId}
          onClose={handleGuestDrawerClose}
          inviteAsMembers={(guestEmails, customMessage?) => {
            sendInvites(guestEmails, customMessage, true);
          }}
          removeOrganisationGuests={removeOrganisationGuests}
        />
      )}
      {isGroupsAdminEnabled && groupDrawerVisible && (
        <OrganisationGroupDrawer
          organisationGroup={selectedOrganisationGroup}
          organisationAircraft={organisationAircraft}
          organisationMembers={organisationMembers}
          isOrgSpidertxtEnabled={isOrgSpidertxtEnabled}
          isAircraftVisibilityGroupsAdminEnabled={isAircraftVisibilityGroupsAdminEnabled}
          isNotificationsAdminEnabled={isNotificationsAdminEnabled}
          removeOrganisationGroup={removeOrganisationGroup}
          onSave={onSaveGroup}
          onClose={handleGroupDrawerClose}
        />
      )}
    </div>
  );
};

export default UsersContainer;
