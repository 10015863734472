import {
  Contact,
  ContactType,
  Conversation,
  Message,
  MessageStatus,
  MessageToSend,
  MessageType
} from '../../../../../../types/spidertxt';
import { ServiceAbstract } from '../../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';

export enum MessageDeliveryStatusFromAPI {
  DELIVERED = 'DELIVERED',
  CREATED = 'CREATED',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  IRIDIUM_IN_PROGRESS = 'IRIDIUM_IN_PROGRESS',
  UNCONFIRMED_DELIVERY = 'UNCONFIRMED_DELIVERY',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  DELETED = 'DELETED'
}
export interface MessageFromAPI {
  id: string;
  conversationId: number;
  /** Is the contact this message belongs to */
  senderId: string;
  recipientId: string;
  /** Is the contact who typed this message. Is a hardcoded value for system users */
  attributionId: string;
  message: string;
  status: {
    recipientId: string;
    status: MessageDeliveryStatusFromAPI;
  }[];
  createdAt: number;
}

export interface ConversationFromAPI {
  id: number;
  displayName: string;
  contactId: string;
  contactType: 'user' | 'group';
  totalMessageCount: number;
  unreadMessageCount: number;
  latestMessages: MessageFromAPI[];
}

export class ConversationService extends ServiceAbstract {
  private getFinalMessageDeliveryStatus(statuses: MessageDeliveryStatusFromAPI[]): MessageStatus {
    if (statuses.some(status => status != MessageDeliveryStatusFromAPI.DELIVERED)) {
      // If the backend has the message, it's functionally sent
      return MessageStatus.SENT;
    }
    return MessageStatus.DELIVERED;
  }

  private transformToDomainMessage(message: MessageFromAPI, currentUserId: string): Message {
    return {
      id: message.id,
      body: message.message,
      contactId: message.senderId,
      attributionId: message.attributionId,
      conversationId: message.conversationId,
      timestamp: message.createdAt,
      type:
        message.senderId === undefined
          ? MessageType.SYSTEM
          : message.senderId == currentUserId
          ? MessageType.SENT
          : MessageType.RECEIVED,
      status: this.getFinalMessageDeliveryStatus(message.status.map(status => status.status))
    };
  }

  private transformToDomainConversation(
    conversation: ConversationFromAPI,
    currentUserId: string
  ): Conversation {
    return {
      id: conversation.id,
      contactId: conversation.contactId,
      messages: conversation.latestMessages.map(message =>
        this.transformToDomainMessage(message, currentUserId)
      ),
      unreadMessageCount: conversation.unreadMessageCount
    };
  }

  /* GET /v2/conversations */
  public async getAllActiveConversations(currentUserId: string): Promise<Conversation[]> {
    try {
      const { conversations } = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `v2/v2/conversations`
      );
      return conversations.map((convo: ConversationFromAPI) =>
        this.transformToDomainConversation(convo, currentUserId)
      );
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  /* POST /v2/conversations */
  public async createConversation(contact: Contact): Promise<Conversation | undefined> {
    try {
      const conversation = await Amplify.API.post(
        'api.spidertracks.io-authenticated',
        `v2/v2/conversations`,
        {
          body: {
            displayName: contact.displayName,
            contactId: contact.id,
            contactType: contact.contactType == ContactType.GROUP ? 'group' : 'user'
          }
        }
      );
      return this.transformToDomainConversation(conversation, '');
    } catch (e) {
      console.log('Failed to create conversation', e);
      return undefined;
    }
  }

  /* GET  /v2/conversations/{conversationId}/messages */
  public async getMoreMessagesForContact(
    currentUserId: string,
    conversationId: number,
    earliestMessageId: string | undefined = undefined,
    limit: number = 50
  ): Promise<Message[]> {
    try {
      const { items: messages } = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `v2/v2/conversations/${conversationId}/messages`,
        earliestMessageId
          ? {
              queryStringParameters: {
                cursor: earliestMessageId,
                limit
              }
            }
          : {
              queryStringParameters: {
                limit
              }
            }
      );
      return messages.map((message: MessageFromAPI) =>
        this.transformToDomainMessage(message, currentUserId)
      );
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  /* POST  /v2/conversations/{conversationId}/messages */
  public async sendMessage(message: MessageToSend): Promise<void> {
    await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `v2/v2/conversations/${message.conversationId}/messages`,
      {
        body: {
          conversationId: message.conversationId, // TODO confirm if this is needed
          message: message.body
        }
      }
    );
  }

  /* PUT /v2/conversations/{conversationId}/messages/{messageId} */
  public async acknowledgeMessage(conversationId: number, messageId: string): Promise<void> {
    await Amplify.API.put(
      'api.spidertracks.io-authenticated',
      `v2/v2/conversations/${conversationId}/messages/${messageId}`,
      {
        body: {
          status: MessageDeliveryStatusFromAPI.DELIVERED
        }
      }
    );
  }
}
