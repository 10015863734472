import React, { FC, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CheckboxOptionType } from 'antd/lib/checkbox';

import { AircraftDetails, AircraftType } from '../../../types/aircraft';
import { OrganisationGroup } from '../../../common/api/spidertracks-sdk/private/services/UserService';

import { ConfirmationButtons } from '../../common/form';
import { AircraftSelector } from '../../common/form';
import { UserSelector, User } from '../../common/form/UserSelector';

import { EventRuleSelector, EventRule } from './EventRuleSelector';
import showDeleteNotificationConfigurationModal from './NotificationDeleteConfirmModal';
import {
  NotificationConfiguration,
  checkboxUserOptionTransformer,
  eventRuleCheckboxRuleOptionTransformer,
  checkboxGroupOptionTransformer
} from './NotificationForm';
import { NotificationMethodSelector } from './NotificationMethodSelector';

export interface GeofenceNotificationFormProps extends FormComponentProps {
  initialData: NotificationConfiguration | null;
  createNotification: (config: NotificationConfiguration) => Promise<void>;
  updateNotification: (config: NotificationConfiguration) => Promise<void>;
  deleteNotification: (notificationConfigurationId: string) => Promise<void>;
  closeDrawer: () => void;
  users: User[];
  groups: OrganisationGroup[];
  aircraft: AircraftDetails[];
  eventRules: EventRule[];
}

interface FieldValues {
  users: string[];
  aircraft: string[];
  notifyMethod: string[];
  eventRules: string[];
  excludedSeverities: string[];
}

export const GeofenceNotificationForm: FC<GeofenceNotificationFormProps> = ({
  initialData,
  aircraft,
  users,
  groups,
  eventRules,
  createNotification,
  updateNotification,
  deleteNotification,
  closeDrawer,
  form
}) => {
  const { validateFields } = form;
  const [isSaving, setIsSaving] = useState(false);
  const [showHideDeleteBtn] = useState(!initialData);
  const notifyMethodOptions = [
    { label: 'Email', value: 'email', disabled: false },
    { label: 'SMS', value: 'sms', disabled: false }
  ];

  useEffect(() => {
    if (!initialData) {
      return;
    }
    form.setFields({
      users: {
        value: initialData.users
      },
      aircraft: {
        value: initialData.aircraft
      },
      notifyMethod: {
        value: initialData.notifyMethod
      },
      eventRules: {
        value: initialData.eventRules
      },
      excludedSeverities: {
        value: initialData.excludedSeverities
      }
    });
  }, [initialData]);

  const saveNotificationConfiguration = async (
    err: Error | undefined,
    fieldValues: FieldValues
  ) => {
    try {
      if (!err) {
        setIsSaving(true);

        // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
        const notificationConfiguration: NotificationConfiguration = {
          ...(initialData && initialData?.id ? { id: initialData.id } : {}),
          users: fieldValues.users,
          aircraft: fieldValues.aircraft,
          notifyMethod: fieldValues.notifyMethod,
          eventRules: fieldValues.eventRules,
          eventClass: 'geofence',
          excludedSeverities: fieldValues.excludedSeverities
        } as NotificationConfiguration;

        if (initialData) {
          await updateNotification(notificationConfiguration);
        } else {
          await createNotification(notificationConfiguration);
        }

        setIsSaving(false);
        closeDrawer();
      }
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, fieldValues) => {
      saveNotificationConfiguration(err, fieldValues);
    });
  };

  const aircraftOptionTransformer = <T extends AircraftType>(aircraft: T): CheckboxOptionType => ({
    disabled: false,
    label: aircraft.registration,
    value: aircraft.id
  });

  return (
    <div>
      <div className="ant-drawer-title">Geofence Notification Configuration</div>
      <Form
        onSubmit={formSubmit}
        layout="vertical"
        hideRequiredMark={false}
        style={{ width: '308px', margin: '30px 0 0 32px' }}
        title="Geofence Notification Configuration"
      >
        <UserSelector
          form={form}
          initialValue={[]}
          isEditForm={false}
          users={users}
          userOptionTransformer={checkboxUserOptionTransformer(false)}
          groups={groups}
          groupOptionTransformer={checkboxGroupOptionTransformer()}
          showAllChoice={true}
          showAdminChoice={true}
        />
        <NotificationMethodSelector form={form} notifyMethodOptions={notifyMethodOptions} />
        <EventRuleSelector
          form={form}
          eventClassLabel={'Geofences'}
          eventClassLabelSingular={'geofence'}
          eventClassLabelPlural={'geofences'}
          eventRuleOptions={eventRules.map(eventRuleCheckboxRuleOptionTransformer(false))}
          showAllChoice={true}
        />
        <AircraftSelector
          form={form}
          initialValue={[]}
          isEditForm={false}
          aircraft={aircraft}
          aircraftOptionTransformer={aircraftOptionTransformer}
          showAllChoice={true}
          showFixedWingChoice={true}
          showRotaryChoice={true}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <Button
            type="danger"
            size="large"
            style={{ width: '85px', margin: '16px 8px 0 0' }}
            hidden={showHideDeleteBtn}
            onClick={() => {
              showDeleteNotificationConfigurationModal({
                deleteNotification: deleteNotification,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                notificationConfigurationId: initialData?.id!,
                onClose: closeDrawer,
                form
              });
            }}
          >
            Delete
          </Button>
          <ConfirmationButtons onClose={closeDrawer} isSaving={isSaving} />
        </div>
      </Form>
    </div>
  );
};
