import React from 'react';
import { Tag } from 'antd';
import { ContactCategory } from '../../types/spidertxt';

interface ContactCategoryFilterProps {
  allCount: number | undefined;
  activeCount: number | undefined;
  individualCount: number | undefined;
  groupCount: number | undefined;
  contactFilteredCategory: ContactCategory;
  onCategorySelect: (category: ContactCategory) => void;
}

const ContactCategoryFilter: React.FC<ContactCategoryFilterProps> = ({
  allCount,
  activeCount,
  individualCount,
  groupCount,
  contactFilteredCategory,
  onCategorySelect
}) => {
  return (
    <div className="cs-contact-category-filter">
      <Tag
        onClick={() => onCategorySelect(ContactCategory.ALL)}
        className={contactFilteredCategory == ContactCategory.ALL ? 'active' : ''}
        style={{ padding: '0.25em 0.75em' }}
      >
        All {allCount != undefined && `(${allCount})`}
      </Tag>
      <Tag
        onClick={() => onCategorySelect(ContactCategory.ACTIVE)}
        className={contactFilteredCategory == ContactCategory.ACTIVE ? 'active' : ''}
      >
        Threads {activeCount != undefined && `(${activeCount})`}
      </Tag>
      <Tag
        onClick={() => onCategorySelect(ContactCategory.GROUP)}
        className={contactFilteredCategory == ContactCategory.GROUP ? 'active' : ''}
      >
        Groups {groupCount != undefined && `(${groupCount})`}
      </Tag>
      <Tag
        onClick={() => onCategorySelect(ContactCategory.INDIVIDUAL)}
        className={contactFilteredCategory == ContactCategory.INDIVIDUAL ? 'active' : ''}
      >
        Individuals {individualCount != undefined && `(${individualCount})`}
      </Tag>
    </div>
  );
};

export default ContactCategoryFilter;
