import React from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

interface ComponentProps {
  guestEmails: string[];
  inviteAsMembers: (guestIds: string[]) => void;
}

const getTitle = (inviteIds: string[]) =>
  inviteIds.length > 1 ? 'Invite as Members' : 'Invite as Member';

const getContent = (inviteIds: string[]) =>
  inviteIds.length > 1 ? (
    <>
      <p>Are you sure you want to invite those guests as members?</p>
    </>
  ) : (
    <>
      <p>Are you sure you want to invite this guest as member?</p>
    </>
  );

export const showInviteAsMembersModal = ({
  guestEmails: guestEmails,
  inviteAsMembers
}: ComponentProps) =>
  Modal.confirm({
    title: getTitle(guestEmails),
    icon: <ExclamationCircleFilled />,
    content: getContent(guestEmails),
    okText: guestEmails.length > 1 ? 'Invite as Members' : 'Invite as Member',
    okButtonProps: { type: 'primary' },
    onOk() {
      inviteAsMembers(guestEmails);
    }
  });
