import React, { useState, useEffect, useCallback } from 'react';
import { PageHeader, Button } from 'antd';
import PropTypes from 'prop-types';
import {
  TableFullRowSelect,
  getAircraftRegistrationVerboseLabel,
  getUserGroupsLabel,
  SolidTooltip
} from '@spidertracks/components';
import { useParams } from 'react-router';
import './styles.scss';
import { PublicAircraftData } from '../../../../common/api/spidertracks-sdk/types/AircraftData';
import { OrganisationMember } from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import { PersonAssociatedTag } from '../../../../common/api/spidertracks-sdk/types/PersonAssociatedTag';
import { getFilterDropdown } from '../../../common/filters';
import { getInstance } from '../../../../common/api/spidertracks-sdk';
import { getCheckedIcon } from '../icons';
import { showRemoveModal } from '../RemoveModal';

interface ComponentProps {
  organisationMembers: OrganisationMember[];
  tags: PersonAssociatedTag[];
  loading: boolean;
  onRow: (organisationMemberId: string) => void;
  removeOrganisationMembers: (ids: string[]) => void;
  showTableTitle: boolean;
  isOrgSpidertxtEnabled: boolean;
  isUserLevelSpidertxtEnabled: boolean;
  isTagsAdminFlagEnabled: boolean;
  isGroupsAdminEnabled: boolean;
}

interface TableRow {
  key: React.Key;
  name: string;
  email: string;
  phoneNumber: string;
  role: string;
  tag: string | undefined;
  userGroups: string;
  userGroupsTooltip: string;
  aircraftVisibility: string;
  aircraftVisibilityTooltip: string;
  spiderTxtEnabled: boolean;
}

export const memberNameOnFilter = (value: string, record: TableRow) => {
  return record.key === value;
};

export const aircraftOnFilter = (value: string, record: TableRow) => {
  return record.aircraftVisibility.includes(value) || record.aircraftVisibility === 'All aircraft';
};

export const getOrganisationMemberDisplayName = (organisationMember: OrganisationMember): string =>
  `${organisationMember.firstName} ${organisationMember.lastName}`;

export const getObfuscatedMobilePhoneNumber = (organisationMember: OrganisationMember) => {
  const firstMobileNumber = organisationMember.phoneNumbers.find(
    number => number.type === 'MOBILE'
  );
  if (!firstMobileNumber) {
    return '';
  }

  const mobileNumber = firstMobileNumber.fullNumber;
  if (mobileNumber.length <= 8) {
    return mobileNumber;
  }

  return mobileNumber.slice(0, 5) + 'x'.repeat(mobileNumber.length - 8) + mobileNumber.slice(-3);
};

export const OrganisationMembersContainer: React.FC<ComponentProps> = ({
  organisationMembers,
  tags,
  loading,
  onRow,
  removeOrganisationMembers,
  showTableTitle,
  isOrgSpidertxtEnabled,
  isUserLevelSpidertxtEnabled,
  isTagsAdminFlagEnabled,
  isGroupsAdminEnabled
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [orgAircraftList, setOrgAircraftList] = useState<PublicAircraftData[]>([]);
  const [selectedAircraft, setAircraftFilters] = useState([]);
  const { organisationId } = useParams<{ organisationId: string }>();
  const SpidertracksSDK = getInstance();

  const fetchOrgAircraftList = useCallback(async () => {
    return await SpidertracksSDK.getOrgAircraft(organisationId);
  }, [SpidertracksSDK, organisationId]);

  useEffect(() => {
    setSelectedRowKeys([]);
    const fetchData = async () => {
      try {
        const data = await fetchOrgAircraftList();
        setOrgAircraftList(data);
      } catch (error) {
        console.error('Error fetching aircraft list', error);
        setOrgAircraftList([]);
      }
    };
    fetchData();
  }, [loading, fetchOrgAircraftList]);

  let organisationOwnerId;
  organisationMembers.map(member => {
    if (member.role === 'OWNER') {
      organisationOwnerId = member.id;
    }
  });

  const organisationOwnerSelected = organisationOwnerId
    ? selectedRowKeys.includes(organisationOwnerId)
    : false;

  const getTooltipRenderContent = (child: string, title: string) => {
    return (
      <SolidTooltip placement="bottom" title={title} trigger="hover">
        {child}
      </SolidTooltip>
    );
  };

  const getUsernameTooltipContent = (row: TableRow) => {
    return (
      <SolidTooltip
        placement="bottom"
        overlay={
          <div>
            <table>
              <tbody>
                <tr>
                  <td>{row.email}</td>
                </tr>
                <tr>
                  <td>{row.phoneNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        trigger="hover"
      >
        {row.name}
      </SolidTooltip>
    );
  };

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const getAircraftVisibilityLabel = (organisationMember: OrganisationMember) => {
    const aircraft = organisationMember.aircraft;
    if (aircraft.length === 0) {
      return '';
    }

    const aircraftRegistrations = aircraft.map(a => {
      if (a === '*') {
        return 'All aircraft';
      }
      return a.registration;
    });

    return getAircraftRegistrationVerboseLabel(aircraftRegistrations);
  };

  const getAircraftVisibilityTooltip = (organisationMember: OrganisationMember) => {
    const aircraft = organisationMember.aircraft;
    if (aircraft.length === 0) {
      return '';
    }

    const aircraftRegistrations = aircraft.map(a => {
      if (a !== '*') {
        return a.registration;
      }
    });

    return aircraftRegistrations.join(',');
  };

  const memberNameFilter = organisationMembers.map(member => ({
    key: `${member.id}`,
    value: `${member.id}`,
    label: `${member.firstName} ${member.lastName}`
  }));

  const aircraftFilters = orgAircraftList
    ? orgAircraftList.map(aircraft => ({
        key: aircraft.id,
        value: aircraft.registration,
        label: aircraft.registration
      }))
    : [];

  const onChange = (selectedRowKeys: string[]) => setSelectedRowKeys(selectedRowKeys);
  const onSelectAll = (selected: boolean) => !selected && setSelectedRowKeys([]);
  const userLevelSpidertxtColumn = {
    title: 'SPIDERTXT',
    dataIndex: 'spiderTxtEnabled',
    key: 'spiderTxtEnabled',
    align: 'center',
    width: '8em',
    render: (spiderTxtEnabled: boolean) => {
      const className = isOrgSpidertxtEnabled ? '' : 'spidertxt-disabled';
      return (
        <div className={className}>{getCheckedIcon(spiderTxtEnabled, isOrgSpidertxtEnabled)}</div>
      );
    }
  };
  const tagsColumn = {
    title: 'TAG',
    dataIndex: 'tag',
    key: 'tag',
    align: 'center',
    width: '8em',
    render: (tag: string | undefined) => {
      return getCheckedIcon(tag !== undefined);
    }
  };
  const groupsColumn = {
    title: 'GROUPS',
    dataIndex: 'userGroups',
    key: 'userGroups',
    render: (_text: string, row: TableRow) => {
      return getTooltipRenderContent(row.userGroups, row.userGroupsTooltip);
    }
  };
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      defaultSortOrder: 'ascend',
      sorter: (a: TableRow, b: TableRow) =>
        a.name
          .toLowerCase()
          .localeCompare(b.name.toLowerCase(), undefined, { sensitivity: 'base' }),
      filters: memberNameFilter,
      filterMultiple: true,
      onFilter: memberNameOnFilter,
      ...getFilterDropdown({
        displayName: 'name',
        onFilter: setSelectedMembers,
        clearSelection: () => [],
        showSearch: true,
        updateOnConfirm: true,
        style: {}
      })(),
      filteredValue: selectedMembers,
      render: (_text: string, row: TableRow) => {
        return getUsernameTooltipContent(row);
      }
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      filterMultiple: false,
      filters: [
        {
          text: 'Owner',
          value: 'Owner'
        },
        {
          text: 'Admin',
          value: 'Admin'
        },
        {
          text: 'Member',
          value: 'Member'
        }
      ],
      onFilter: (value: string, record: TableRow) => record.role === value
    },

    {
      title: 'AIRCRAFT VISIBILITY',
      dataIndex: 'aircraftVisibility',
      key: 'aircraftVisibility',
      filters: aircraftFilters,
      onFilter: aircraftOnFilter,
      ...getFilterDropdown({
        displayName: 'aircraftVisibility',
        onFilter: setAircraftFilters,
        clearSelection: () => [],
        showSearch: true,
        updateOnConfirm: true,
        style: {}
      })(),
      filteredValue: selectedAircraft,
      render: (_text: string, row: TableRow) => {
        return getTooltipRenderContent(row.aircraftVisibility, row.aircraftVisibilityTooltip);
      }
    }
  ];
  if (isUserLevelSpidertxtEnabled) {
    // @ts-ignore, because we are adding a new column that does not have specific values
    columns.push(userLevelSpidertxtColumn);
  }
  if (isGroupsAdminEnabled) {
    // @ts-ignore, because we are adding a new column that does not have specific values
    columns.splice(2, 0, groupsColumn);
  }
  if (isTagsAdminFlagEnabled) {
    // @ts-ignore, because we are adding a new column that does not have specific values
    columns.splice(2, 0, tagsColumn);
  }

  const getPersonTagMacAddress = (
    personId: string,
    tags: PersonAssociatedTag[]
  ): string | undefined => {
    const personTag = tags.find(tag => tag.personId === personId);

    return personTag ? personTag.macAddress : undefined;
  };

  const organisationMemberToTableRow = (
    organisationMember: OrganisationMember,
    tags: PersonAssociatedTag[]
  ): TableRow => ({
    key: organisationMember.id,
    name: getOrganisationMemberDisplayName(organisationMember),
    email: organisationMember.email,
    phoneNumber: getObfuscatedMobilePhoneNumber(organisationMember),
    role: capitalizeFirstLetter(organisationMember.role),
    tag: getPersonTagMacAddress(organisationMember.id, tags),
    userGroups: getUserGroupsLabel(organisationMember.groups.map(group => group.name)),
    userGroupsTooltip: organisationMember.groups
      ? organisationMember.groups.map(group => group.name).join(', ')
      : '',
    aircraftVisibility: getAircraftVisibilityLabel(organisationMember),
    aircraftVisibilityTooltip: getAircraftVisibilityTooltip(organisationMember),
    spiderTxtEnabled: organisationMember.spiderTxtEnabled
  });

  const tableRows = organisationMembers.map(member => organisationMemberToTableRow(member, tags));

  const handleOnRow = (row: TableRow) => ({
    onClick: (e: React.MouseEvent) => {
      e.preventDefault();
      onRow(row.key.toString());
    }
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingBottom: '4px'
        }}
      >
        <PageHeader
          className="site-page-header members-container"
          style={{ paddingLeft: '0px' }}
          title={showTableTitle ? 'Members' : ''}
          subTitle=""
        />
        <Button
          data-testid={'member-remove'}
          type="link"
          icon="delete"
          size="small"
          disabled={selectedRowKeys.length === 0 || organisationOwnerSelected}
          onClick={() => {
            showRemoveModal({
              modalType: 'member',
              ids: selectedRowKeys,
              setIsSaving: () => {},
              removeFunction: removeOrganisationMembers
            });
          }}
        >
          Remove
        </Button>
      </div>

      <TableFullRowSelect
        className="members-container"
        pagination={{ pageSize: 10, hideOnSinglePage: true }}
        rowKey="key"
        size="middle"
        bordered
        dataSource={tableRows}
        columns={columns}
        loading={loading}
        onRow={handleOnRow}
        rowSelection={{
          selectedRowKeys,
          onChange,
          onSelectAll
        }}
      />
    </>
  );
};

OrganisationMembersContainer.propTypes = {
  organisationMembers: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onRow: PropTypes.func.isRequired,
  removeOrganisationMembers: PropTypes.func.isRequired,
  showTableTitle: PropTypes.bool.isRequired,
  isUserLevelSpidertxtEnabled: PropTypes.bool.isRequired,
  isTagsAdminFlagEnabled: PropTypes.bool.isRequired,
  isGroupsAdminEnabled: PropTypes.bool.isRequired
};
